import React, { CSSProperties, ReactElement } from 'react';

export interface IModal {
    key: TModalKey;
    props: TModalProps;
}

type TModalKey = 'popUp' | 'slide' | 'fullScreen';
type TModalProps = IPopUpProps | ISlideProps | IFullScreenProps;

export interface IDefaultModal {
    className?: string;
    containerStyle?: CSSProperties;
    content: string | React.ReactElement | Function;
    contentStyle?: CSSProperties;
    handleCloseModal?: Function;
}

export interface IPopUpProps extends IDefaultModal {
    type: 'noYes' | 'confirm' | 'submit' | 'default';
    onClickNo?: Function;
    onClickYes?: Function;
    onClickConfirm?: Function;
    onClickSubmit?: Function;
    noText?: string | ReactElement;
    yesText?: string | ReactElement;
}

export interface ISlideProps extends IDefaultModal {
    direction?: string;
    hasHeader?: boolean;
    maxHeightRatio?: number;
    title?: string | React.ReactElement;
    hasFixedButton?: boolean;
    isBorderRound?: boolean;
    modalHeight?: number;
}

export interface IFullScreenProps extends IDefaultModal {
    isCloseIconOnRight?: boolean;
    transparent?: boolean;
    hasHeader?: boolean;
    title?: string | React.ReactElement;
    hasFixedButton?: boolean;
    headerStyle?: CSSProperties;
}

export const isPopUp = (key: TModalKey, props: TModalProps): props is IPopUpProps => {
    if (key === 'popUp') {
        if ('type' in props) {
            return true;
        }
    }
    return false;
};

export const isSlide = (key: TModalKey, _props: TModalProps): _props is ISlideProps => {
    if (key === 'slide') {
        return true;
    }
    return false;
};

export const isFullScreen = (key: TModalKey, _props: TModalProps): _props is IFullScreenProps => {
    if (key === 'fullScreen') {
        return true;
    }
    return false;
};
