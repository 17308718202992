import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { toast } from 'react-toastify';

import { IModal } from '@/models';
import { modalState } from '@/recoil';

interface UseModal {
    addModal: (_modalProps: IModal) => void;
    removeCurrentModal: () => void;
    clearModalList: () => void;
    foreModal?: IModal;
}

export default function useModal(): UseModal {
    const setModalList = useSetRecoilState(modalState);

    const addModal = useCallback(
        ({ key, props }: IModal) => {
            setModalList(oldValue => {
                if (window.ReactNativeWebView && oldValue.length === 0)
                    window.ReactNativeWebView.postMessage(JSON.stringify({ pageState: 'modal' }));

                toast.dismiss();

                const temp = [...oldValue];
                temp.push({ key, props });
                return temp;
            });
        },
        [setModalList],
    );

    const removeCurrentModal = useCallback(() => {
        setModalList(oldValue => {
            if (window.ReactNativeWebView && oldValue.length === 1)
                window.ReactNativeWebView.postMessage(JSON.stringify({ pageState: 'page' }));

            const temp = [...oldValue];
            temp.pop();
            return temp;
        });
    }, [setModalList]);

    const clearModalList = () => {
        if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(JSON.stringify({ pageState: 'page' }));
        setModalList([]);
    };

    return { addModal, removeCurrentModal, clearModalList };
}
