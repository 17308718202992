import { useRouter } from 'next/router';
import { useSetRecoilState } from 'recoil';
import { MutateFunction } from '@tanstack/react-query';

import { designRequestIdState } from '@/recoil/myPlan';
import useMutationHook from '../useMutationHook';

interface Props {
    onSubmitCounsel: MutateFunction;
}

export default function useCounselMatching({ onSubmitCounsel }: Props) {
    const router = useRouter();
    const setDesignRequestId = useSetRecoilState(designRequestIdState);

    const { mutate, errorPopUp } = useMutationHook(['submit-matching'], onSubmitCounsel, {
        onSuccess: ({ designRequestId }) => {
            setDesignRequestId(designRequestId);
            router.push(`/myPlan/proposal-status/${designRequestId}`);
        },
        onError: err => errorPopUp(err?.response?.status),
    });

    return { submitProposal: mutate };
}
