import { useRouter } from 'next/router';
import jwt_decode from 'jwt-decode';

import useMutationHook from '@/hooks/useMutationHook';
import { useSocket } from '@/hooks/useSocket';
import RegistGuest from '@/queries/common/registGuest';
import { IJwt } from '@/models';
import { API, URLs } from '@/api';
import CheckGuest from './checkGuest';
import DeleteGuest from '@/queries/common/deleteGuest';

const RefreshTokens = () => {
    const router = useRouter();
    const { connectSocket, disconnectSocket } = useSocket();
    const { registGuest } = RegistGuest();
    const { checkGuest, isLoading } = CheckGuest();
    const { deleteGuest } = DeleteGuest();

    const { mutate: refreshTokens } = useMutationHook(
        ['refresh-token'],
        async () => {
            const refreshToken = localStorage.getItem('refreshToken');
            const decoded = jwt_decode(refreshToken!) as IJwt;
            if (decoded.exp * 1000 < new Date().getTime()) {
                throw new Error('Refresh token expired');
            }
            return await API.post(URLs.auth.refresh, { refreshToken });
        },
        {
            onSuccess: res => {
                const { isBanned } = jwt_decode(res.accessToken) as IJwt;

                if (!isBanned) {
                    disconnectSocket();
                    connectSocket(res.accessToken, res.refreshToken);

                    if (router.asPath === '/ban') router.replace('/');
                } else {
                    if (router.pathname !== '/ban') router.push('/ban');
                }
            },
            onError: err => {
                if (checkGuest) {
                    deleteGuest({});
                }
                registGuest({ isForceLogout: true });
            },
        },
    );

    return { refreshTokens };
};

export default RefreshTokens;
