import React, { useRef, useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { TABLET, RESIZED_SCREEN_TABLET } from '@/models/breakPoints';

interface IProps {
    imageArr: string[];
    selectedTab: number;
    handleTab: Function;
}

const CarouselWithPinchZoom = ({ imageArr, selectedTab, handleTab }: IProps) => {
    const setOriginSizeArr = () => {
        const arr = [];

        for (let i = 0; i < imageArr.length; i += 1) arr.push(0);

        return arr;
    };

    const [isDisabledPanning, setIsDisabledPanning] = useState(true);
    const ratioRef = useRef<number[]>(setOriginSizeArr());
    const [opacity, setOpacity] = useState(0);

    const renderImage = (item: string, index: number) => {
        if (opacity) {
            return (
                <TransformWrapper
                    initialScale={ratioRef.current[index]}
                    minScale={ratioRef.current[index]}
                    wheel={{ step: 0.05 }}
                    onZoom={e => {
                        if (e.state.scale !== ratioRef.current[index]) setIsDisabledPanning(false);
                        else setIsDisabledPanning(true);
                    }}
                    panning={{ disabled: isDisabledPanning }}
                    centerOnInit
                >
                    <TransformComponent
                        wrapperStyle={{
                            width:
                                document.body.clientWidth >= TABLET
                                    ? `calc(${RESIZED_SCREEN_TABLET}px - 40px)`
                                    : 'calc(100vw - 40px)',
                            height:
                                document.body.clientWidth >= TABLET
                                    ? `calc(${RESIZED_SCREEN_TABLET}px - 40px)`
                                    : 'calc(100vw - 40px)',
                        }}
                    >
                        <img src={item} alt="" />
                    </TransformComponent>
                </TransformWrapper>
            );
        } else {
            return <></>;
        }
    };

    useEffect(() => {
        for (let i = 0; i < imageArr.length; i += 1) {
            const img = document.createElement('img');
            img.src = imageArr[i];

            img.addEventListener('load', e => {
                const originWidth = (e.target as HTMLImageElement).naturalWidth;
                const originHeight = (e.target as HTMLImageElement).naturalHeight;
                const imgContainerWidth =
                    document.body.clientWidth >= TABLET ? RESIZED_SCREEN_TABLET - 40 : document.body.clientWidth - 40;
                const imgContainerHeight =
                    document.body.clientWidth >= TABLET ? RESIZED_SCREEN_TABLET - 40 : document.body.clientWidth - 40;

                // 왠지는 모르겠지만 고정한 가로 값으로 강제적으로 이미지가 알아서 맞춰짐...
                if (originWidth < imgContainerWidth) {
                    if (originHeight < imgContainerHeight) {
                        if (originWidth < originHeight) ratioRef.current[i] = imgContainerWidth / originHeight;
                        else ratioRef.current[i] = imgContainerHeight / originWidth;
                    } else ratioRef.current[i] = imgContainerWidth / originHeight;
                } else {
                    if (originHeight < imgContainerHeight) ratioRef.current[i] = 1;
                    else {
                        if (originWidth < originHeight) ratioRef.current[i] = originWidth / originHeight;
                        else ratioRef.current[i] = 1;
                    }
                }

                const loadedImgCnt = ratioRef.current.filter(item => item !== 0);
                if (loadedImgCnt.length === imageArr.length) setOpacity(1);
            });
        }
    }, []);

    return (
        <Carousel
            swipeable={true}
            swipeScrollTolerance={document.body.clientWidth / 3}
            emulateTouch
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            showThumbs={false}
            onChange={e => {
                handleTab(e);
            }}
            selectedItem={selectedTab}
        >
            {imageArr.map((item: string, index: number) => {
                return renderImage(item, index);
            })}
        </Carousel>
    );
};

export default CarouselWithPinchZoom;
