export interface ILoginState {
    userType: string;
    clientId: number;
    name: string;
    email: string;
    designatedPlannerName: string;
    designatedPlannerId: number;
}

export interface ClientInfo {
    id: number;
    clientName: string;
    email: string;
    recommendedPlannerCode: string | null;
    groupProposalSubscribe: boolean;
    deviceId: string;
    platform: string;
    platformId: string;
    registrationState: number;
    createdAt: Date;
    updatedAt: Date;
    lastConnectedAt: Date;
    recommendedPlannerName?: string;
    recommendedPlannerId?: number;
}

export const noLoginState: ILoginState = {
    userType: 'faker',
    clientId: 0,
    name: '픽앤플랜',
    email: '',
    designatedPlannerName: '',
    designatedPlannerId: -1,
};

export interface IDummyState extends ILoginState {
    platform: string;
    oAuthIdToken: string;
}

export const defaultDummyState: IDummyState = {
    userType: 'faker',
    clientId: 0,
    name: '픽앤플랜',
    email: '',
    designatedPlannerName: '',
    designatedPlannerId: -1,
    platform: '',
    oAuthIdToken: '',
};

export interface IProfile {
    needUpdate: boolean;
    profileURL: string;
    profileImage?: File;
}

export const noProfileState: IProfile = {
    needUpdate: false,
    profileURL: '/img/profile_image/nonMember@3x.png',
    profileImage: undefined,
};
