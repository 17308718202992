import { atom } from 'recoil';

interface ITyped {
    name: string;
    code: string;
    designatedCode: string;
}

export const typedUserInfoState = atom<ITyped>({
    key: 'typedUserInfoState',
    default: {
        name: '',
        code: '',
        designatedCode: '',
    },
});

interface ITypedError {
    isNameError: boolean;
}

export const typedErrorState = atom<ITypedError>({
    key: 'typedErrorState',
    default: {
        isNameError: false,
    },
});

export const checkErrorState = atom<boolean>({
    key: 'checkErrorState',
    default: false,
});
