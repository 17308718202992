import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useEffect } from 'react';

const CampaignChecker = () => {
    const router = useRouter();

    useEffect(() => {
        const campaignQuery = router.query;

        const decodedQuery: ParsedUrlQuery = {};

        for (const key in campaignQuery) {
            const value = campaignQuery[key];

            if (typeof value === 'string') {
                decodedQuery[key] = decodeURIComponent(value);
            } else if (Array.isArray(value)) {
                decodedQuery[key] = value.map(decodeURIComponent);
            } else {
                decodedQuery[key] = value;
            }
        }

        const campaignCategory = campaignQuery.utm_campaign;
        const campaignTerm = campaignQuery.utm_term;
        const campaignSource = campaignQuery.utm_source;

        const sessionCampaignCategory = window.sessionStorage.getItem('campaign-category');

        if (
            sessionCampaignCategory === null &&
            campaignCategory !== undefined &&
            campaignTerm !== undefined &&
            campaignSource !== undefined
        ) {
            window.sessionStorage.setItem('campaign-category', campaignCategory?.toString());
            window.sessionStorage.setItem('campaign-term', campaignTerm?.toString());
            window.sessionStorage.setItem('campaign-source', campaignSource?.toString());
        }
    }, [router.query]);

    return <></>;
};

export default CampaignChecker;
