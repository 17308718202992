import React from 'react';
import styled from 'styled-components';

interface IIndicator {
    indicatorWidth?: number;
    indicatorHeight?: number;
    selectedIndicatorColor?: string;
    unSelectedIndicatorColor?: string;
    isIndicatorTransparent?: boolean;
    className?: string;
}

interface IProps extends IIndicator {
    length: number;
    currentTab: number;
}

interface IStyle extends IIndicator {
    selected: boolean;
}

const DefaultIndicator = ({
    length,
    currentTab,
    indicatorWidth = 7,
    indicatorHeight = 7,
    selectedIndicatorColor = '#0EC4CF',
    unSelectedIndicatorColor = '#DADADA',
    isIndicatorTransparent = false,
    className,
}: IProps) => {
    const arr = Array.from(Array(length).keys());

    return (
        <IndicatorContainer className={className}>
            {arr.map((_, index: number) => (
                <Indicator
                    key={index}
                    selected={currentTab === index}
                    indicatorWidth={indicatorWidth}
                    indicatorHeight={indicatorHeight}
                    selectedIndicatorColor={selectedIndicatorColor}
                    unSelectedIndicatorColor={unSelectedIndicatorColor}
                    isIndicatorTransparent={isIndicatorTransparent}
                />
            ))}
        </IndicatorContainer>
    );
};

const IndicatorContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyCenter};
    gap: 6px;
`;

const setOpacity = (selected: boolean, isIndicatorTransparent: boolean | undefined) => {
    if (isIndicatorTransparent) {
        if (selected) return 0.9;
        return 0.5;
    }
};

const Indicator = styled.div<IStyle>`
    width: ${({ selected, indicatorWidth }) => (selected ? `${indicatorWidth}px` : '7px')};
    height: ${({ indicatorHeight }) => `${indicatorHeight}px`};
    border-radius: ${({ indicatorHeight }) => `${indicatorHeight}px`};
    background-color: ${({ selected, selectedIndicatorColor, unSelectedIndicatorColor }) =>
        selected ? selectedIndicatorColor : unSelectedIndicatorColor};
    opacity: ${({ selected, isIndicatorTransparent }) => setOpacity(selected, isIndicatorTransparent)};
`;

export default DefaultIndicator;
