export const FontStyle = (
    fontSize: number,
    lineHeight: number = fontSize + 8,
    fontWeight: string = 'normal',
    color: string = '#000000',
    letterSpacing: number = -1,
) => {
    return {
        fontSize: fontSize + 'px',
        lineHeight: lineHeight + 'px',
        fontWeight,
        letterSpacing: letterSpacing + 'px',
        color,
    };
};
