import { DateTime } from 'luxon';

import PlanCommonUtil from '@/utils/plan/PlanCommonUtil';
import { InsuredPerson } from '@/models/userInput';
import { IDMInsuranceInput, IDM_Plan, TInsuranceType } from '@/models/plan';

/*
 * 치매보험은 만 30세 이상 ~ 만 75세 이하 가입 가능
 */
const dateRange = () => {
    const now = DateTime.local();
    const max = now.minus({ years: 69, months: 6 }).plus({ days: 1 }).toFormat('yyyyMMdd');
    const min = now.minus({ years: 29, months: 6 }).plus({ days: 0 }).toFormat('yyyyMMdd');

    return {
        min: DateTime.fromISO(min),
        max: DateTime.fromISO(max),
    };
};

const setErrorMsg = (value: string) => {
    // 1. 입력 형식이 맞는지
    if (!PlanCommonUtil._checkValidDate(value)) {
        return '생년월일을 올바르게 입력해주세요';
    }

    // 2. 기간이 맞는지
    const range = dateRange();
    const userBirth = DateTime.fromISO(value);
    if (userBirth < range.max) {
        return '가입을 할 수 없는 나이입니다 (최대 가입 가능 보험나이 69세)';
    } else if (userBirth > range.min) {
        return '가입을 할 수 없는 나이입니다 (최소 가입 가능 보험나이 30세)';
    }

    return '';
};

// 물가상승률 1.1%를 기반으로 한 목표나이부터 10년간의 필요 금액의 평균
const getAveragePaymentPerTenYears = (curAge: number, stAge: number, curValue: number) => {
    const rate = 1.011;
    const n = stAge - curAge;

    const numerator = Math.pow(rate, n) * (Math.pow(rate, 10) - 1) * curValue;
    const denominator = 10 * (rate - 1);

    return Math.round(numerator / denominator / 10000);
};

const getDMListParams = (userInfo: InsuredPerson, insInput: IDMInsuranceInput) => {
    const { paymentPeriod, guaranteePeriod, livingExpense } = insInput;
    return {
        sex: userInfo.sex,
        paymentPeriod: `Y${paymentPeriod}`,
        guaranteePeriod: `A${guaranteePeriod}`,
        insuranceAge: PlanCommonUtil._calculateInsuranceAge(userInfo.birth),
        westernAge: PlanCommonUtil._calculateWesternAge(userInfo.birth),
        livingExpense: livingExpense,
    };
};

const getDMProposalParams = (userInfo: InsuredPerson, selectedProduct: IDM_Plan, selIns: TInsuranceType) => {
    const insuranceInfo = { ...selectedProduct, insuranceType: selIns };

    const params = {
        insuranceInfo,
        insuredPersonInfo: { insuredPersonBirth: userInfo.birth, insuredPersonSex: (userInfo as InsuredPerson).sex },
    };

    return params;
};

const getDMInsuredPersonInfo = (insuredPerson: InsuredPerson) => {
    const { birth: insuredPersonBirth, sex: insuredPersonSex } = insuredPerson;
    return { insuredPersonBirth, insuredPersonSex };
};

export default {
    setErrorMsg,
    getAveragePaymentPerTenYears,
    getDMProposalParams,
    getDMInsuredPersonInfo,
    getDMListParams,
};
