import { PageData } from '../common';

export interface Thread {
    client: Client | null;
    planner: Planner;
    thread: PlannerThread;
    threadComments: { metadata: PageData; data: PlannerThreadComments[] };
}

export interface Client {
    id: number;
    clientName: string;
    email: string;
    recommendedPlannerCode: string;
    groupProposalSubscribe: boolean;
    deviceId: string;
    platform: string;
    platformId: string;
    registrationState: number;
    createdAt: string;
    updatedAt: string;
    lastConnectedAt: string;
    clientProfileImg: string | null;
}

export interface Planner {
    id: number;
    companyNumber: string;
    phoneNumber: string;
    masterPermission: number;
    locationCode: number;
    introduce: string;
    introduceDetail: string;
    minAcceptablePremium: number;
    likes: number;
    subscribe: boolean;
    isPhoneNumberPublic: boolean;
    registrationAgreement: boolean;
    consumerLawAgreement: boolean;
    name: string;
    age: number;
    sex: number;
    tier: number;
    indemnityCert: boolean;
    lifeCert: boolean;
    variableCert: boolean;
    award: {
        isCertifiedPlanner: boolean;
        certifiedPeriod: number;
        customerService: boolean;
        inaProfessional: boolean;
        highlyReview: boolean;
    };
    sns: { kakao: null | string; blog: null | string; youtube: null | string };
}

export interface PlannerThread {
    id: number;
    plannerId: number;
    clientId: number;
    content: string;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    isBlind: boolean;
}

export interface PlannerThreadComments {
    id: number;
    threadId: number;
    userType: number;
    userId: number;
    content: string;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    isBlind: boolean;
}

export const USERTYPE = {
    PLANNER: 1,
    CLIENT: 2,
};

// 본부장, 지사장이 관리자
export const POSITION = {
    NOTHING: 0, // 해당없음
    HEAD_LEADER: 1, // 본부장
    ORGANIZATION_LEADER: 2, // 지사장
    TEAM_LEADER: 3, // 팀장
    ELITE_PLANNER: 4, // 위촉FC
    NORMAL_PLANNER: 5, // 일반FC
    ASSISTANT: 6, // 총무
};

export interface PromotionPlanner {
    companyNumber: string;
    id: number;
    name: string;
}
