import { useRouter } from 'next/router';
import { useState } from 'react';

import { API, URLs } from '@/api';
import { IAllAlarm } from '@/models';
import useQueryHook from '@/hooks/useQueryHook';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { alarmArr, hasAlertSelector } from '@/recoil';

// export const GetNotificationList = () => {
//     const { data, isSuccess, RenderError } = useQueryHook(
//         ['notificationList'],
//         async ({ pageParam = 1 }) => {
//             return await API.get(URLs.noti.notificationList, {
//                 page: pageParam,
//             });
//         },
//         {
//             // TODO: Add onSuccess, onFail here for checks later
//             onSuccess: res => {
//                 console.log('Infinite fetch is successful though result didnt show up in console', res);
//             },
//             onError: () => {
//                 console.log('Error during the actual fetching process');
//             },
//         },
//     );

//     return { data, isSuccess, RenderError };
// };

export const GetNotificationList = async (page?: number) => {
    // const { data, isSuccess, RenderError } = useQueryHook(
    //     ['notificationList'],
    //     async ({ pageParam = 1 }) => {
    //         return await API.get(URLs.noti.notificationList, {
    //             page: pageParam,
    //         });
    //     },
    //     {
    //         // TODO: Add onSuccess, onFail here for checks later
    //         onSuccess: res => {
    //             console.log('Infinite fetch is successful though result didnt show up in console', res);
    //         },
    //         onError: () => {
    //             console.log('Error during the actual fetching process');
    //         },
    //     },
    // );
    const data = await API.get(URLs.noti.notificationList, { page });
    const notification = data as unknown as IAllAlarm;

    return notification;
};
