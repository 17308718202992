import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { appState } from '@/recoil';

const AppChecker = () => {
    const setIsApp = useSetRecoilState(appState);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const browser = localStorage.getItem('isApp');
            setIsApp(browser);
        }
    }, []);

    return <></>;
};

export default AppChecker;
