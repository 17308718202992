import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { FontStyle } from '@/utils/StyleUtil';
import StringUtil from '@/utils/StringUtil';
import { QNA_STATUS } from '@/models/ina';
import DividerDefault from '../Divider/Default';

interface IQuesitonInfo {
    status?: number;
    content: string;
    contentLine?: number;
    hasQuestionMark?: boolean;
    showStatus?: boolean;
    answerCount?: number;
    createdAt: string;
    hasDivider?: boolean;
    isLast?: boolean;
    style?: CSSProperties;
    className?: string;
    contentTitle?: string;
}

const QnAInfoByStatusInquiry = ({
    status,
    content,
    contentLine,
    hasQuestionMark = true,
    showStatus = true,
    answerCount,
    createdAt,
    hasDivider,
    isLast = false,
    style,
    className,
    contentTitle,
}: IQuesitonInfo) => {
    let iconBgColor;
    let iconTextColor;
    let statusText;

    switch (status) {
        case QNA_STATUS.WAITING:
            iconBgColor = '#FFE4E4';
            iconTextColor = '#FF1E1E';
            statusText = '답변대기';
            break;
        case QNA_STATUS.COMPLETE:
            iconBgColor = '#EAFAFF';
            iconTextColor = '#00ACE2';
            statusText = '답변완료';
            break;
        case QNA_STATUS.UNANSWERED:
            iconBgColor = '#F0F0F0';
            iconTextColor = '#ACACAC';
            statusText = '답변완료(미답변)';
            break;
        case QNA_STATUS.ANSWER:
            iconBgColor = '#eafffe';
            iconTextColor = '#0ec4cf';
            statusText = '';
            break;
    }

    return (
        <div>
            <ContentContainer className={className} style={style} isAnswerPage={contentLine !== undefined}>
                {hasQuestionMark && (
                    <IconContainer iconTextColor={iconTextColor} iconBgColor={iconBgColor}>
                        <div className="icon_text">{status === QNA_STATUS.ANSWER ? 'A' : 'Q'}</div>
                        <div className="icon_background" />
                    </IconContainer>
                )}
                <FlexContainer contentLine={contentLine}>
                    <QuestionContent contentLine={contentLine}>{contentTitle ?? content}</QuestionContent>
                    <StatusContainer iconTextColor={iconTextColor}>
                        <div>
                            {showStatus && (
                                <div className="answer_status_box">
                                    <div className="status_text">{statusText}</div>
                                    {answerCount !== undefined && (
                                        <>
                                            <div className="vertical_divider" />
                                            <div className="answer_cnt">{answerCount}개의 답변</div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="created_at">{StringUtil.displayedAt(createdAt)}</div>
                    </StatusContainer>
                </FlexContainer>
            </ContentContainer>
            {hasDivider && !isLast && (
                <DividerDefault
                    color="#f1f1f1"
                    thickness={1}
                    length="calc(100% - 40px)"
                    style={{ marginLeft: '20px' }}
                />
            )}
        </div>
    );
};

const ContentContainer = styled.div<{ isAnswerPage: boolean }>`
    ${({ theme }) => theme.flex.flexOnly};
    &:active {
        ${({ isAnswerPage }) => isAnswerPage && `background-color: #fbfbfb`};
    }
`;

const IconContainer = styled.div<{ iconTextColor?: string; iconBgColor?: string }>`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyCenter};
    position: relative;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    .icon_text {
        ${FontStyle(17, 20, '500', undefined, -1)}
        color: ${({ iconTextColor }) => iconTextColor};
        z-index: 1;
    }
    .icon_background {
        width: 22px;
        height: 10px;
        background-color: ${({ iconBgColor }) => iconBgColor};
        position: absolute;
        margin-top: 12px;
        z-index: 0;
    }
`;

const FlexContainer = styled.div<{ contentLine?: number }>`
    ${({ theme }) => theme.flex.flexColumn};
    flex: 1;
    .content_text {
        ${FontStyle(14, 22, '400', '#444444', -1)}
        ${({ theme, contentLine }) => theme.settingByBrowser.lineClamp(contentLine)}
        white-space: pre-wrap;
    }
`;

const QuestionContent = styled.h2<{ contentLine?: number }>`
    ${FontStyle(14, 22, '400', '#444444', -1)}
    ${({ theme, contentLine }) => theme.settingByBrowser.lineClamp(contentLine)}
    white-space: pre-wrap;
    margin: 0px;
`;

const StatusContainer = styled.div<{ iconTextColor?: string }>`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyBetween};
    flex: 1;
    margin-top: 14px;
    .answer_status_box {
        ${({ theme }) => theme.flex.flexOnly};
        ${({ theme }) => theme.flex.justifyCenter};
        .status_text {
            ${FontStyle(12, 20, '400', undefined, -1)}
            color: ${({ iconTextColor }) => iconTextColor}
        }
        .vertical_divider {
            width: 1px;
            height: 10px;
            margin: 0 6px;
            background-color: #ececec;
        }
        .answer_cnt {
            ${FontStyle(12, 20, '400', '#888888', -1)}
        }
    }
    .created_at {
        ${FontStyle(12, 20, '400', '#c8c8c8', -1)}
    }
`;

export default QnAInfoByStatusInquiry;
