import { useRouter } from 'next/router';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import useMutationHook from '@/hooks/useMutationHook';
import { useSocket } from '@/hooks/useSocket';
import { uuidState, loginState, isLoginState, profileState, groupProposalState } from '@/recoil';
import { noLoginState, noProfileState } from '@/models';
import { API, URLs } from '@/api';
import CheckGuest from './checkGuest';

interface IProps {
    clientId: number;
    tokenSet: {
        accessToken: string;
        refreshToken: string;
    };
}

const RegistGuest = () => {
    const router = useRouter();
    const { connectSocket, disconnectSocket } = useSocket();
    const uuid = useRecoilValue(uuidState);
    const setLogin = useSetRecoilState(loginState);
    const isLogin = useRecoilValue(isLoginState);
    const setProfile = useSetRecoilState(profileState);
    const setIsGetGroupProposal = useSetRecoilState(groupProposalState);
    // const { checkGuest } = CheckGuest();

    const socketConnect = (accessToken: string, refreshToken: string) => {
        disconnectSocket();
        connectSocket(accessToken, refreshToken);
    };

    const changeState = (res: IProps, variable?: any) => {
        if (window.ReactNativeWebView)
            window.ReactNativeWebView.postMessage(JSON.stringify({ clearAllPushNoti: true }));

        socketConnect(res.tokenSet.accessToken, res.tokenSet.refreshToken);

        setLogin({ ...noLoginState, clientId: res.clientId });
        setProfile(noProfileState);
        setIsGetGroupProposal(false);

        if (isLogin && variable.isForceLogout) {
            router.push('/logout');
        } else if (isLogin && variable.isDeleteAccount) router.push('/');
    };
    //console.log('checkGuest process, ', checkGuest);
    // console.log('Trying out new api, ', uuidGuestExisit);
    // const currentPersistId = window?.localStorage?.getItem('persist-uuid')
    //     ? localStorage.getItem('persist-uuid')
    //     : null;

    // if (currentPersistId === 'eccefbe8-660b-4fe4-8705-1326c74e1b08') {
    //     console.log('Matched the test id');
    // }

    const { mutate: registGuest, errorPopUp } = useMutationHook(
        ['regist-guest'],
        async () => {
            return await API.post(URLs.auth.registerGuest, { deviceId: uuid });
        },
        {
            onSuccess: (res, variable) => {
                if (!variable.isDeleteAccount) changeState(res, variable);
            },
            onError: err => errorPopUp(err?.response?.status),
        },
    );

    return { changeState, registGuest };
};

export default RegistGuest;
