import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import useModal from '@/hooks/useModal';
import TitleNAdditionalContents from '@/components/ContextPrinter/TitleNAdditionalContents';
import BottomFixedButton from '@/components/Button/BottomFixedButton';
import CheckTerms from '@/containers/login/agreeTerms/CheckTerms';
import TypeEssentialInfo from '@/containers/modal/login/TypeEssentialInfo';
import { profileState } from '@/recoil';
import { typedUserInfoState } from '@/recoil/login';

const AgreeTerms = ({ name }: { name?: string }) => {
    const { addModal, removeCurrentModal } = useModal();
    const setUserInfo = useSetRecoilState(typedUserInfoState);
    const setProfile = useSetRecoilState(profileState);
    const [isActive, setIsActive] = useState(false);

    const onClickNext = () => {
        if (isActive) {
            setUserInfo({ name: '', code: '', designatedCode: '' });
            setProfile({
                needUpdate: false,
                profileURL: '/img/profile_image/nonMember@3x.png',
                profileImage: undefined,
            });

            addModal({
                key: 'fullScreen',
                props: {
                    className: 'movingFocus',
                    content: <TypeEssentialInfo name={name} />,
                    handleCloseModal: removeCurrentModal,
                    isCloseIconOnRight: false,
                },
            });
        }
    };

    return (
        <div>
            <Container>
                <TitleNAdditionalContents
                    title={`픽앤플랜 가입을 위해\n이용약관 동의를 해주세요`}
                    subText="안전한 서비스 이용을 위해서는 약관 동의가 꼭 필요해요!"
                />
                <CheckTerms setActive={(bool: boolean) => setIsActive(bool)} />
            </Container>
            <BottomFixedButton
                text="다음 단계"
                buttonColor={isActive ? '#0ec4cf' : '#acacac'}
                onClickButton={onClickNext}
            />
        </div>
    );
};

const Container = styled.div`
    padding: 26px 20px 0 20px;
`;

export default AgreeTerms;
