import React from 'react';
import styled from 'styled-components';

import { FontStyle } from '@/utils/StyleUtil';
import SvgSelector from '@/components/Svg/SvgSelector';

interface IProps {
    type: 'communication' | 'access' | 'badRequest';
}

function ErrorPartial({ type }: IProps) {
    const renderText = () => {
        if (type === 'communication') {
            return <TextContainer>{'통신에 실패했습니다\n다시 시도해주세요'}</TextContainer>;
        } else if (type === 'access') {
            return <TextContainer>{'잘못된 접근입니다\n다시 시도해주세요'}</TextContainer>;
        } else if (type === 'badRequest') {
            return <TextContainer>{'관리자에게 문의바랍니다\n(문의 : picknplan@primeasset.kr)'}</TextContainer>;
        }
    };

    return (
        <ErrorContainer>
            <SvgSelector svg="round-exclamation-mark-circle" width={40} height={40} strokeWidth={2} stroke="#c8c8c8" />
            {renderText()}
        </ErrorContainer>
    );
}

const TextContainer = styled.div`
    ${({ theme }) => theme.flex.flexColumn};
    ${({ theme }) => theme.flex.justifyCenter};
    ${FontStyle(14, 22, '400', '#acacac', -1)}
    white-space:pre-wrap;
    text-align: center;
`;

const ErrorContainer = styled.div`
    ${({ theme }) => theme.flex.flexColumn};
    ${({ theme }) => theme.flex.justifyStart};
    margin: 10px;
    svg {
        margin-bottom: 10px;
    }
`;

export default ErrorPartial;
