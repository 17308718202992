import SendBird, { AdminMessage, BaseChannel, ChannelHandler, FileMessage, GroupChannel, UserMessage } from 'sendbird';

SendBird.setLogLevel(SendBird.LogLevel.WARN);

interface IPushToken {
    platform: string;
    fcmToken: string;
    apnsToken: string;
}

class SendBirdWrapper {
    instance: SendBird.SendBirdInstance;
    groupChannelCollection!: SendBird.GroupChannelCollection;

    constructor(appId: string) {
        this.instance = new SendBird({ appId, localCacheEnabled: true });
    }

    connect = (userId: string, callback: SendBird.userCallback) => {
        this.instance.connect(userId, callback).catch(err => console.log(err));
    };

    isConnect = () => {
        const connectStatus = this.instance.getConnectionState();

        if (connectStatus === 'CONNECTING' || connectStatus === 'OPEN') return true;
        return false;
    };

    disconnect = async ({ platform, fcmToken, apnsToken }: IPushToken) => {
        if (platform === 'Android') this.instance.unregisterGCMPushTokenForCurrentUser(fcmToken);
        else if (platform === 'iOS') this.instance.unregisterAPNSPushTokenForCurrentUser(apnsToken);

        await this.instance.disconnect(() => {
            console.log('sendbird disconnected!');
        });
    };

    registTokenForFCM = async (platform: string, fcmToken: string, apnsToken: string) => {
        const pushTemplate = this.instance.PUSH_TEMPLATE_ALTERNATIVE;

        if (platform === 'android' || platform === 'Android')
            this.instance.registerGCMPushTokenForCurrentUser(fcmToken);
        else if (platform === 'ios' || platform === 'iOS') this.instance.registerAPNSPushTokenForCurrentUser(apnsToken);

        this.instance.setPushTemplate(pushTemplate);
    };

    updateUserInfo = async (nickName: string, profileImage?: File) => {
        if (profileImage) return this.instance.updateCurrentUserInfoWithProfileImage(nickName, profileImage);
        else {
            const response = await fetch('/img/profile_image/nonMember@3x.png');
            const blob = await response.blob();
            const defaultProfile = new File([blob], 'defaultProfile.png', { type: 'image/png' });

            return this.instance.updateCurrentUserInfoWithProfileImage(nickName, defaultProfile);
        }
    };

    createUserMetaData = async () => {
        await this.instance.currentUser.createMetaData({ userStatus: 'alive' });
    };

    updateUserMetaData = () => {
        this.instance.currentUser.updateMetaData({ userStatus: 'dead' });
    };

    loadMoreChannels = async (callback: Function) => {
        if (this.groupChannelCollection.hasMore) {
            this.groupChannelCollection.loadMore().then(channelList => {
                callback(channelList);
            });
        }
    };

    initGroupChannelCollection = (updateCallback: Function) => {
        // 정렬 설정
        const groupChannelOrder = this.instance.GroupChannelCollection.GroupChannelOrder.LATEST_LAST_MESSAGE;

        // 필터 설정
        const groupChannelFilter = new this.instance.GroupChannelFilter();
        groupChannelFilter.includeEmpty = true;
        groupChannelFilter.hiddenChannelFilter = this.instance.GroupChannelFilter.HiddenChannelFilter.UNHIDDEN;

        this.groupChannelCollection = this.instance.GroupChannel.createGroupChannelCollection()
            .setOrder(groupChannelOrder)
            .setFilter(groupChannelFilter)
            .build();

        // 핸들러 설정
        const groupChannelCollectionHandler = (callback: Function) => {
            const channelList = this.groupChannelCollection.channelList;

            return {
                async onChannelsAdded(_context: object, _channels: BaseChannel[]) {
                    const updatedChannels = [..._channels, ...channelList];

                    callback(updatedChannels);
                },
                async onChannelsUpdated(_context: object, _channels: BaseChannel[]) {
                    const updatedChannels = channelList.map(channel => {
                        if (channel.url === _channels[0].url) return _channels[0];
                        else return channel;
                    });

                    callback(updatedChannels);
                },
                async onChannelsDeleted(_context: object, channelUrls: string[]) {
                    const updatedChannels = channelList.filter(channel => channel.url !== channelUrls[0]);

                    callback(updatedChannels);
                },
            };
        };

        // 설정들 Set
        this.groupChannelCollection.setGroupChannelCollectionHandler(
            groupChannelCollectionHandler((channelList: GroupChannel[]) => {
                updateCallback(channelList);
            }),
        );

        sendbird.loadMoreChannels((channelList: GroupChannel[]) => {
            updateCallback(channelList);
        });
    };

    getChannel = async (
        channelURL: string,
        callback: Function,
        errorCallback: (_err: SendBird.SendBirdError) => void,
        isLogin: boolean,
    ) => {
        if (!channelURL) return;
        else {
            if (isLogin) {
                await sendbird.instance.GroupChannel.getChannel(channelURL, (groupChannel, error) => {
                    if (error) {
                        console.log(error.code, error.message);
                        errorCallback(error);
                    } else {
                        callback(groupChannel);
                    }
                });
            }
        }
    };

    initConnectionHandler = () => {
        const connectionHandler = new this.instance.ConnectionHandler();

        connectionHandler.onReconnectStarted = () => {
            console.log('connecting....');
        };
        connectionHandler.onReconnectSucceeded = () => {
            console.log('success to connect');
        };
        connectionHandler.onReconnectFailed = () => {
            console.log('Connection failed. Please check the network status.');
        };

        return connectionHandler;
    };

    initChannelHandler = (channelURL: string | undefined, callback: Function) => {
        const channelHandler = new sendbird.instance.ChannelHandler();

        channelHandler.onMessageReceived = (channel, message) => {
            console.log('message receive : ', channel, message);

            if (channel.url === channelURL) {
                callback({ message, isRead: true });
                (channel as GroupChannel).markAsRead().catch(err => console.log('MarkAsRead onMessageReceived: ', err));
            }
        };
        channelHandler.onMessageUpdated = (channel, message) => {
            console.log('message update : ', channel, message);

            if (channel.url === channelURL) {
                callback({ message, isRead: true });
                (channel as GroupChannel).markAsRead().catch(err => console.log('MarkAsRead onMessageUpdated: ', err));
            }
        };
        channelHandler.onMessageDeleted = (channel, message) => {
            console.log('message delete : ', channel, message);

            // if (channel.url === channelURL) {
            //     callback(message);
            //     (channel as GroupChannel).markAsRead();
            // }
        };

        return channelHandler;
    };

    initMessageHandler = (groupChannel: GroupChannel, callback: Function) => {
        const messageFilter = new sendbird.instance.MessageFilter();

        const startingPoint = Date.now();
        const messageCollectionFetchLimit = 100;
        const messageCollection: any = groupChannel
            .createMessageCollection()
            .setFilter(messageFilter)
            .setStartingPoint(startingPoint)
            .setLimit(messageCollectionFetchLimit)
            .build();

        messageCollection
            .initialize(sendbird.instance.MessageCollection.MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
            .onCacheResult((err: Error, messages: (UserMessage | FileMessage | AdminMessage)[]) => {
                if (err) {
                    console.log(err);
                    return;
                }

                const msgArr = [];
                for (let message of messages) {
                    const isRead = groupChannel.getUnreadMemberCount(message) > 0 ? false : true;
                    msgArr.push({ message, isRead: isRead });
                }

                callback(msgArr);
            })
            .onApiResult((err: Error, messages: (UserMessage | FileMessage | AdminMessage)[]) => {
                if (err) {
                    console.log(err);
                    return;
                }

                const msgArr = [];
                for (let message of messages) {
                    const isRead = groupChannel.getUnreadMemberCount(message) > 0 ? false : true;
                    msgArr.push({ message, isRead: isRead });
                }

                callback(msgArr);
            });

        groupChannel.markAsRead().catch(err => console.log('MarkAsRead initialize: ', err));

        return messageCollection;
    };

    loadPreviousMsgs = (groupChannel: GroupChannel, messageCollection: any, callback: Function) => {
        if (messageCollection && messageCollection.hasPrevious) {
            messageCollection.loadPrevious().then((messages: (UserMessage | FileMessage | AdminMessage)[]) => {
                const msgArr = [];
                for (let message of messages) {
                    const isRead = groupChannel.getUnreadMemberCount(message) > 0 ? false : true;
                    msgArr.push({ message, isRead: isRead });
                }
                callback(msgArr);
            });
        } else callback([]);
    };

    updateReadState = (channelHandler: ChannelHandler, channelURL: string, callback: Function) => {
        channelHandler.onReadReceiptUpdated = (channel: GroupChannel) => {
            if (channel.url === channelURL) {
                callback(true);
            } else {
                callback(false);
            }
        };
    };

    hideMessages = (channelURL: string) => {
        this.getChannel(
            channelURL,
            (groupChannel: GroupChannel) =>
                groupChannel.hide(true, true, (response, error) => {
                    if (error) {
                        console.log('error occurs when hide messages : ', error);
                    } else {
                        this.instance.clearCachedMessages([channelURL]);
                    }
                }),
            err => console.log(err.code, err.message),
            true,
        );
    };

    leaveChannel = (channelURL: string, ftAfterLeave: Function) => {
        this.getChannel(
            channelURL,
            async (groupChannel: GroupChannel) => {
                await groupChannel.hide(true, true, (response, error) => {
                    if (error) {
                        console.log('error occurs when leave channel : ', error);
                    } else {
                        console.log('leave chat room : ', response);
                        ftAfterLeave();
                    }
                });
            },
            err => console.log(err.code, err.message),
            true,
        );
    };

    findInvitedUser = (inviterId: string, memberList: SendBird.Member[]) => {
        if (memberList[0].userId !== inviterId) return memberList[0];
        else return memberList[1];
    };

    blockUser = async (plannerId: string) => {
        await this.instance.blockUserWithUserId(plannerId);
    };

    unblockUser = async (plannerId: string) => {
        await this.instance.unblockUserWithUserId(plannerId);
    };

    // TODO meta-data에 넣을 값이 추가되면 그 때 이 부분도 수정해야할 듯
    getMetaData = (channelURL: string, callback: Function) => {
        this.getChannel(
            channelURL,
            async (groupChannel: GroupChannel, error: any) => {
                if (error) {
                    console.log('error occurs when get meta data');
                } else {
                    return await groupChannel.getMetaData(['isEvaluated'], (response: Object, error: any) => {
                        if (error) {
                            console.log('error occurs when get meta data');
                        } else {
                            callback(response);
                        }
                    });
                }
            },
            err => console.log(err.code, err.message),
            true,
        );
    };

    updateMetaData = (channelURL: string, data: any) => {
        this.getChannel(
            channelURL,
            async (groupChannel: GroupChannel, error: any) => {
                if (error) {
                    console.log('error occurs when update meta data');
                } else {
                    console.log('update meta data : ', data);
                    await groupChannel.updateMetaData(data);
                }
            },
            err => console.log(err.code, err.message),
            true,
        );
    };

    isBlockedPlanner = async (plannerId: string) => {
        const query = this.instance.createBlockedUserListQuery();

        const blockedUsers = await query.next();

        for (let i = 0; i < blockedUsers.length; i += 1) {
            if (blockedUsers[i].userId === plannerId) {
                return true;
            }
        }

        return false;
    };

    checkPushTrigger = async () => {
        return await this.instance.getPushTriggerOption();
    };

    setPushTrigger = async (bool: boolean) => {
        if (bool) await this.instance.setPushTriggerOption('all');
        else await this.instance.setPushTriggerOption('off');
    };
}

export default SendBirdWrapper;

const appId: string | undefined = process.env.NEXT_PUBLIC_SENDBIRD_APP_ID;

export const sendbird = new SendBirdWrapper(appId as string);
