import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSetRecoilState } from 'recoil';
import { prevPathState } from '@/recoil';

const PageChecker = () => {
    const router = useRouter();

    const setPrevPath = useSetRecoilState(prevPathState);

    useEffect(() => {
        const prev = window.sessionStorage.getItem('prevPage');
        const curr = window.sessionStorage.getItem('currPage');

        if (prev && curr) {
            window.sessionStorage.setItem('prevPage', curr);
            window.sessionStorage.setItem('currPage', router.pathname);
            setPrevPath(curr);
        } else {
            setPrevPath(router.pathname);
            window.sessionStorage.setItem('prevPage', router.pathname);
            window.sessionStorage.setItem('currPage', router.pathname);
        }
    }, [router.pathname, setPrevPath]);

    return <></>;
};

export default PageChecker;
