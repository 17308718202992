import React, { useState } from 'react';
import Image from 'next/image';
import styled from 'styled-components';

interface IProps {
    imageArr: string[];
    selectedTab: number;
    handleTab: Function;
}

interface IImgLayout {
    width?: number;
    height?: number;
    layout: 'fill' | 'responsive';
}

const ThumbnailIndicator = ({ imageArr, selectedTab, handleTab }: IProps) => {
    const setOriginSizeArr = () => {
        const arr = [];

        for (let i = 0; i < imageArr.length; i += 1) arr.push({ width: 1, height: 1 });

        return arr;
    };

    const [originImageSize, setOriginImageSize] = useState(setOriginSizeArr());

    const setLayout = (index: number): IImgLayout => {
        const width = originImageSize[index].width;
        const height = originImageSize[index].height;

        if (width > height)
            return {
                layout: 'fill',
            };
        else
            return {
                width,
                height,
                layout: 'responsive',
            };
    };

    return (
        <ThumbnailContainer>
            {imageArr.map((item: string, index: number) => (
                <EachThumbnailContainer
                    key={item}
                    isSelected={selectedTab === index}
                    onClick={() => {
                        handleTab(index);
                    }}
                >
                    <Image
                        src={item}
                        alt={`image_${index}`}
                        {...setLayout(index)}
                        onLoad={e => {
                            const tmp = [...originImageSize];
                            tmp[index].width = (e.target as HTMLImageElement).naturalWidth;
                            tmp[index].height = (e.target as HTMLImageElement).naturalHeight;
                            setOriginImageSize([...tmp]);
                        }}
                    />
                </EachThumbnailContainer>
            ))}
        </ThumbnailContainer>
    );
};

const ThumbnailContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyCenter};
    gap: 8px;
    margin-top: 30px;
`;

const EachThumbnailContainer = styled.div<{ isSelected: boolean }>`
    position: relative;
    width: 60px;
    height: 60px;
    overflow: hidden;
    ${({ isSelected }) => isSelected && `border: 3px solid #0ec4cf;`}
`;

export default ThumbnailIndicator;
