import { DefaultValue } from 'recoil';

import { InsuredPerson, InsuredFetus, MedicalInsuredPerson } from '@/models/userInput';

const isMedicalUser = (
    userInfo: MedicalInsuredPerson | InsuredPerson | InsuredFetus | DefaultValue,
): userInfo is MedicalInsuredPerson => {
    return !!(userInfo as MedicalInsuredPerson)?.risk;
};

const isFetus = (
    userInfo: MedicalInsuredPerson | InsuredPerson | InsuredFetus | DefaultValue,
): userInfo is InsuredFetus => {
    return !!(userInfo as InsuredFetus)?.expectedBirthDate;
};

const isUser = (
    userInfo: MedicalInsuredPerson | InsuredPerson | InsuredFetus | DefaultValue,
): userInfo is InsuredPerson => {
    if (!isMedicalUser(userInfo) && !isFetus(userInfo)) return true;
    return false;
};

export default { isMedicalUser, isFetus, isUser };
