import { DefaultValue } from 'recoil';

import {
    ITM_Plan,
    ITM_Senior,
    IDE_Plan,
    IAC_Plan,
    IDM_Plan,
    TMSENIOR,
    TM,
    TMFETUS,
    TMSICK,
    DE,
    AC,
    DM,
    IMFETUS,
    IM,
    IIM_Plan,
    TPlan,
    TRequestType,
    INQUIRYTM,
    INQUIRYTMFETUS,
    INQUIYTMSICK,
    INQUIRYDE,
    INQUIRYDM,
    TInsuranceType,
    INQUIRY,
    TProduct,
    TNoProduct,
} from '@/models/plan';
import { DateTime } from 'luxon';
import {
    IDE_Direct,
    IDM_Direct,
    IInsuredFetusInfo,
    IInsuredPersonInfo,
    ITM_Direct,
    MatchingType,
} from '@/models/myPlan';

const isSenior = (params: TPlan | DefaultValue | undefined): params is ITM_Senior => {
    if (params instanceof DefaultValue) return false;
    return params?.insuranceType === TMSENIOR;
};

const isTMPlan = (params: TPlan | DefaultValue | undefined): params is ITM_Plan | ITM_Direct => {
    if (params instanceof DefaultValue) return false;
    return params?.insuranceType === TM || params?.insuranceType === TMFETUS || params?.insuranceType === TMSICK;
};

const isDEPlan = (params: TPlan | DefaultValue | undefined): params is IDE_Plan | IDE_Direct => {
    if (params instanceof DefaultValue) return false;
    return params?.insuranceType === DE;
};

const isACPlan = (params: TPlan | DefaultValue | undefined): params is IAC_Plan => {
    if (params instanceof DefaultValue) return false;
    return params?.insuranceType === AC;
};

const isDirect = (
    params: ITM_Plan | IIM_Plan | ITM_Senior | IAC_Plan | IDE_Plan | IDM_Plan | DefaultValue | undefined,
): params is IAC_Plan | ITM_Senior => {
    return isACPlan(params) || isSenior(params);
};

const isDMPlan = (params: TPlan | DefaultValue | undefined): params is IDM_Plan | IDM_Direct => {
    if (params instanceof DefaultValue) return false;
    return params?.insuranceType === DM;
};

const isIMPlan = (params: TPlan | DefaultValue | undefined): params is IIM_Plan => {
    if (params instanceof DefaultValue) return false;
    return params?.insuranceType === IM || params?.insuranceType === IMFETUS;
};

const isInquiryTM = (requestType: TRequestType, plan: TPlan | DefaultValue | undefined): plan is ITM_Direct => {
    if (plan instanceof DefaultValue) return false;
    return requestType === INQUIRYTM || requestType === INQUIRYTMFETUS || requestType === INQUIYTMSICK;
};

const isInquiryDE = (requestType: TRequestType, plan: TPlan | DefaultValue | undefined): plan is IDE_Direct => {
    if (plan instanceof DefaultValue) return false;
    return requestType === INQUIRYDE;
};

const isInquiryDM = (requestType: TRequestType, plan: TPlan | DefaultValue | undefined): plan is IDM_Direct => {
    if (plan instanceof DefaultValue) return false;
    return requestType === INQUIRYDM;
};

const getRestTime = (createdAt: string, timelimitSec: number) => {
    const now = DateTime.now();
    const startTime = DateTime.fromISO(createdAt);
    const addLimit = startTime.plus({ milliseconds: timelimitSec * 1000 });
    const rest = addLimit.diff(now).milliseconds / 1000;
    if (rest < 0) return { min: -1, sec: -1 };
    return { min: Math.floor(rest / 60), sec: Math.floor(rest % 60) };
};

const isFetus = (
    insuredPersonInfo: IInsuredFetusInfo | IInsuredPersonInfo | undefined,
): insuredPersonInfo is IInsuredFetusInfo => {
    return !!(insuredPersonInfo as IInsuredFetusInfo)?.expectedBirthDate;
};

const convertInsuranceType = (matchingType: number, insuranceType?: TInsuranceType): TRequestType => {
    if (matchingType === MatchingType.NORMAL && insuranceType) {
        return insuranceType;
    } else if (matchingType === MatchingType.ONETOONE && insuranceType) {
        return insuranceType;
    } else if (matchingType === MatchingType.NOPRODUCT && insuranceType) {
        switch (insuranceType) {
            case TM:
                return INQUIRYTM;
            case TMFETUS:
                return INQUIRYTMFETUS;
            case TMSICK:
                return INQUIYTMSICK;
            case DE:
                return INQUIRYDE;
            case DM:
                return INQUIRYDM;
            default:
                return insuranceType;
        }
    } else if (matchingType === MatchingType.PLANNERINQUIRY) {
        return INQUIRY;
    }
    return 0;
};

const isProduct = (requestType: TRequestType, plan: TPlan): plan is TProduct => {
    if (
        requestType === TM ||
        requestType === TMFETUS ||
        requestType === TMSICK ||
        requestType === IM ||
        requestType === IMFETUS ||
        requestType === DE ||
        requestType === DM
    )
        return true;
    return false;
};

const isNoProduct = (requestType: TRequestType, plan: TPlan): plan is TNoProduct => {
    if (
        requestType === INQUIRY ||
        requestType === INQUIRYTM ||
        requestType === INQUIRYTMFETUS ||
        requestType === INQUIYTMSICK ||
        requestType === INQUIRYDE ||
        requestType === INQUIRYDM ||
        requestType === AC ||
        requestType === TMSENIOR
    )
        return true;
    return false;
};

export default {
    isSenior,
    isTMPlan,
    isDEPlan,
    isACPlan,
    isDirect,
    isDMPlan,
    isIMPlan,
    getRestTime,
    isFetus,
    convertInsuranceType,
    isProduct,
    isNoProduct,
    isInquiryDE,
    isInquiryDM,
    isInquiryTM,
};
