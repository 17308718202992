import { DateTime } from 'luxon';
import TotalMedicalUtil from './TotalMedicalUtil';
import {
    DE,
    DM,
    IACInsuranceInput,
    IDEInsuranceInput,
    IDMInsuranceInput,
    IM,
    IMFETUS,
    INQUIRY,
    ISeniorInput,
    ITMInsuranceInput,
    TInsuranceType,
    TM,
    TMFETUS,
    TMSICK,
} from '@/models/plan';
import { InsuredPerson, MedicalInsuredPerson, InsuredFetus } from '@/models/userInput';
import DeathUtil from './DeathUtil';
import DementialUtil from './DementialUtil';
import IndemnityUtil from './IndemnityUtil';
import UserInfoUtil from './UserInfoUtil';
/*
 * 20220101 이 date 값인 지 체크
 */
const _checkValidDate = (v: string) => {
    const date = DateTime.fromFormat(v, 'yyyyMMdd');
    return date.isValid;
};

/*
 * 해당 날짜가 두 날짜 사이에 있는 지 체크
 */
const _checkDateRange = (v: string, min: DateTime, max: DateTime) => {
    const inputDate = DateTime.fromISO(v);
    if (min <= inputDate && inputDate <= max) {
        return true;
    }
    return false;
};

/*
 * 현재 날짜로부터 89년 6개월이 지난 사람의 Date = ex. 10101130
 */
const _birthMin = () => {
    const now = DateTime.local();
    const min = now.minus({ years: 90, months: 6 }).plus({ days: 1 }).toFormat('yyyyMMdd');
    return min;
};

/*
 * 현재 날짜 Date = ex. 20200115
 */
const _birthMax = () => {
    const now = DateTime.local().toFormat('yyyyMMdd');
    return now;
};

/*
 * 유병자는 만 15세 이상부터 가입 가능
 */
const _sickBirthMax = () => {
    const now = DateTime.local();
    const max = now.minus({ years: 15 }).toFormat('yyyyMMdd');
    return max;
};

/*
 * 입력 값의 보험 나이 계산
 */
const _calculateInsuranceAge = (v: number | string) => {
    const birthDay = DateTime.fromFormat(v.toString(), 'yyyyMMdd').toISODate();
    const now = DateTime.local().toISODate();
    const start = DateTime.fromISO(birthDay);
    const end = DateTime.fromISO(now);

    const days = end.diff(start, ['year', 'months', 'days']).plus({ months: 6 }).toObject();
    const age = days.years! + Math.floor(days.months! / 12);

    if (Number.isNaN(age)) return 0;
    if (age < 0) return 0;
    return age;
};

/*
 * 입력 값의 만 나이 계산
 */
const _calculateWesternAge = (v: number | string) => {
    const birthDay = DateTime.fromFormat(v.toString(), 'yyyyMMdd').toISODate();
    const now = DateTime.local().toISODate();
    const start = DateTime.fromISO(birthDay);
    const end = DateTime.fromISO(now);

    const days = end.diff(start, ['year', 'months', 'days']).toObject();

    if (days.years === undefined) return 0;
    return days.years;
};

/*
 * Calculating the first two digits of the year. Western age = 98 is the baseline
 * Will last until 2097
 */
const _calculatePrependValue = (value: string) => {
    const base = DateTime.local().year - 98 - 1900;
    let toPrepend = '';

    if (value.length <= 6) {
        const twoDigitInputYear = parseInt(value.substring(0, 2));
        if (twoDigitInputYear < base) {
            toPrepend += '20';
        } else {
            toPrepend += '19';
        }
    }
    return toPrepend;
};

/*
 * 지역 코드 받아오기
 */
const _getLocationCode = (_cityName: string, _gunguName: string) => {
    const AreaDivision = require('@/asset/AreaDivision.json');
    if (
        Object.keys(AreaDivision)
            .slice(0, -1)
            .findIndex(item => item === _cityName) !== -1
    )
        return AreaDivision[_cityName][_gunguName];
};

const checkValid = (value: string, type: number) => {
    // 1. 입력 형식이 맞는지
    if (!_checkValidDate(value)) return false;
    // 2. 기간이 맞는지
    const range = TotalMedicalUtil.dateRangeByType(type);
    if (!_checkDateRange(value, range.min, range.max)) return false;
    return true;
};

const _getSelectedCity = () => {
    const AreaDivision = require('@/asset/AreaDivision.json');
    if (typeof window !== 'undefined') {
        const location = localStorage.getItem('connection-location');

        if (location && Object.keys(AreaDivision).indexOf(JSON.parse(location).city) !== -1)
            return JSON.parse(location).city;
    }
    return '시/도 선택';
};

const _getSelectedGungu = () => {
    if (typeof window !== 'undefined') {
        const location = localStorage.getItem('connection-location');

        if (location) return JSON.parse(location).gungu;
    }
    return '구/시·군 선택';
};

const roundPremium = (value: number, roundingMode?: string, precision?: number, specialValue?: number) => {
    const additionalSpecialVal = specialValue ?? 0;

    if (roundingMode === undefined || precision === undefined) return value;

    const position = precision;

    switch (roundingMode) {
        case 'floor':
            return Math.floor(value / position) * position + additionalSpecialVal;
        case 'round':
            return Math.round(value / position) * position + additionalSpecialVal;
        case 'ceil':
            return Math.ceil(value / position) * position + additionalSpecialVal;
        default:
            throw new Error(`Invalid mode: ${roundingMode}`);
    }
};

const getInsuredPersonInfoParams = (
    selIns: TInsuranceType,
    insuredPerson: InsuredPerson | MedicalInsuredPerson | InsuredFetus,
) => {
    switch (selIns) {
        case TM:
        case TMSICK: {
            if (UserInfoUtil.isMedicalUser(insuredPerson)) {
                const params = TotalMedicalUtil.getTMInsuredPersonInfo(insuredPerson);
                return params;
            }
            return undefined;
        }
        case TMFETUS: {
            if (UserInfoUtil.isFetus(insuredPerson)) {
                const params = TotalMedicalUtil.getTMInsuredPersonInfo(insuredPerson);
                return params;
            }
            return undefined;
        }
        case IM: {
            if (UserInfoUtil.isMedicalUser(insuredPerson)) {
                const params = IndemnityUtil.getIMInsuredPersonInfo(insuredPerson);
                return params;
            }
            return undefined;
        }
        case IMFETUS: {
            if (UserInfoUtil.isFetus(insuredPerson)) {
                const params = IndemnityUtil.getIMInsuredPersonInfo(insuredPerson);
                return params;
            }
            return undefined;
        }
        case DE: {
            if (UserInfoUtil.isUser(insuredPerson)) {
                const params = DeathUtil.getDEInsuredPersonInfo(insuredPerson);
                return params;
            }
            return undefined;
        }
        case DM: {
            if (UserInfoUtil.isUser(insuredPerson)) {
                const params = DementialUtil.getDMInsuredPersonInfo(insuredPerson);
                return params;
            }
            return undefined;
        }
        case INQUIRY: {
            const {
                birth: insuredPersonBirth,
                sex: insuredPersonSex,
                risk: insuredPersonRisk,
                sick: insuredPersonSick,
            } = insuredPerson as MedicalInsuredPerson;
            const params = { insuredPersonBirth, insuredPersonSick, insuredPersonRisk, insuredPersonSex };
            return params;
        }
        default:
            return undefined;
    }
};

const getNoProductRegisterParams = (
    selIns: TInsuranceType,
    insuranceInput: IDEInsuranceInput | ITMInsuranceInput | IDMInsuranceInput | IACInsuranceInput | ISeniorInput,
) => {
    switch (selIns) {
        case TM:
        case TMFETUS:
        case TMSICK: {
            const majorDiseases = (insuranceInput as ITMInsuranceInput).selectedGP.reduce(
                (acc, cur) => Object.assign({ ...acc }, { [cur.key]: cur.value }),
                {},
            );
            const { priority } = insuranceInput as ITMInsuranceInput;
            return { ...majorDiseases, priority, insuranceType: selIns };
        }
        case DE: {
            const { subscriptionAmount } = insuranceInput as IDEInsuranceInput;
            const params = { subscriptionAmount, insuranceType: selIns };
            return params;
        }
        case DM: {
            const { livingExpense } = insuranceInput as IDMInsuranceInput;
            const params = { livingExpense, insuranceType: selIns };
            return params;
        }
        case INQUIRY:
            return undefined;
        default:
            return undefined;
    }
};

export default {
    _checkValidDate,
    _checkDateRange,
    _birthMin,
    _birthMax,
    _sickBirthMax,
    _calculateInsuranceAge,
    _calculateWesternAge,
    _calculatePrependValue,
    _getLocationCode,
    checkValid,
    _getSelectedCity,
    _getSelectedGungu,
    roundPremium,
    getInsuredPersonInfoParams,
    getNoProductRegisterParams,
};
