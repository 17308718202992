export interface IJwt {
    clientId: number;
    devicdeId: string;
    userType: string;
    isBanned: boolean;
    exp: number;
    iat: number;
}

export const BOTTOM_NAVIGATOR = 57;
export const BOTTOM_FIXED_BUTTON = 58;

export const LIST_ITEM_PAGE = [
    '/ina/[id]',
    '/ina/my/[id]',
    '/plan/insurance-detail',
    '/myPlan/proposal-status',
    '/myPlan/interest-detail',
];

export interface PageData {
    page: number;
    pageSize: number;
    totalCount: number;
}

export type TEasyLogin = 'request' | 'direct' | 'consult' | 'promotion';

export const DEFAULT_REFERRER = process.env.NEXT_PUBLIC_DEFAULT_REFERRER;
