import { AtomEffect, DefaultValue } from 'recoil';

const localStorage = typeof window !== 'undefined' ? window.localStorage : null;
const sessionStorage = typeof window !== 'undefined' ? window.sessionStorage : null;

export const persistAtom = <T>(key: string): AtomEffect<T> => {
    return ({ setSelf, onSet }) => {
        // setSelf: 값을 초기화
        const savedValue = localStorage && localStorage.getItem(key);
        if (savedValue != null) {
            setSelf(JSON.parse(savedValue));
        } else {
            setSelf(new DefaultValue());
        }

        // atom 변경되었을 때
        onSet((newValue: T) => {
            localStorage && localStorage.setItem(key, JSON.stringify(newValue));
        });
    };
};

export const sessionAtom = <T>(key: string): AtomEffect<T> => {
    return ({ setSelf, onSet }) => {
        // setSelf: 값을 초기화
        const savedValue = sessionStorage && sessionStorage.getItem(key);
        if (savedValue != null) {
            setSelf(JSON.parse(savedValue));
        } else {
            setSelf(new DefaultValue());
        }

        // atom 변경되었을 때
        onSet((newValue: T) => {
            sessionStorage && sessionStorage.setItem(key, JSON.stringify(newValue));
        });
    };
};

export interface IAPIData {
    data: any;
    init: boolean;
    caching: boolean;
}
