import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useModal from '@/hooks/useModal';
import { modalState, isLoadingState } from '@/recoil';

const BackgroundControl = () => {
    const router = useRouter();
    const { removeCurrentModal } = useModal();
    const modalList = useRecoilValue(modalState);
    const setIsLoading = useSetRecoilState(isLoadingState);

    useEffect(() => {
        router.beforePopState(() => {
            setIsLoading(false);

            if (modalList.length > 0) {
                if (router.pathname === '/event/[id]') {
                    removeCurrentModal();
                    return true;
                }
                history.pushState('', '', router.pathname);
                removeCurrentModal();

                return false;
            }

            return true;
        });

        if (modalList.length === 1) {
            return () => {
                router.beforePopState(() => true);
            };
        }
    }, [modalList]);

    return <></>;
};

export default BackgroundControl;
