import { TInsuranceType, TProductType } from '.';

export const INPUT_TYPE = {
    BIRTH: 0,
    MATERNITY_BIRTH: 1,
    DUEDATE: 2,
    SICK_BIRTH: 3,
};

export interface IDISEASE_ITEM {
    label: string;
    key: string;
    img: string;
}

export interface IMajorGuarentee {
    label: string;
    value: number;
    key: string;
}

export interface ITMInsuranceInput {
    claim: number;
    selectedGP: IMajorGuarentee[];
    priority: number[];
    selectedPlan: number;
    periods: {
        paymentPeriod: number;
        guaranteePeriods: number[];
        guaranteePeriodUnits: string[];
    };
}

export interface ISeniorInsurance {
    claim: number;
    selectedGP: IMajorGuarentee[];
}

export const TM_MAJOR_DISEASE = [
    {
        label: '암',
        key: 'cancer',
        img: '/img/plan/majorDisease/cancer@3x.png',
    },
    {
        label: '뇌질환',
        key: 'brain',
        img: '/img/plan/majorDisease/brain@3x.png',
    },
    {
        label: '심장질환',
        key: 'heart',
        img: '/img/plan/majorDisease/heart@3x.png',
    },
    {
        label: '간질환',
        key: 'liver',
        img: '/img/plan/majorDisease/liver@3x.png',
    },
    {
        label: '폐질환',
        key: 'lung',
        img: '/img/plan/majorDisease/lung@3x.png',
    },
    {
        label: '신장질환',
        key: 'kidney',
        img: '/img/plan/majorDisease/renal@3x.png',
    },
];

export const DOUGHNUTCOLORS = ['#34A0FF', '#54D196', '#8B949A', '#A0A6A8', '#B8BCC0', '#D1D4D6'];

export const DISEASE_SUBTEXT = [
    '암 진단비(유사암 제외)',
    '뇌졸중 진단비+뇌혈관 진단비',
    '급성심근경색증진단비+허혈성심장질환 진단비',
    '말기간경화 진단비',
    '말기폐질환 진단비',
    '급성신부전증 진단비',
];

export const SELECTED_PIECE = {
    strokeWidth: 80,
    scale: 1.15,
    xLoc: 1.5,
    yLoc: 1.5,
    fontSize: 19,
    opacity: 1,
};

export const NON_SELECTED_PIECE = {
    strokeWidth: 63,
    scale: 1,
    xLoc: 1,
    yLoc: 1,
    fontSize: 16,
    opacity: 0,
};

export const defaultTMInput = {
    claim: 0,
    selectedGP: [],
    priority: [],
    selectedPlan: -1,
    periods: {
        paymentPeriod: 20,
        guaranteePeriods: [20, 100, 100],
        guaranteePeriodUnits: ['Y', 'A', 'A'],
    },
};

export const defaultTMFetusInput = {
    claim: 60000000,
    selectedGP: [],
    priority: [],
    selectedPlan: -1,
    periods: {
        paymentPeriod: 20,
        guaranteePeriods: [100, 100],
        guaranteePeriodUnits: ['A', 'A'],
    },
};

export const defaultTMChildInput = {
    claim: 0,
    selectedGP: [],
    priority: [],
    selectedPlan: -1,
    periods: {
        paymentPeriod: 20,
        guaranteePeriods: [100, 100],
        guaranteePeriodUnits: ['A', 'A'],
    },
};

export const defaultTMSeniorInput = { claim: 100000, priority: [] };

export interface TMListRequestBody {
    cancer: number;
    brain: number;
    heart: number;
    liver?: number;
    lung?: number;
    kidney?: number;
    risk?: number;
    sex?: number;
    insuranceAge?: number;
    westernAge?: number;
    motherInsuranceAge?: number;
    motherWesternAge?: number;
    motherRisk?: number;
    expectedBirthDate?: number;
    sick: boolean;
    fetus: boolean;
    renewal: boolean;
    notCancel: boolean;
    priority: number[];
    paymentPeriod: string;
    guaranteePeriod: string;
}

export interface ISeniorInput {
    claim: number;
    priority: number[];
}

export interface IGuarantee {
    coverageName: string; // 담보 이름
    premium: number; // 기본 월납 보험료
    child?: IGuarantee[]; // 자식 상품
    coverageIdx: number; // 상품 인덱스
    prenatalOnly: boolean; // 태아 상품 여부
    minSA: number; // 추가 보장 최소
    maxSA: number; // 추가 보장 최대
    unitSA: number; // 추가 보장 단위
    subscriptionAmount: number; // 가입자가 보장 받을 금액
    paymentPeriod: number; // 납입기간
    paymentPeriodUnit: string; // 납입기간 단위 코드(년)
    guaranteePeriod: number; // 보장기간
    guaranteePeriodUnit: string; // 보장기간 단위 코드(년)
    unitMP: number; // 월납금액단위
    monthlyPayment: number; // 월납금액
    description?: string;
    companyCode: string; // 회사 코드
    coverageCode: string; // 담보 코드
    sex?: number; // 성별
    risk?: number; // 위험도
}

export interface ITM_Plan {
    insuranceType: TInsuranceType;
    afterBirthSex?: number;
    companyCode: string; // 보험사 코드
    companyName: string; // 보험사이름
    productCode: string; // 상품코드
    productName: string; // 상품이름
    productType: TProductType[]; //상품 분류
    paymentPeriod: number; // 납입기간
    paymentPeriodUnit: number; // 납입기간 단위
    guaranteePeriod: number; // 보장기간
    guaranteePeriodUnit: number; // 보장기간 단위
    maxRenewalPeriod: number; // 최대 갱신기간
    minPremium?: number; // 최소가입금액
    complianceConfirm: string; //준법감시필
    premiumReferenceDate: string; // 보험료 기준일
    informFileName: string; // 가입시 확인할 사항 파일명
    premium: { fetus?: { original: number[]; total: number[] }; normal: { original: number; total: number } }; // 기본 보험료
    complianceConfirmGIA?: string; //준법감시필(손해보험협회 승인)
    additionalGuarantee?: {
        beforeBirth?: { [key: string]: { label: string; premium: number; subscriptionAmount: number } };
        afterBirth?: { [key: string]: { label: string; premium: number; subscriptionAmount: number } };
    }; // 추가보장 데이터
    coverage: {
        original: {
            beforeBirth: {
                main: IGuarantee[];
                necessary: IGuarantee[];
            };
            afterBirth: {
                main: IGuarantee[];
                necessary: IGuarantee[];
            };
        };
        additional: {
            injuryDisease: {
                beforeBirth?: {
                    injury: IGuarantee[];
                    disease: IGuarantee[];
                    injuryDaily: IGuarantee[];
                    diseaseDaily: IGuarantee[];
                };
                afterBirth: {
                    injury: IGuarantee[];
                    disease: IGuarantee[];
                    injuryDaily: IGuarantee[];
                    diseaseDaily: IGuarantee[];
                };
            };
        };
    };
    roundingMode: string;
    precision: number;
    specialValue?: number;
}

export interface ITM_Additional {
    coverageName: string;
    subscriptionAmount: number;
    premium: number;
}

export interface IAdditionalInput {
    label: string;
    premium: number;
    subscriptionAmount: number;
}

export const defaultAdditionalBeforeBirth: {
    [key: string]: IAdditionalInput;
} = {
    injury: { label: '상해 수술비', premium: 0, subscriptionAmount: 0 },
    disease: { label: '질병 수술비', premium: 0, subscriptionAmount: 0 },
    injuryDaily: { label: '상해 입원일당', premium: 0, subscriptionAmount: 0 },
    diseaseDaily: { label: '질병 입원일당', premium: 0, subscriptionAmount: 0 },
};

export const defaultAdditionalAfterBirth: {
    [key: string]: IAdditionalInput;
} = {
    injury: { label: '상해 수술비', premium: 0, subscriptionAmount: 0 },
    disease: { label: '질병 수술비', premium: 0, subscriptionAmount: 0 },
    injuryDaily: { label: '상해 입원일당', premium: 0, subscriptionAmount: 0 },
    diseaseDaily: { label: '질병 입원일당', premium: 0, subscriptionAmount: 0 },
};

export type TToggle = 'injury' | 'disease';
export type TRange = 'injuryDaily' | 'diseaseDaily';
export type TAdditional = 'injury' | 'disease' | 'injuryDaily' | 'diseaseDaily';

export interface ITM_Senior {
    insuranceType: TInsuranceType;
    premium: number;
    selectedGuarantee: { [key: string]: number };
}
