import { useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { isAppState } from '@/recoil';

const DEFAULT_MARGIN_TOP = 112;

const MovingFocusInput = (props: any) => {
    const isApp = useRecoilValue(isAppState);
    const inputRef = useRef<any>(props.ref);

    useEffect(() => {
        if (isApp && inputRef && inputRef.current) {
            inputRef.current.addEventListener('focus', () => {
                if (props.isMoving) {
                    const offset = inputRef.current?.getBoundingClientRect();

                    setTimeout(() => {
                        const container_page = document.getElementById('top_main_layout');
                        const container_modal = document.getElementsByClassName('movingFocus');

                        container_page?.scrollTo({
                            top: inputRef.current.offsetTop - DEFAULT_MARGIN_TOP,
                            behavior: 'smooth',
                        });

                        if (container_modal[0]?.scrollTop < offset.top - DEFAULT_MARGIN_TOP) {
                            container_modal[0]?.scrollTo({
                                top: offset.top - DEFAULT_MARGIN_TOP,
                                behavior: 'smooth',
                            });
                        }
                    }, 200);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (isApp && inputRef && inputRef.current) {
            if (props.isKeyboardHide) inputRef.current.blur();
        }
    }, [props.isKeyboardHide]);

    if (props.tag === 'textarea')
        return (
            <textarea
                {...props}
                className={props.className}
                ref={inputRef}
                onFocus={() => {
                    if (props.onFocus) props.onFocus();
                }}
                onBlur={() => {
                    if (props.onBlur) props.onBlur();
                }}
            />
        );
    return (
        <input
            {...props}
            className={props.className}
            ref={inputRef}
            onFocus={() => {
                if (props.onFocus) props.onFocus();
            }}
            onBlur={() => {
                if (props.onFocus) props.onBlur();
            }}
        />
    );
};

export default MovingFocusInput;
