/* 1: 남 / 2: 여 */
export const CSex = {
    MALE: 1,
    FEMALE: 2,
};

export interface InsuredPerson {
    birth: string;
    sex: number;
}

export interface MedicalInsuredPerson extends InsuredPerson {
    risk: number;
    sick: number;
}

export interface InsuredFetus {
    birth: string;
    expectedBirthDate: string;
    risk: number;
}

// TODO default를 이걸 기준으로 잡아야 하나 고민
export const inquiryUser = {
    birth: '',
    sex: 1,
    risk: 1,
    sick: 2,
};

export const defaultUser = {
    birth: '',
    sex: 1,
};

export const defaultTMNormal = {
    birth: '',
    sex: 1,
    risk: 1,
    sick: 2,
};

export const defaultTMSick = {
    birth: '',
    sex: 1,
    risk: 1,
    sick: 1,
};

export const defaultTMFetus = {
    birth: '',
    expectedBirthDate: '',
    risk: 1,
};

export const defaultIMNormal = {
    birth: '',
    sex: 1,
    risk: 1,
    sick: 2,
};

export const defaultIMFetus = {
    birth: '',
    expectedBirthDate: '',
    risk: 1,
};
