import { atom } from 'recoil';

export const sendbirdConnectionState = atom<boolean | undefined>({
    key: 'sendbirdConnectionState',
    default: undefined,
});

export const scrollPosState = atom<{ isPosTop: boolean; loadMsgs: boolean }>({
    key: 'scrollPosState',
    default: {
        isPosTop: false,
        loadMsgs: false,
    },
});

export const unReadChattingCntState = atom<number>({
    key: 'unReadChattingCntState',
    default: 0,
});

export const eventBoxClickState = atom<boolean>({
    key: 'eventBoxClickState',
    default: false,
});

export const fileBoxClickState = atom<boolean>({
    key: 'fileBoxClickState',
    default: false,
});

export const recentMsgState = atom<{ msgId: number; msgType: string }>({
    key: 'recentMsgState',
    default: { msgId: 0, msgType: '' },
});

export const roomAlarmState = atom<boolean>({
    key: 'roomAlarmState',
    default: false,
});

export const plannerMuteState = atom<boolean>({
    key: 'plannerMuteState',
    default: false,
});

export const cameraPermissionState = atom<boolean>({
    key: 'cameraPermissionState',
    default: false,
});
