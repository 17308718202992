import React, { useEffect, useState } from 'react';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import TitleNAdditionalContents from '@/components/ContextPrinter/TitleNAdditionalContents';
import BottomFixedButton from '@/components/Button/BottomFixedButton';
import NameNPassword from '@/containers/login/nameNPassword/NameNPassword';
import DesignatedPlanner from '@/containers/login/nameNPassword/DesignatedPlanner';
import SelectProfile from '@/containers/login/nameNPassword/SelectProfile';
import CompleteRegist from '@/containers/modal/login/CompleteRegist';
import { useSocket } from '@/hooks/useSocket';
import useQueryHook from '@/hooks/useQueryHook';
import useMutationHook from '@/hooks/useMutationHook';
import useModal from '@/hooks/useModal';
import useLocalStorage from '@/hooks/useLocalStorage';
import {
    loginState,
    dummyUserState,
    profileState,
    groupProposalState,
    isAppState,
    promotionPlannerState,
} from '@/recoil';
import { typedUserInfoState } from '@/recoil/login';
import { defaultDummyState } from '@/models';
import { API, URLs } from '@/api';
import { LocalStorage } from '@/utils/LocalStorage';
import { FontStyle } from '@/utils/StyleUtil';
import ErrorPage from '@/containers/chat/error';

const TypeEssentialInfo = ({ name }: { name?: string }) => {
    const { addModal, removeCurrentModal } = useModal();
    const { connectSocket, disconnectSocket } = useSocket();
    const { getItem } = useLocalStorage('interest-plan');

    const promotionPlanner = useRecoilValue(promotionPlannerState);
    const setLoginStatus = useSetRecoilState(loginState);
    const [dummyUser, setDummyUser] = useRecoilState(dummyUserState);
    const [userInfo, setUserInfo] = useRecoilState(typedUserInfoState);
    const [profile, setProfile] = useRecoilState(profileState);
    const setIsGetGroupProposal = useSetRecoilState(groupProposalState);
    const [isActive, setIsActive] = useState(false);
    const isApp = useRecoilValue(isAppState);

    const { mutate: postUserInfo, errorPopUp } = useMutationHook(
        ['post-user-info'],
        async ({ plannerCode }) => {
            if (plannerCode) {
                return API.post(URLs.clientInfo.signUp(dummyUser.platform), {
                    //platform: dummyUser.platform,
                    oAuthResult: dummyUser.oAuthIdToken,
                    name: userInfo.name,
                    //files: [profile.profileImage],
                    recommendedPlannerCode: plannerCode,
                    //platformId: dummyUser.platformId,
                    //email: dummyUser.email,
                    //isApp,
                });
            } else {
                return API.post(URLs.clientInfo.signUp(dummyUser.platform), {
                    //platform: dummyUser.platform,
                    oAuthResult: dummyUser.oAuthIdToken,
                    name: userInfo.name,
                    //files: [profile.profileImage],
                    recommendedPlannerCode: null,
                    //platformId: dummyUser.platformId,
                    //email: dummyUser.email,
                    //isApp,
                });
            }
        },
        {
            onSuccess: async (userRes, { plannerCode }) => {
                if (window.ReactNativeWebView)
                    window.ReactNativeWebView.postMessage(JSON.stringify({ clearAllPushNoti: true }));

                disconnectSocket();
                connectSocket(userRes.tokenSet.accessToken, userRes.tokenSet.refreshToken);

                if (profile.profileImage)
                    await API.put(URLs.clientInfo.profileImage, {
                        uploads: [profile.profileImage],
                    });

                setProfile({
                    needUpdate: true,
                    profileURL: '/img/profile_image/nonMember@3x.png',
                    profileImage: undefined,
                });

                setLoginStatus({
                    userType: 'client',
                    clientId: dummyUser.clientId,
                    name: userInfo.name,
                    email: userRes.email,
                    designatedPlannerName: userRes.recommendedPlannerName,
                    designatedPlannerId: -1,
                });
                if (plannerCode) setIsGetGroupProposal(false);
                setDummyUser(defaultDummyState);

                const interestPlans = getItem();
                if (interestPlans) {
                    const insuranceArr = interestPlans.map((v: any) => {
                        const interestPlan = { ...v };
                        delete interestPlan['id'];
                        return interestPlan;
                    });

                    await API.post(URLs.myPlan.interestBulk, { insuranceArr }).then(() => {
                        LocalStorage.removeItem('interest-plan');
                    });
                }

                addModal({
                    key: 'fullScreen',
                    props: {
                        content: <CompleteRegist />,
                        handleCloseModal: removeCurrentModal,
                        hasHeader: false,
                    },
                });
            },
            onError: err => {
                console.log('error check : ', err, err?.response?.status);
                //errorPopUp(err?.response?.status);
                addModal({
                    key: 'fullScreen',
                    props: {
                        content: <ErrorPage isList={false} />,
                        handleCloseModal: removeCurrentModal,
                        hasHeader: false,
                    },
                });
            },
        },
    );

    const { refetch: checkPlanner, RenderError } = useQueryHook(
        ['planner-info'],
        async () => {
            return await API.get(URLs.plannerInfo.plannerInfo(Number(userInfo.code)));
        },
        {
            onSuccess: plannerInfo => {
                if (plannerInfo.name)
                    addModal({
                        key: 'popUp',
                        props: {
                            type: 'noYes',
                            content: (
                                <>
                                    <PlannerName>{plannerInfo.name}</PlannerName> 플래너가 맞나요?
                                </>
                            ),
                            handleCloseModal: removeCurrentModal,
                            noText: '아니오',
                            onClickNo: () => {
                                setUserInfo({ ...userInfo, code: '' });
                                removeCurrentModal();
                            },
                            yesText: '예',
                            onClickYes: () => {
                                removeCurrentModal();
                                postUserInfo({ plannerCode: userInfo.code });
                            },
                        },
                    });
                else
                    addModal({
                        key: 'popUp',
                        props: {
                            type: 'confirm',
                            content: '입력하신 코드를 다시 확인해 주세요',
                            handleCloseModal: removeCurrentModal,
                            onClickConfirm: removeCurrentModal,
                        },
                    });
            },
            onError: () => {
                addModal({
                    key: 'popUp',
                    props: {
                        type: 'confirm',
                        content: <>{RenderError}</>,
                        handleCloseModal: removeCurrentModal,
                        onClickConfirm: removeCurrentModal,
                    },
                });
            },
            enabled: false,
        },
    );

    const onClickEvent = async () => {
        if (isActive) {
            if (userInfo.code !== userInfo.designatedCode) {
                addModal({
                    key: 'popUp',
                    props: {
                        type: 'noYes',
                        content: '지정 플래너 등록을 하지 않았습니다.\n등록하시겠습니까?',
                        noText: '아니오',
                        onClickNo: () => {
                            removeCurrentModal();
                            postUserInfo({ plannerCode: null });
                        },
                        yesText: '예',
                        onClickYes: () => {
                            removeCurrentModal();
                            checkPlanner({});
                        },
                    },
                });
            } else postUserInfo({ plannerCode: userInfo.code });
        }
    };

    useEffect(() => {
        if (promotionPlanner) {
            setUserInfo(prev => ({
                ...prev,
                code: promotionPlanner.companyNumber,
                designatedCode: promotionPlanner.companyNumber,
            }));
        }
    }, [promotionPlanner, setUserInfo]);

    return (
        <div>
            <Container>
                <TitleNAdditionalContents
                    title={'회원가입에\n필요한 정보를 입력해주세요'}
                    subText="픽앤플랜은 최소한의 정보만 수집합니다"
                />
                <NameNPassword setActive={(bool: boolean) => setIsActive(bool)} name={name} />
                {!promotionPlanner && <DesignatedPlanner />}
                <SelectProfile />
            </Container>
            <BottomFixedButton
                text="가입 완료"
                buttonColor={isActive ? '#0ec4cf' : '#acacac'}
                onClickButton={onClickEvent}
            />
        </div>
    );
};

const Container = styled.div`
    padding: 26px 20px 112px 20px;
`;

const PlannerName = styled.div`
    ${FontStyle(14, 22, '500', '#444444', -1)};
`;

export default TypeEssentialInfo;
