import { atom, selector } from 'recoil';

import { insuranceTypeState, SelectedIns } from '@/recoil/plan';
import { TMPlanState } from '@/recoil/plan/totalMedical';
import { DEPlanState } from '@/recoil/plan/death';
import {
    DM,
    AC,
    DE,
    IAC_Plan,
    TM,
    TMFETUS,
    TMSENIOR,
    TMSICK,
    ITM_Plan,
    ITM_Senior,
    IDM_Plan,
    IM,
    IMFETUS,
} from '@/models/plan';
import { IDE_Plan } from '@/models/plan';
import {
    IPlannerDetailInfo,
    IInsuredPersonInfo,
    ITM_Direct,
    IDE_Direct,
    IDM_Direct,
    IInsuredFetusInfo,
} from '@/models/myPlan';
import MyPlanUtil from '@/utils/myPlan/MyPlanUtil';
import { IIM_Plan } from '@/models/plan/indemnity';
import { IMPlanState } from '../plan/indemnity';
import { DMPlanState } from '../plan/dementia';

export const plannerInformation = atom<IPlannerDetailInfo | undefined>({
    key: 'plannerInformation',
    default: undefined,
});

export const designRequestIdState = atom<number>({
    key: 'designRequestIdState',
    default: 0,
});

export const additionalUserTextState = atom<string>({
    key: 'additionalUserTextState',
    default: '',
});

export const userInfoState = atom<IInsuredPersonInfo | IInsuredFetusInfo | undefined>({
    key: 'userInfoState',
    default: undefined,
});

/*
 * Plan 관련 recoil(현재 통합의료비만 적용, type guard 적용예정)
 */

export const tm = atom<ITM_Plan | undefined>({
    key: 'tm',
    default: undefined,
});

export const senior = atom<ITM_Senior | undefined>({
    key: 'senior',
    default: undefined,
});

export const accident = atom<IAC_Plan | undefined>({
    key: 'accident',
    default: undefined,
});

export const death = atom<IDE_Plan | undefined>({
    key: 'death',
    default: undefined,
});

export const dementia = atom<IDM_Plan | undefined>({
    key: 'dementia',
    default: undefined,
});

export const indemnity = atom<IIM_Plan | undefined>({
    key: 'indemnity',
    default: undefined,
});

export const selectedPlanState = selector<
    ITM_Plan | ITM_Senior | IAC_Plan | IDE_Plan | IDM_Plan | IIM_Plan | undefined
>({
    key: 'selectedPlanState',
    get: ({ get }) => {
        const insuranceType = get(insuranceTypeState);
        switch (insuranceType) {
            case TM:
            case TMFETUS:
            case TMSICK:
                return get(tm);
            case TMSENIOR:
                return get(senior);
            case DE:
                return get(death);
            case AC:
                return get(accident);
            case DM:
                return get(dementia);
            case IM:
            case IMFETUS:
                return get(indemnity);
            default:
                return undefined;
        }
    },
    set: ({ get, set }, newValue) => {
        const insuranceType = get(insuranceTypeState);
        switch (insuranceType) {
            case TM:
            case TMFETUS:
            case TMSICK:
                if (MyPlanUtil.isTMPlan(newValue)) {
                    set(tm, newValue);
                }
                break;
            case TMSENIOR:
                if (MyPlanUtil.isSenior(newValue)) {
                    set(senior, newValue);
                }
                break;
            case DE:
                if (MyPlanUtil.isDEPlan(newValue)) {
                    set(death, newValue);
                }
                break;
            case AC:
                if (MyPlanUtil.isACPlan(newValue)) {
                    set(accident, newValue);
                }
                break;
            case DM:
                if (MyPlanUtil.isDMPlan(newValue)) {
                    set(dementia, newValue);
                }
                break;
            case IM:
            case IMFETUS:
                if (MyPlanUtil.isIMPlan(newValue)) {
                    set(indemnity, newValue);
                }
                break;
            default:
                break;
        }
    },
});

export const tmDirect = atom<ITM_Direct | undefined>({
    key: 'tmDirect',
    default: undefined,
});

export const deathDirect = atom<IDE_Direct | undefined>({
    key: 'deathDirect',
    default: undefined,
});

export const dementiaDirect = atom<IDM_Direct | undefined>({
    key: 'dementiaDirect',
    default: undefined,
});

export const noProductPlanState = selector<ITM_Direct | IDE_Direct | IDM_Direct | undefined>({
    key: 'noProductPlanState',
    get: ({ get }) => {
        const insuranceType = get(insuranceTypeState);
        switch (insuranceType) {
            case TM:
            case TMFETUS:
            case TMSICK:
                return get(tmDirect);
            case DE:
                return get(deathDirect);
            case DM:
                return get(dementiaDirect);
            default:
                return undefined;
        }
    },
    set: ({ get, set }, newValue) => {
        const insuranceType = get(insuranceTypeState);
        switch (insuranceType) {
            case TM:
            case TMFETUS:
            case TMSICK:
                if (MyPlanUtil.isTMPlan(newValue)) set(tmDirect, newValue);
                break;
            case DE:
                if (MyPlanUtil.isDEPlan(newValue)) set(deathDirect, newValue);
                break;
            case DM:
                if (MyPlanUtil.isDMPlan(newValue)) set(dementiaDirect, newValue);
                break;
            default:
                break;
        }
    },
});

// TODO selectorFamily로 수정
export const interestPlanState = selector<ITM_Plan | IDE_Plan | IIM_Plan | IDM_Plan | undefined>({
    key: 'interestPlanState',
    get: ({ get }) => {
        const insuranceType = get(SelectedIns);
        switch (insuranceType) {
            case TM:
            case TMFETUS:
            case TMSICK:
                return get(TMPlanState);
            case DE:
                return get(DEPlanState);
            case IM:
            case IMFETUS:
                return get(IMPlanState);
            case DM:
                return get(DMPlanState);
            default:
                return undefined;
        }
    },
    set: ({ get, set }, newValue) => {
        const insuranceType = get(SelectedIns);
        switch (insuranceType) {
            case TM:
            case TMFETUS:
            case TMSICK:
                if (MyPlanUtil.isTMPlan(newValue)) {
                    set(TMPlanState, newValue);
                } else {
                    set(TMPlanState, undefined);
                }
                break;
            case DE:
                if (MyPlanUtil.isDEPlan(newValue)) {
                    set(DEPlanState, newValue);
                } else {
                    set(DEPlanState, undefined);
                }
                break;
            case IM:
            case IMFETUS:
                if (MyPlanUtil.isIMPlan(newValue)) {
                    set(IMPlanState, newValue);
                } else {
                    set(IMPlanState, undefined);
                }
                break;
            case DM:
                if (MyPlanUtil.isDMPlan(newValue)) {
                    set(DMPlanState, newValue);
                } else {
                    set(DMPlanState, undefined);
                }
                break;
            default:
                break;
        }
    },
});

export const interestIdState = atom<number>({
    key: 'interestIdState',
    default: 0,
});

export const interestUserInfoState = atom<IInsuredPersonInfo | undefined>({
    key: 'interestUserInfoState',
    default: undefined,
});

export const matchingTypeState = atom<number | undefined>({
    key: 'matchingTypeState',
    default: undefined,
});
