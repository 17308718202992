import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import RegistGuest from '@/queries/common/registGuest';
import RefreshTokens from '@/queries/common/refreshTokens';
import { uuidState, loginState } from '@/recoil';
import { IJwt } from '@/models';
import jwt_decode from 'jwt-decode';
import DeleteGuest from '@/queries/common/deleteGuest';
import CheckGuest from '@/queries/common/checkGuest';

const TokenProvider = ({ children }: { children: any }) => {
    const [uuid, setUuid] = useRecoilState(uuidState);
    const loginStatus = useRecoilValue(loginState);
    const { registGuest } = RegistGuest();
    const { refreshTokens } = RefreshTokens();
    const [isInitToken, setIsInitToken] = useState(false);
    const { deleteGuest } = DeleteGuest();
    const { checkGuest, isLoading } = CheckGuest();

    const onSuccess = () => {
        setIsInitToken(true);
    };
    // 최초 접속 시 비회원 id 부여 / 비회원, 회원 accessToken refresh
    useEffect(() => {
        if (!isLoading) {
            setUuid(uuid);
            const refreshToken: string | null = localStorage.getItem('refreshToken');

            if (loginStatus.clientId === 0 || refreshToken === null) {
                if (checkGuest) {
                    deleteGuest({}, { onSuccess: onSuccess });
                }

                registGuest(
                    {},
                    {
                        onSuccess: onSuccess,
                    },
                );
                // If refresh token is not expired
            } else {
                const decoded: IJwt = jwt_decode(refreshToken);
                // Change to miliseconds
                const expireTime: number = decoded.exp * 1000;

                // Case for when refresh token is not yet expired
                if (expireTime > new Date().getTime()) {
                    refreshTokens(
                        {},
                        {
                            onSuccess: onSuccess,
                        },
                    );
                }
                // When refresh token is expired
                else {
                    // console.log('tokenProvider time expired');
                    if (checkGuest) {
                        deleteGuest({}, { onSuccess: onSuccess });
                        registGuest(
                            {},
                            {
                                onSuccess: onSuccess,
                            },
                        );
                    } else {
                        registGuest(
                            {},
                            {
                                onSuccess: onSuccess,
                            },
                        );
                    }
                }
            }
        }
    }, [isLoading]);

    return <>{isInitToken ? children : undefined}</>;
};

export default TokenProvider;
