import { useCallback } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import { loginState, isLoginState, profileState } from '@/recoil';
import { sendbirdConnectionState, unReadChattingCntState } from '@/recoil/chat';
import { pushTokenState } from '@/recoil/notification';
import { sendbird } from '@/utils/Sendbird';

const useSendbirdConnect = () => {
    const loginStatus = useRecoilValue(loginState);
    const isLogin = useRecoilValue(isLoginState);
    const setIsSendbirdConnected = useSetRecoilState(sendbirdConnectionState);
    const [profile, setProfile] = useRecoilState(profileState);
    const setUnReadChattingCnt = useSetRecoilState(unReadChattingCntState);
    const pushToken = useRecoilValue(pushTokenState);

    const getUnreadMsgCnt = useCallback(async () => {
        const totalCount = await sendbird.instance.getTotalUnreadMessageCount();

        setUnReadChattingCnt(totalCount);

        if (window.ReactNativeWebView)
            window.ReactNativeWebView.postMessage(JSON.stringify({ unReadChatCnt: totalCount }));
    }, [setUnReadChattingCnt]);

    const connect = useCallback(() => {
        if (typeof window !== 'undefined' && window.ReactNativeWebView)
            window.ReactNativeWebView.postMessage(JSON.stringify({ deleteFCMToken: true }));
        sendbird.instance.removeUserEventHandler('update_message_count');

        sendbird
            .disconnect(pushToken)
            .then(() => {
                setIsSendbirdConnected(undefined);

                if (isLogin) {
                    const sbId = String(loginStatus.clientId);
                    sendbird.connect(sbId, (user, error) => {
                        if (error) {
                            // TODO reconnect
                            setIsSendbirdConnected(false);
                        } else {
                            // console.log('sendbird connect : ', user, profile);
                            if (profile.needUpdate) {
                                sendbird.updateUserInfo(loginStatus.name, profile.profileImage);
                                setProfile({ ...profile, needUpdate: false });
                            }

                            getUnreadMsgCnt();

                            if (!Object.prototype.hasOwnProperty.call(user.metaData, 'userStatus'))
                                sendbird.createUserMetaData();

                            const eventHandler = new sendbird.instance.UserEventHandler();
                            eventHandler.onTotalUnreadMessageCountUpdated = (totalCount: number) => {
                                setUnReadChattingCnt(totalCount);

                                if (window.ReactNativeWebView)
                                    window.ReactNativeWebView.postMessage(
                                        JSON.stringify({ unReadChatCnt: totalCount }),
                                    );
                            };

                            sendbird.instance.addUserEventHandler('update_message_count', eventHandler);
                            setIsSendbirdConnected(true);
                        }
                    });
                }

                if (typeof window !== 'undefined' && window.ReactNativeWebView)
                    window.ReactNativeWebView.postMessage(JSON.stringify({ requestFCMToken: true }));
                return true;
            })
            .catch(err => {
                console.log('sendbird disconnection error : ', err);
                return false;
            });
    }, [
        getUnreadMsgCnt,
        isLogin,
        loginStatus.clientId,
        loginStatus.name,
        profile,
        pushToken,
        setIsSendbirdConnected,
        setProfile,
        setUnReadChattingCnt,
    ]);

    return { connect };
};

export default useSendbirdConnect;
