import { atom, selector } from 'recoil';
import { v4 } from 'uuid';

import { ILoginState, noLoginState } from '@/models';
import { persistAtom } from './_persistAtom';

interface IDummy extends ILoginState {
    platform: string;
    oAuthIdToken: string;
    platformId?: string;
}

export const loginState = atom<ILoginState>({
    key: 'loginState',
    default: noLoginState,
    effects_UNSTABLE: [persistAtom('persist-login-state')],
});

export const dummyUserState = atom<IDummy>({
    key: 'dummyUserState',
    default: {
        ...noLoginState,
        platform: '',
        oAuthIdToken: '',
    },
});

export const uuidState = atom<string>({
    key: 'uuidState',
    default: v4().toString(),
    effects_UNSTABLE: [persistAtom('persist-uuid')],
});

export const isLoginState = selector<boolean>({
    key: 'isLoginState',
    get: ({ get }) => {
        if (get(loginState).userType === 'client') {
            return true;
        }
        return false;
    },
});

export const profileState = atom<{ needUpdate: boolean; profileURL: string; profileImage?: File }>({
    key: 'profileState',
    default: {
        needUpdate: false,
        profileURL: '/img/profile_image/nonMember@3x.png',
        profileImage: undefined,
    },
});

export const groupProposalState = atom<boolean>({
    key: 'groupProposalState',
    default: false,
    effects_UNSTABLE: [persistAtom('group-proposal')],
});
