import { ReactElement, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { isLoadingState } from '@/recoil';
import ErrorPartial from '@/containers/common/error/Partial';
import useModal from './useModal';
import { FontStyle } from '@/utils/StyleUtil';

export default function useQueryHook(
    key: (string | number)[],
    fetch: QueryFunction,
    options?: UseQueryOptions<any, AxiosError, any, (string | number)[]>, // TODO 타입 다시 확인
    inaLoading?: boolean,
) {
    const {
        data,
        isFetching,
        isLoading,
        isError,
        isSuccess: _isSuccess,
        refetch,
        ...result
    } = useQuery(key, fetch, {
        onSettled: () => {
            if (!inaLoading) setLoading(false);
        },
        ...options,
    });
    const { addModal, removeCurrentModal } = useModal();
    const [RenderError, setRenderError] = useState<ReactElement | undefined>(undefined);
    const [isSuccess, setIsSuccess] = useState(false);
    const setLoading = useSetRecoilState(isLoadingState);

    const errorPopUp = (errorStatus?: number) => {
        let errorContent: ReactElement;

        if (errorStatus === 400 || errorStatus === 404) {
            errorContent = <ErrorPartial type="badRequest" />;
        } else {
            errorContent = <ErrorPartial type="communication" />;
        }

        addModal({
            key: 'popUp',
            props: {
                type: 'confirm',
                content: <div>{errorContent}</div>,
                contentStyle: FontStyle(14, 22, '400', '#444444', -1),
                onClickConfirm: () => {
                    removeCurrentModal();
                },
            },
        });
    };

    useEffect(() => {
        if (isFetching && isLoading) setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        // api 통신 성공한 경우
        if (_isSuccess) {
            // data가 null이 온 경우 (서버에서 null은 무조건 에러인 것을 보장함)
            if (data === null) {
                setRenderError(<ErrorPartial type="access" />);
                setIsSuccess(false);
                return;
            }
            setIsSuccess(true);
        } else {
            // api 통신 실패한 경우
            if (isError) {
                setRenderError(<ErrorPartial type="communication" />);
                setIsSuccess(false);
                return;
            }
        }
        // renderError 초기화
        setRenderError(undefined);
    }, [data, isError, _isSuccess]);

    return { data, isLoading, isSuccess, RenderError, refetch, result, isError, errorPopUp };
}
