import { DefaultValue } from 'recoil';

import {
    DE,
    DM,
    IDE_Plan,
    IDM_Plan,
    IIM_Plan,
    IM,
    IMFETUS,
    ITM_Plan,
    TM,
    TMFETUS,
    TMSICK,
    TProduct,
} from '@/models/plan';

const isTMPlan = (plan: TProduct | undefined | DefaultValue): plan is ITM_Plan => {
    if (plan instanceof DefaultValue) return false;
    return plan?.insuranceType === TM || plan?.insuranceType === TMFETUS || plan?.insuranceType === TMSICK;
};

const isIMPlan = (plan: TProduct | undefined | DefaultValue): plan is IIM_Plan => {
    if (plan instanceof DefaultValue) return false;
    return plan?.insuranceType === IM || plan?.insuranceType === IMFETUS;
};

const isDEPlan = (plan: TProduct | undefined | DefaultValue): plan is IDE_Plan => {
    if (plan instanceof DefaultValue) return false;
    return plan?.insuranceType === DE;
};

const isDMPlan = (plan: TProduct | undefined | DefaultValue): plan is IDM_Plan => {
    if (plan instanceof DefaultValue) return false;
    return plan?.insuranceType === DM;
};

export default { isTMPlan, isIMPlan, isDEPlan, isDMPlan };
