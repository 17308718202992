export const WELCOME = 0; // 회원가입
export const NOTICE = 1; // 공지사항
export const EVENT = 2; // 이벤트
export const COMMUNITY_ANSWER = 200; // 보험n답 답변
export const COMMUNITY_ANSWER_EXPIRED = 201; // 보험n답 무응답 답변 완료
export const MYPLAN_PROPOSAL = 400; // 제안서 받음
export const MYPLAN_PROPOSAL_CHECK = 402; // 제안서 3일간 미확인
export const INQUIRY_ANSWER = 500; // 1:1 문의, 앱 이용 관련 문의 답변
export const ONE_TO_ONE_MATCHING = 703; // 1:1 매칭
export const PLANNER_THREAD = 1001; //플래너 스레드 답글

export interface IAlarmItem {
    // id: number;
    type: number;
    clientId: number | null;
    message: string;
    payload: any;
    tier: number | null;
    // receivedAt 새로 추가
    receivedAt: string;
    // isRead 새로 추가
    isRead: boolean;
}

export interface IAlarmMeta {
    unreadCount: number;
}

export interface IAllAlarm {
    data: IAlarmItem[];
    metadata: IAlarmMeta;
}

export interface IInAPayload {
    questionId: string | number;
}

export interface IProposalPayload {
    designRequestId: number;
    plannerId: number;
    proposalId: string | number;
}

export interface IMyPageAnswerPayload {
    inquiryId: number;
}

export interface IOneToOneMatchingPayload {
    designRequestId: number;
}

export interface IPlannerThreadPayload {
    threadId: number;
    plannerId: number;
    threadCommentId: number;
}
