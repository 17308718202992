import { defaultDEInsuranceInput, IDEInsuranceInput, IDE_Plan } from '@/models/plan';
import { defaultUser, InsuredPerson } from '@/models/userInput';
import { atom } from 'recoil';

export const DEUserInfoState = atom<InsuredPerson>({
    key: 'DEUserInfoState',
    default: defaultUser,
});

export const DEInsuranceInput = atom<IDEInsuranceInput>({
    key: 'DEInsuranceInput',
    default: defaultDEInsuranceInput,
});

export const DEListTabState = atom<number>({
    key: 'DEListTabState',
    default: 0,
});

export const DECalculatedAmount = atom<number>({
    key: 'DECalculatedAmount',
    default: 0,
});

export const DEPlanState = atom<IDE_Plan | undefined>({
    key: 'DEPlanState',
    default: undefined,
});
