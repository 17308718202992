import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import InputForm from '@/containers/login/inputForm/InputForm';
import { typedErrorState, typedUserInfoState } from '@/recoil/login';

interface IProps {
    setActive: Function;
    name?: string;
}

const NameNPassword = ({ setActive, name }: IProps) => {
    const [userInfo, setUserInfo] = useRecoilState(typedUserInfoState);
    const [typedError, setTypedError] = useRecoilState(typedErrorState);

    useEffect(() => {
        if (userInfo.name && !typedError.isNameError) setActive(true);
        else setActive(false);
    }, [userInfo]);

    return (
        <>
            <InputForm
                inputType="name"
                givenInput={name}
                typedEmail={(typed: string) => setUserInfo({ ...userInfo, name: typed })}
                isError={(isError: boolean) => {
                    if (isError) setTypedError({ isNameError: true });
                    else setTypedError({ isNameError: false });
                }}
                style={{ marginTop: '40px' }}
            />
        </>
    );
};

export default NameNPassword;
