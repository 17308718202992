import React, { CSSProperties } from 'react';
import Image from 'next/image';
import styled from 'styled-components';

import SvgSelector, { TIcons } from '@/components/Svg/SvgSelector';
import { FontStyle } from '@/utils/StyleUtil';

interface IProps {
    className?: string;
    containerStyle?: CSSProperties;
    content: string | React.ReactElement;
    fontStyle?: CSSProperties;
}

interface IImage extends IProps {
    image: string;
    imageWidth: number | string;
    imageHeight: number | string;
    alt?: string;
}

interface ISvg extends IProps {
    svg: TIcons;
    width?: string | number;
    height?: string | number;
    stroke?: string;
    strokeWidth?: number;
    fill?: string;
    svgStyle?: CSSProperties;
}

const VerticalImageNContent = (props: IImage | ISvg) => {
    const isImage = (props: IImage | ISvg): props is IImage => {
        if ('image' in props) {
            return true;
        }
        return false;
    };

    return (
        <Container className={props.className} style={props.containerStyle}>
            {isImage(props) ? (
                <Image
                    src={props.image}
                    alt={typeof props.content === 'string' ? props.content : props.alt}
                    width={props.imageWidth}
                    height={props.imageHeight}
                />
            ) : (
                <SvgSelector
                    svg={props.svg}
                    width={props.width}
                    height={props.height}
                    stroke={props.stroke}
                    strokeWidth={props.strokeWidth}
                    fill={props.fill}
                    style={props.svgStyle}
                />
            )}
            <MarginDiv />
            <Content style={props.fontStyle}>{props.content}</Content>
        </Container>
    );
};

const Container = styled.div`
    ${({ theme }) => theme.flex.flexColumn}
    ${({ theme }) => theme.flex.justifyCenter}
`;

const MarginDiv = styled.div`
    margin-top: 8px;
`;

const Content = styled.div`
    text-align: center;
    white-space: pre-wrap;
    ${FontStyle(14, 22, '400', '#888888', -1)};
`;

export default VerticalImageNContent;
