import useQueryHook from '@/hooks/useQueryHook';

import { uuidState } from '@/recoil';
import { useRecoilValue } from 'recoil';

import { API, URLs } from '@/api';

const CheckGuest = () => {
    const uuid = useRecoilValue(uuidState);

    const {
        data: checkGuest,
        errorPopUp,
        isLoading,
    } = useQueryHook(
        ['check-guest'],
        async () => {
            return await API.get(URLs.auth.checkFaker, { deviceId: uuid });
        },
        {
            onError: err => errorPopUp(err?.response?.status),
        },
    );

    return { checkGuest, isLoading };
};

export default CheckGuest;
