import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface IProps {
    className?: string;
    direction?: 'vertical' | 'horizontal';
    thickness: number;
    length?: number | string;
    color: string;
    margin?: string;
    padding?: string;
    style?: CSSProperties;
}

export default function DividerDefault({
    className,
    direction = 'horizontal',
    thickness,
    length = '100%',
    color,
    margin,
    padding,
    style,
}: IProps) {
    const DividerContainer = styled.div`
        width: ${direction === 'horizontal' ? length : thickness + 'px'};
        height: ${direction === 'vertical' ? length : thickness + 'px'};
        background-color: ${color};
        margin: ${margin};
        padding: ${padding};
    `;

    return <DividerContainer className={className} style={style} />;
}
