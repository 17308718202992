import { useState } from 'react';
import styled from 'styled-components';

import useModal from '@/hooks/useModal';
import CarouselWithEnlarger from '../Carousel/CarouselWithEnlarger';
import DefaultIndicator from '../Indicator/DefaultIndicator';
import EnlargedImageCarousel from '../Modal/EnlargedImageCarousel';

interface Props {
    imageArr: string[];
    className?: string;
}

const ImageCarouselDefault = ({ imageArr, className }: Props) => {
    const { addModal, removeCurrentModal } = useModal();
    const [currentTab, setCurrentTab] = useState(0);

    const onClick = () => {
        addModal({
            key: 'fullScreen',
            props: {
                title: '',
                content: <EnlargedImageCarousel imageArr={imageArr} selectedTab={currentTab} />,
                handleCloseModal: removeCurrentModal,
                transparent: true,
                containerStyle: { backgroundColor: 'rgba(0,0,0,0.8)' },
            },
        });
    };

    return (
        <ImageContainer className={className}>
            <CarouselWithEnlarger
                imageArr={imageArr}
                currentTab={currentTab}
                handleTab={(tab: number) => setCurrentTab(tab)}
                onClickEnlarger={onClick}
            />
            <DefaultIndicator length={imageArr.length} currentTab={currentTab} />
        </ImageContainer>
    );
};

const ImageContainer = styled.div`
    ${({ theme }) => theme.flex.flexColumn};
    padding: 22px 20px 0 20px;
    gap: 12px;
`;

export default ImageCarouselDefault;
