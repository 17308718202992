import { CSSProperties } from 'react';
import { ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FontStyle } from '@/utils/StyleUtil';

function DefaultToast() {
    const containerStyle: CSSProperties = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '40%',
    };

    const defaultToastStyle: CSSProperties = {
        maxWidth: '100%',
        width: 'fit-content',
        minHeight: 0,
        padding: 0,
        borderRadius: '6px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 20px rgba(0,0,0,0.1)',
    };

    const defaultBodyStyle: CSSProperties = {
        padding: '15px 20px',
        ...FontStyle(12, 20, '400', '#888888', -1),
    };

    const defaultOptions: ToastOptions & { toastStyle?: CSSProperties } = {
        position: 'top-center',
        hideProgressBar: true,
        autoClose: 1000,
        closeButton: false,
        style: containerStyle,
        toastStyle: defaultToastStyle,
        bodyStyle: defaultBodyStyle,
    };

    return <ToastContainer {...defaultOptions} limit={1} />;
}

export default DefaultToast;
