import { ITM_Direct, IDE_Direct, IDM_Direct } from '../myPlan';
import { IAC_Plan } from './accident';
import { IDE_Plan } from './death';
import { IDM_Plan } from './dementia';
import { IIM_Plan } from './indemnity';
import { ITM_Plan, ITM_Senior } from './totalMedical';

export * from './accident';
export * from './death';
export * from './totalMedical';
export * from './dementia';
export * from './indemnity';

export type TProductType =
    | 'renewable'
    | 'nonRenewable'
    | 'nonCancel'
    | 'interestRate'
    | 'lowCancel'
    | 'variable'
    | 'normal'
    | 'notSurrender'
    | 'lowSurrender'
    | 'standard'
    | 'partialCancellationRefund'
    | 'unpaidableCancellationRefund'
    | 'interestRateRelated'
    | 'interestRateFixed';

export const TM = 100;
export const TMSENIOR = 110;
export const TMFETUS = 120;
export const TMSICK = 130;
export const DE = 200;
export const AC = 300;
export const DM = 400;
export const IM = 500;
export const IMFETUS = 510;

export const INQUIRY = 9000;
export const INQUIRYTM = 9100;
export const INQUIRYTMFETUS = 9120;
export const INQUIYTMSICK = 9130;
export const INQUIRYDE = 9200;
export const INQUIRYDM = 9400;

export type TInsuranceType = 100 | 110 | 120 | 130 | 200 | 300 | 400 | 500 | 510 | 0 | 9000;

export type TRequestType =
    | 100
    | 110
    | 120
    | 130
    | 200
    | 300
    | 400
    | 500
    | 510
    | 0
    | 9000
    | 9100
    | 9120
    | 9130
    | 9200
    | 9400;

export type TPlan =
    | ITM_Plan
    | ITM_Direct
    | IIM_Plan
    | ITM_Senior
    | IAC_Plan
    | IDE_Plan
    | IDE_Direct
    | IDM_Plan
    | IDM_Direct;

export type TNoProduct = ITM_Direct | IAC_Plan | ITM_Senior | IDE_Direct | IDM_Direct;

export type TProduct = ITM_Plan | IIM_Plan | IDE_Plan | IDM_Plan;

export const UNIT: { [key: string]: string } = {
    Y: '년',
    A: '세',
    M: '월',
};

export const CITY_PLACEHOLDER = '시/도 선택';

export const GUNGU_PLACEHOLDER = '구/시·군 선택';

export const RISKGUIDE = [
    {
        parent: '선택하신 직업의 위험성과 보험료를 평가하는 위험등급은 직업의\n직무에 따라 달라질 수 있으며 보험료가 변동될 수 있습니다.',
    },
];

export const RISK_TEXTS = [
    { mainText: '저위험직', subText: '사무직, 주부, 학생 등' },
    { mainText: '중위험직', subText: '영업직, 서비스, 단순노무 등' },
    { mainText: '고위험직', subText: '건설노동, 공장, 운송 등' },
];

export const TInsuranceToString = (code: TInsuranceType) => {
    switch (code) {
        case TM:
            return 'TM';
        case TMFETUS:
            return 'TMFETUS';
        case TMSICK:
            return 'TMSICK';
        case IM:
            return 'IM';
        default:
            return 'other';
    }
};

export const TInsuranceStringToCode = (code: string) => {
    switch (code) {
        case 'TM':
            return TM;
        case 'TMFETUS':
            return TMFETUS;
        case 'TMSICK':
            return TMSICK;
        case 'IM':
            return IM;
        default:
            return 0;
    }
};
