import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import useSendbirdConnect from '@/hooks/chat/useSendbirdConnect';
import { isLoginState } from '@/recoil';

const Sendbird = () => {
    const { connect } = useSendbirdConnect();
    const isLogin = useRecoilValue(isLoginState);

    // sendbird init
    useEffect(() => {
        connect();
    }, [isLogin]);

    return <></>;
};

export default Sendbird;
