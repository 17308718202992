import React from 'react';

import VerticalImageNContent from '@/components/ContextPrinter/VerticalImageNContent';

const Error = ({ isList }: { isList: boolean }) => {
    return (
        <VerticalImageNContent
            content={'서비스 점검중입니다.\n잠시 후 다시 사용해 주세요'}
            containerStyle={{ paddingTop: isList ? 52 : 160 }}
            image={isList ? '/img/common/error_page_g_dark@3x.png' : '/img/common/error_page_g_light@3x.png'}
            imageWidth={280}
            imageHeight={100}
        />
    );
};

export default Error;
