import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';

import { FontStyle } from '@/utils/StyleUtil';
import BottomFixedButton from '@/components/Button/BottomFixedButton';

function ErrorPage() {
    return (
        <ErrorContainer>
            <Image src={'/img/error/errorimg03.png'} alt="error" width={72} height={72} />
            <div className="main">{'잘못된 접근이에요 :('}</div>
            <div className="sub">페이지를 다시 확인해 주세요</div>
            <BottomFixedButton text="처음으로 돌아가기" link={'/'} active buttonColor="#0ec4cf" />
        </ErrorContainer>
    );
}

const ErrorContainer = styled.div`
    ${({ theme }) => theme.flex.flexColumn};
    ${({ theme }) => theme.flex.justifyCenter};
    width: 100%;
    height: calc(100% - 58px);
    .main {
        margin-top: 10px;
        ${FontStyle(20, 28, '700', '#333', -1)}
    }
    .sub {
        margin-top: 4px;
        ${FontStyle(14, 22, '400', '#acacac', -1)}
    }
`;

export default ErrorPage;
