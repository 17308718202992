import { atom } from 'recoil';

import { IDMInsuranceInput, IDM_Plan } from '@/models/plan';
import { InsuredPerson, defaultUser } from '@/models/userInput';

export const DMUserInfoState = atom<InsuredPerson>({
    key: 'DMUserInfoState',
    default: defaultUser,
});

export const DMStandardPlanState = atom<number>({
    key: 'DMStandardPlanState',
    default: 0,
});

export const DMInsuranceInputState = atom<IDMInsuranceInput>({
    key: 'DMInsuranceInputState',
    default: {
        paymentPeriod: 20,
        guaranteePeriod: 90,
        livingExpense: 0,
    },
});

export const DMPlanState = atom<IDM_Plan | undefined>({
    key: 'DMPlanState',
    default: undefined,
});
