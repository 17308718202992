import { TInsuranceType, TProductType } from '.';

export const EXPENSE = [
    {
        key: 'livingExpenses',
        tabMenu: '생활비',
        title: '유가족 자립 준비 기간의 생활비',
        subTitle: '부양가족의 수를 반영한  월평균 지출 금액으로 유가족의 자립 기간 동안 필요한생활비 금액을 산정해요.',
        source: '출처 : 통계청 가계동향조사 (2024. 01분기 기준)',
    },
    {
        key: 'educationExpenses',
        tabMenu: '교육비',
        title: '자녀의 대학 졸업 시 까지 필요한 교육비',
        subTitle: '자녀의 학령에 맞춰서 대학 졸업 시 까지 필요한 교육비를 산정해요.',
        source: '출처 : 유치원알리미 공시 (24. 1차기준), 통계청 초중고교사 교육비 조사 (23년 기준), 교육부 대학알리미 (24년 기준)',
    },
    {
        key: 'funeralExpenses',
        tabMenu: '장례비',
        title: '장례비 및 장묘비',
        subTitle: '장례 시 필요한 빈소 사용료부터 매장을 위한 비용까지\n평균 비용을 통해 산정합니다.',
        source: '출처 : 한국소비자원 KCA 보고서',
    },
    {
        key: 'spendingExpenses',
        tabMenu: '배우자소득',
        title: '소득별 대체 가능 사망보험금',
        subTitle:
            '배우자 소득으로 사망보험금의 일부 대체가 가능하며 소득 구간별  소비지출가능금액을 추산하여 산정합니다.',
        source: '출처 : 통계청 가계동향조사 (24년 1분기 기준)',
    },
];

export const BARSETTING: { [key: string]: { color: string; title: string } } = {
    living: {
        color: '#45CED7',
        title: '생활비',
    },
    education: {
        color: '#6497FA',
        title: '교육비',
    },
    funeral: {
        color: '#8976FF',
        title: '장례비',
    },
};

export const FAMILY_NUMBER_PLACEHOLDER = '본인을 제외한 인원수를 선택하세요';
export const SPOUSE_INCOME_PLACEHOLDER = '월 소득구간을 선택하세요';

export const defaultStatistics = { living: 0, education: 0, funeral: 0, spending: 0 };

export const defaultDeathTableData = {
    educationExpenses: [
        {
            expenseKey: '3',
            expenseValue: 130000,
        },
        {
            expenseKey: '4',
            expenseValue: 120000,
        },
        {
            expenseKey: '5',
            expenseValue: 90000,
        },
        {
            expenseKey: 'ElementarySchool',
            expenseValue: 400000,
        },
        {
            expenseKey: 'MiddleSchool',
            expenseValue: 450000,
        },
        {
            expenseKey: 'HighSchool',
            expenseValue: 490000,
        },
        {
            expenseKey: 'University',
            expenseValue: 6820000,
        },
    ],
    spendingExpenses: [
        {
            expenseKey: '0',
            expenseValue: 1390000,
        },
        {
            expenseKey: '200',
            expenseValue: 1900000,
        },
        {
            expenseKey: '300',
            expenseValue: 2270000,
        },
        {
            expenseKey: '400',
            expenseValue: 2650000,
        },
        {
            expenseKey: '500',
            expenseValue: 3280000,
        },
        {
            expenseKey: '600',
            expenseValue: 3640000,
        },
        {
            expenseKey: '700',
            expenseValue: 4950000,
        },
    ],
    livingExpenses: [
        {
            expenseKey: '1',
            expenseValue: 1690000,
        },
        {
            expenseKey: '2',
            expenseValue: 2510000,
        },
        {
            expenseKey: '3',
            expenseValue: 3840000,
        },
        {
            expenseKey: '4',
            expenseValue: 4760000,
        },
        {
            expenseKey: '5',
            expenseValue: 5120000,
        },
    ],
    funeralExpenses: [
        {
            expenseKey: 'funeral',
            expenseValue: 9380000,
        },
        {
            expenseKey: 'burial',
            expenseValue: 7140000,
        },
        {
            expenseKey: 'total',
            expenseValue: 16520000,
        },
    ],
};

export const LIVINGKEY: { [key: string]: string } = {
    '1': '1인',
    '2': '2인',
    '3': '3인',
    '4': '4인',
    '5': '5인 이상',
};

export const EDUCATIONKEY: { [key: string]: string } = {
    '3': '사립 유치원 (만 3세)',
    '4': '사립 유치원 (만 4세)',
    '5': '사립 유치원 (만 5세~)',
    ElementarySchool: '초등학교',
    MiddleSchool: '중학교',
    HighSchool: '고등학교',
    University: '일반 및 교육대학(4년제)',
};

export const SPENDINGKEY: { [key: string]: string } = {
    '0': '200만원 미만',
    '200': '200~300만원',
    '300': '300~400만원',
    '400': '400~500만원',
    '500': '500~600만원',
    '600': '600~700만원',
    '700': '700만원 이상',
};

export interface IDEInsuranceInput {
    paymentPeriod: number; //'Y20'
    guaranteePeriod: number; //'A100'
    investmentPaymentPeriod: number;
    investmentGuaranteePeriod: number;
    subscriptionAmount: number;
    familyNumber: number;
    income: string;
    childrenAge: number[];
}

export const defaultDEInsuranceInput = {
    paymentPeriod: 20,
    guaranteePeriod: 999,
    investmentPaymentPeriod: 20,
    investmentGuaranteePeriod: 20,
    subscriptionAmount: 0,
    familyNumber: 0,
    income: '',
    childrenAge: [],
};

export interface IDE_Plan {
    insuranceType: TInsuranceType;
    companyCode: string;
    companyName: string;
    productName: string;
    complianceConfirm: string;
    premiumReferenceDate: string;
    productType: TProductType[];
    premium: number;
    productCode: string;
    paymentPeriod: number;
    paymentPeriodUnit: string;
    guaranteePeriod: number;
    guaranteePeriodUnit: string;
    subscriptionAmount: number;
    minPremium: number;
    description?: string;
    informFileName: string;
    cancellationRefunds: {
        ratio: string;
        ratioName: string;
        refunds: number[];
        ratioComment: string;
    }[];
    coverageName: string;
}

export const FAMILY_NUMBER = ['0명', '1명', '2명', '3명', '4명', '5명 이상'];
export const FAMILY_NUMBERKEY = [0, 1, 2, 3, 4, 5];
export const SPOUSE_INCOME = [
    '없음',
    '200만원 미만',
    '200만원 이상 300만원 미만',
    '300만원 이상 400만원 미만',
    '400만원 이상 500만원 미만',
    '500만원 이상 600만원 미만',
    '600만원 이상 700만원 미만',
    '700만원 이상',
];

export const SPOUSE_INCOMEKEY = ['-1', '0', '200', '300', '400', '500', '600', '700'];
