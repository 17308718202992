import { useState, CSSProperties } from 'react';
import { toast, ToastContent, ToastOptions } from 'react-toastify';

export default function useToast() {
    const [isToastShown, setIsToastShown] = useState(false);

    const openToast = (text: ToastContent<unknown>, options?: ToastOptions & { toastStyle?: CSSProperties }) => {
        if (!isToastShown) {
            toast(text, {
                ...options,
                onOpen: () => setIsToastShown(true),
                onClose: () => setIsToastShown(false),
            });
        }
    };

    return { openToast };
}
