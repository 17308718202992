import React, { useState } from 'react';
import styled from 'styled-components';

import CarouselWithPinchZoom from '@/components/Carousel/CarouselWithPinchZoom';
import ThumbnailIndicator from '@/components/Indicator/ThumbnailIndicator';

interface IProps {
    imageArr: string[];
    selectedTab: number;
}

const EnlargedImageCarousel = ({ imageArr, selectedTab }: IProps) => {
    const [currentTab, setCurrentTab] = useState(selectedTab);

    return (
        <div>
            <MarginDiv />
            <ImageContainer>
                <CarouselWithPinchZoom
                    imageArr={imageArr}
                    selectedTab={currentTab}
                    handleTab={(tab: number) => {
                        setCurrentTab(tab);
                    }}
                />
                <ThumbnailIndicator
                    imageArr={imageArr}
                    selectedTab={currentTab}
                    handleTab={(tab: number) => {
                        setCurrentTab(tab);
                    }}
                />
            </ImageContainer>
        </div>
    );
};

const MarginDiv = styled.div`
    margin-top: 60px;
`;

const ImageContainer = styled.div<{ setLayout?: boolean }>`
    ${({ theme }) => theme.flex.flexColumn};
    margin-left: 20px;
`;

export default EnlargedImageCarousel;
