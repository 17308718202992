import { atom } from 'recoil';

interface IPushToken {
    platform: string;
    fcmToken: string;
    apnsToken: string;
}

export const pushTokenState = atom<IPushToken>({
    key: 'pushTokenState',
    default: {
        platform: '',
        fcmToken: '',
        apnsToken: '',
    },
});
