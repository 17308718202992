import React, { useState, useEffect, CSSProperties } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import SvgSelector from '@/components/Svg/SvgSelector';
import MovingFocusInput from '@/components/InputForm/MovingFocusInput';
import { checkErrorState } from '@/recoil';
import { FontStyle } from '@/utils/StyleUtil';

interface IProps {
    inputType: string;
    givenInput?: string;
    typedEmail?: Function;
    isError?: Function;
    isMoving?: boolean;
    style?: CSSProperties;
}

const InputForm = ({ inputType, givenInput = '', typedEmail, isError, isMoving, style }: IProps) => {
    const [isCheckError, setIsCheckError] = useRecoilState(checkErrorState);
    const [input, setInput] = useState(givenInput);
    const [inputBorder, setInputBorder] = useState('#dadada');
    const [inputErrorMsg, setInputErrorMsg] = useState('');

    const checkName = (name: string) => {
        const koReg = /^[가-힣]+$/;
        const engReg = /^[a-zA-Z]+$/;

        setInputBorder('#ff1e1e');

        let errorMsg: string;
        if (name.length < 2 && name.length > 0)
            errorMsg = '최소 2글자, 최대 한글 8글자 영문 10글자까지 입력 가능합니다';
        else if (koReg.test(name) && name.length > 8)
            errorMsg = '최소 2글자, 최대 한글 8글자 영문 10글자까지 입력 가능합니다';
        else if (engReg.test(name) && name.length > 10)
            errorMsg = '최소 2글자, 최대 한글 8글자 영문 10글자까지 입력 가능합니다';
        else if (!koReg.test(name) && !engReg.test(name) && name.length > 0) errorMsg = '이름을 확인해 주세요';
        else {
            errorMsg = '';

            if (name.length > 0) setInputBorder('#444444');
            else setInputBorder('#dadada');
        }

        setInputErrorMsg(errorMsg);

        if (errorMsg) return true;
        else return false;
    };

    const checkError = (value: string) => {
        if (inputType === 'name') return checkName(value);
    };

    useEffect(() => {
        if (isCheckError) {
            checkError(givenInput);
            setIsCheckError(false);
        }
    }, [isCheckError]);

    return (
        <Container style={style}>
            <Title>회원명</Title>
            <InfoTypingContainer>
                <InfoTyping
                    placeholder="홍길동"
                    type="text"
                    inputMode="text"
                    value={input}
                    onChange={(e: any) => {
                        setInput(e.target.value);
                        typedEmail!(e.target.value);
                        isError!(checkError(e.target.value));
                    }}
                    onFocus={() => setInputBorder('#444444')}
                    onBlur={() => setInputBorder('#dadada')}
                    style={{ borderBottomColor: inputBorder }}
                    isEmail={inputType === 'email' ? true : false}
                    isMoving={isMoving}
                />
            </InfoTypingContainer>
            <ErrorMsg>
                {inputErrorMsg !== '' && (
                    <SvgSelector
                        svg="exclamation-mark-circle"
                        width={16}
                        height={16}
                        fill="none"
                        stroke="#ff4343"
                        strokeWidth={1}
                    />
                )}
                {inputErrorMsg}
            </ErrorMsg>
        </Container>
    );
};

const Container = styled.div``;

const Title = styled.div`
    margin-bottom: 10px;
    ${FontStyle(14, 22, '500', '#686868', -1)};
`;

const InfoTypingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`;

const InfoTyping = styled(MovingFocusInput)<{ isEmail: boolean }>`
    width: 100%;
    border: none;
    border-bottom: 1px solid #dadada;
    outline: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    padding: 9px 0;
    ${FontStyle(18, 26, '400', '#444444', undefined)};
    letter-spacing: ${({ isEmail }) => (isEmail ? 0 : -1)};

    ::placeholder {
        color: #c8c8c8;
    }
`;

const ErrorMsg = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    align-items: center;
    gap: 3px;
    margin-top: 8px;
    ${FontStyle(12, 18, '400', '#ff4343', -1.2)};
`;

export default InputForm;
