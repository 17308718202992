import { LocalStorage } from '@/utils/LocalStorage';
import { useEffect, useState, useCallback } from 'react';

const useLocalStorage = <T,>(key: string) => {
    const [data, setData] = useState<string | null | undefined>();

    useEffect(() => {
        const data = LocalStorage.getItem(key);
        setData(data);
    }, [key]);

    const getItem = useCallback(() => {
        if (data) return JSON.parse(data);
    }, [data]);

    const updateItem = useCallback(
        (value: T) => {
            const id = Math.floor(Math.random() * (999999999 - 100000000) + 100000000);
            const data = LocalStorage.getItem(key);
            if (data) {
                const newInterestPlans = JSON.parse(data);
                LocalStorage.setItem(
                    key,
                    JSON.stringify([
                        ...newInterestPlans,
                        {
                            ...value,
                            id,
                            createdAt: new Date().toISOString(),
                        },
                    ]),
                );
            } else {
                LocalStorage.setItem(key, JSON.stringify([{ ...value, id, createdAt: new Date().toISOString() }]));
            }
        },
        [key],
    );

    const deleteItem = useCallback(
        (id: number) => {
            const data = LocalStorage.getItem(key);
            if (data) {
                const localStorageData = JSON.parse(data);
                const newInterestPlans = localStorageData.filter((item: { id: number }) => id !== item.id);
                localStorage.setItem(key, JSON.stringify(newInterestPlans));
            }
        },
        [key],
    );

    return { updateItem, deleteItem, getItem };
};

export default useLocalStorage;
