import { INPUT_TYPE } from '@/models/plan';
import PlanCommonUtil from './PlanCommonUtil';
import { DateTime } from 'luxon';
import { InsuredFetus, MedicalInsuredPerson } from '@/models/userInput';
import TotalMedicalUtil from './TotalMedicalUtil';

// 실손이 나이 계산 방식이 다른 보험과 달랐으니, 일단 긴존 실손 방식 틀 진행
const _birthMin = (type: number) => {
    const now = DateTime.local();
    const ageToUse = (() => {
        switch (type) {
            case INPUT_TYPE.BIRTH:
                return 65;
            case INPUT_TYPE.SICK_BIRTH:
                return 70;
            default:
                return -1;
        }
    })();
    const min = now.minus({ years: ageToUse, months: 6 }).plus({ days: 1 }).toFormat('yyyyMMdd');
    return min;
};

const _birthMax = (type: number) => {
    const now = DateTime.local();
    const ageToUse = (() => {
        switch (type) {
            case INPUT_TYPE.BIRTH:
                return 0;
            case INPUT_TYPE.SICK_BIRTH:
                return 4;
            default:
                return -1;
        }
    })();
    if (ageToUse === 0) return now.toFormat('yyyyMMdd');
    const max = now.minus({ years: ageToUse, months: 6 }).plus({ days: 1 }).toFormat('yyyyMMdd');
    return max;
};

const setErrorMsg = (value: string, type: number) => {
    // 1. 입력 형식이 맞는지
    const minRange = _birthMin(type);
    const maxRange = _birthMax(type);
    if (!PlanCommonUtil._checkValidDate(value)) return '생년월일을 올바르게 입력해주세요';
    if (!PlanCommonUtil._checkDateRange(value, DateTime.fromISO(minRange), DateTime.fromISO(maxRange))) {
        return '가입을 할 수 없는 나이입니다';
    }
    return '';
};

const checkValid = (value: string, sick: boolean) => {
    // 1. 입력 형식이 맞는지
    if (!PlanCommonUtil._checkValidDate(value)) return false;
    // 2. 기간이 맞는지
    const type = sick ? INPUT_TYPE.SICK_BIRTH : INPUT_TYPE.BIRTH;
    const minRange = _birthMin(type);
    const maxRange = _birthMax(type);
    if (!PlanCommonUtil._checkDateRange(value, DateTime.fromISO(minRange), DateTime.fromISO(maxRange))) {
        return false;
    }
    return true;
};

const getIMListParams = (userInfo: MedicalInsuredPerson | InsuredFetus) => {
    const { birth, risk } = userInfo;
    const insuranceAge = PlanCommonUtil._calculateInsuranceAge(birth);
    const westernAge = PlanCommonUtil._calculateWesternAge(birth);
    const userType = TotalMedicalUtil.getUserCode(userInfo);
    if (TotalMedicalUtil.isFetus(userInfo)) {
        const params = {
            motherInsuranceAge: insuranceAge,
            motherWesternAge: westernAge,
            motherRisk: risk,
            expectedBirthDate: Number(userInfo.expectedBirthDate),
            fetus: true,
            sick: false,
        };
        return params;
    }
    const params = {
        risk,
        sex: (userInfo as MedicalInsuredPerson).sex,
        insuranceAge,
        westernAge,
        sick: userType.includes('S'),
        fetus: false,
    };

    return params;
};

const getIMInsuredPersonInfo = (insuredPerson: MedicalInsuredPerson | InsuredFetus) => {
    if ('expectedBirthDate' in insuredPerson) {
        const fetusInfo = {
            motherRisk: insuredPerson.risk,
            expectedBirthDate: insuredPerson.expectedBirthDate.toString(),
            motherBirth: insuredPerson.birth,
        };
        return fetusInfo;
    } else {
        const personInfo = {
            insuredPersonRisk: insuredPerson.risk,
            insuredPersonBirth: insuredPerson.birth,
            insuredPersonSex: insuredPerson.sex,
            insuredPersonSick: insuredPerson.sick === 1,
        };
        return personInfo;
    }
};

export default { checkValid, setErrorMsg, getIMInsuredPersonInfo, getIMListParams };
