import { DateTime } from 'luxon';

import {
    IAdditionalInput,
    IGuarantee,
    ITMInsuranceInput,
    INPUT_TYPE,
    ITM_Plan,
    TMListRequestBody,
    TM_MAJOR_DISEASE,
    ISeniorInput,
    TMSENIOR,
} from '@/models/plan';
import { InsuredFetus, InsuredPerson, MedicalInsuredPerson } from '@/models/userInput';
import PlanCommonUtil from './PlanCommonUtil';
import { DefaultValue } from 'recoil';

/*
 * 통합의료비 Type에 따른 생년월일 범위 계산
 */
const dateRangeByType = (type: number) => {
    let minDate = '';
    let maxDate = '';
    if (type === INPUT_TYPE.BIRTH) {
        minDate = PlanCommonUtil._birthMin();
        maxDate = PlanCommonUtil._birthMax();
    }
    if (type === INPUT_TYPE.MATERNITY_BIRTH) {
        minDate = _maternityMin();
        maxDate = _maternityMax();
    }
    if (type === INPUT_TYPE.DUEDATE) {
        minDate = _dueDateMin();
        maxDate = _dueDateMax();
    }
    if (type === INPUT_TYPE.SICK_BIRTH) {
        minDate = PlanCommonUtil._birthMin();
        maxDate = PlanCommonUtil._sickBirthMax();
    }
    return {
        min: DateTime.fromISO(minDate),
        max: DateTime.fromISO(maxDate),
    };
};

/*
 * 산모의 최소 생년월일
 */
const _maternityMin = () => {
    const now = DateTime.local();
    const min = now.minus({ years: 45, months: 6 }).plus({ days: 1 }).toFormat('yyyyMMdd');
    return min;
};

/*
 * 산모의 최대 생년월일
 */
const _maternityMax = () => {
    const now = DateTime.local();
    const max = now.minus({ years: 15 }).toFormat('yyyyMMdd');
    return max;
};

/*
 * 최대 출산 예정일
 */
const _dueDateMax = () => {
    const now = DateTime.local();
    const max = now.plus({ days: 280 }).toFormat('yyyyMMdd');
    return max;
};

/*
 * 최소 출산 예정일
 */
const _dueDateMin = () => {
    const now = DateTime.local();
    const min = now.plus({ days: 1 }).toFormat('yyyyMMdd');
    return min;
};

/*
 * 입력 값의 임신 주기 계산
 */
const _calculatePregnancyCycle = (v: string) => {
    const pragmentDay = DateTime.fromFormat(v, 'yyyyMMdd').minus({ days: 280 }).toISODate();
    const now = DateTime.local().toISODate();
    const start = DateTime.fromISO(pragmentDay);
    const end = DateTime.fromISO(now);

    const cycle = end.diff(start, ['week', 'day']).toObject().weeks!;
    if (cycle === 0 && !Object.is(+0, cycle)) return 0;
    if (cycle >= 40 || cycle < 0 || cycle === undefined) return 0;

    return cycle;
};

/*
 * TM 기준 사용자 타입 반환
 * CommonUtil 쪽으로 넣지 않은 이유는 fetus 같이 각 보험마다 요구하는 타입이 다를 수 있기 때문
 */
function getUserCode(userData: MedicalInsuredPerson | InsuredFetus) {
    const insuranceAge = PlanCommonUtil._calculateInsuranceAge(userData.birth);
    const westernAge = PlanCommonUtil._calculateWesternAge(userData.birth);
    if (!userData) return 'Q';
    // 태아
    if ('expectedBirthDate' in userData) return 'F';
    // 유병자일 경우
    if (userData.sick === 1) {
        // 유병자 - 일반
        if (westernAge >= 15 && insuranceAge <= 60) {
            return 'S0';
        }
        // 유병자 - 고연령
        if (insuranceAge > 60 && insuranceAge <= 70) {
            return 'S1';
        }
        // 유병자 - 초고연령
        if (insuranceAge > 70 && insuranceAge <= 90) {
            return 'S2';
        }
    } else {
        if (insuranceAge >= 0 && westernAge < 15) {
            // 표준체 - 어린이
            return 'N0';
        }
        if (westernAge >= 15 && insuranceAge <= 50) {
            // 표준체 - 일반
            return 'N1';
        }
        if (insuranceAge > 50 && insuranceAge <= 60) {
            // 표준체 - 고연령
            return 'N2';
        }
        if (insuranceAge > 60 && insuranceAge <= 65) {
            // 표준체 - 고연령2
            return 'N3';
        }
        if (insuranceAge > 65 && insuranceAge <= 90) {
            // 표준체 - 초고연령
            return 'N4';
        }
    }
    return 'Q';
}

function checkUserType(userType: string): 'OLDER' | 'OLDEST' | 'NORMAL' | 'FETUS' {
    switch (userType) {
        case 'F':
            return 'FETUS';
        case 'N3':
        case 'S1':
            return 'OLDER';
        case 'N4':
        case 'S2':
            return 'OLDEST';
        default:
            return 'NORMAL';
    }
}

/**
 * @param type 0 = BIRTH, 1 = MATERNITY_BIRTH, 2 = DUEDATE
 */
const setErrorMsg = (value: string, type: number) => {
    // 1. 입력 형식이 맞는지
    if (!PlanCommonUtil._checkValidDate(value)) {
        switch (type) {
            case INPUT_TYPE.BIRTH:
            case INPUT_TYPE.MATERNITY_BIRTH:
                return '생년월일을 올바르게 입력해주세요';
            case INPUT_TYPE.DUEDATE:
                return '출산 예정일을 올바르게 입력해주세요';
            default:
                return '생년월일을 올바르게 입력해주세요';
        }
    }
    // 2. 기간이 맞는지
    const range = dateRangeByType(type);
    const toPrepend = PlanCommonUtil._calculatePrependValue(value);
    const fourDigitYear = toPrepend.concat(value);

    if (!PlanCommonUtil._checkDateRange(fourDigitYear, range.min, range.max)) {
        switch (type) {
            case INPUT_TYPE.BIRTH:
            case INPUT_TYPE.SICK_BIRTH:
                return '가입을 할 수 없는 나이입니다';
            case INPUT_TYPE.MATERNITY_BIRTH:
                return '가입을 할 수 없는 나이입니다(최대 가능 나이 보험나이 기준 45세)';
            case INPUT_TYPE.DUEDATE:
                return '가입을 할 수 없는 임신주수입니다(최대 가능 임신주수 기준 39주)';
            default:
                return '가입을 할 수 없는 나이입니다';
        }
    }
    return '';
};

/*
 * user information type guard
 */
const isFetus = (props: InsuredPerson | MedicalInsuredPerson | InsuredFetus): props is InsuredFetus => {
    if ('expectedBirthDate' in props) {
        return true;
    }
    return false;
};

/**
 * 추가보장 보험료 계산
 * @param subscriptionAmount
 * @param unitMP
 * @param monthlyPayment
 * @returns number:계산된 추가보장 보험료
 */

const calculatePremium = (subscriptionAmount: number, unitMP: number, monthlyPayment: number): number => {
    return Math.floor((subscriptionAmount * monthlyPayment) / unitMP);
};

/**
 * 어린이/태아 여부 반환
 * @param userType
 * @returns boolean
 */

const isFetusOrChild = (userType: string) => {
    return userType === 'F' || userType === 'N0';
};

/**
 * @param userInfo 유저 정보 birth: '19980302'; risk:number; sex:number; sick?:number; expectedBirthDate?:string;
 * @param insuranceInfo 사용자가 입력한 보험정보
 */
const getListParams = (
    userInfo: MedicalInsuredPerson | InsuredFetus,
    insuranceInfo: ITMInsuranceInput,
): TMListRequestBody[] => {
    const { risk, birth } = userInfo;
    const { periods, priority, selectedGP } = insuranceInfo;

    const majorDiseases: {
        cancer: number;
        brain: number;
        heart: number;
        liver?: number;
        lung?: number;
        kidney?: number;
    } = selectedGP.reduce((acc, cur) => Object.assign({ ...acc }, { [cur.key]: cur.value }), {
        cancer: 0,
        brain: 0,
        heart: 0,
    });
    const userInfoParams: {
        sick: boolean;
        fetus: boolean;
        insuranceAge?: number;
        westernAge?: number;
        motherInsuranceAge?: number;
        motherWesternAge?: number;
        motherRisk?: number;
        expectedBirthDate?: number;
        risk?: number;
        sex?: number;
    } = { fetus: false, sick: false };
    const userType = getUserCode(userInfo);

    if (isFetus(userInfo)) {
        const motherInsuranceAge = PlanCommonUtil._calculateInsuranceAge(birth);
        const motherWesternAge = PlanCommonUtil._calculateWesternAge(birth);
        Object.assign(userInfoParams, {
            motherInsuranceAge,
            motherWesternAge,
            motherRisk: risk,
            expectedBirthDate: Number(userInfo.expectedBirthDate),
            fetus: true,
            sick: false,
        });
    } else {
        const insuranceAge = PlanCommonUtil._calculateInsuranceAge(birth);
        const westernAge = PlanCommonUtil._calculateWesternAge(birth);
        Object.assign(userInfoParams, {
            risk,
            sex: userInfo.sex,
            insuranceAge,
            westernAge,
            sick: userType.includes('S'),
            fetus: false,
        });
    }

    const productTypeArr = [
        { renewal: true, notCancel: false },
        { renewal: false, notCancel: false },
        { renewal: false, notCancel: true },
    ];
    const unitArr = ['Y', 'A', 'A'];
    if (isFetusOrChild(userType)) {
        productTypeArr.shift();
        unitArr.shift();
    }

    const params = periods.guaranteePeriods.map((guaranteePeriod, idx) => ({
        ...majorDiseases,
        ...userInfoParams,
        ...productTypeArr[idx],
        priority,
        paymentPeriod: `Y${periods.paymentPeriod}`,
        guaranteePeriod: `${unitArr[idx]}${guaranteePeriod}`,
    }));

    return params;
};

/**
 * priority에 담긴 index들을 중요보장 이름과 선택 여부로 변환
 * @param priority number[]
 * @returns 선택한 보장들 boolean으로 표시
 */

const getSeniorSelectedGuarantee = ({ priority }: { priority: number[] }) => {
    const selectedGuarantee = TM_MAJOR_DISEASE.reduce((acc, v, idx) => {
        const isSelected = priority.includes(idx);
        return { ...acc, [v.key]: isSelected };
    }, {});

    return selectedGuarantee;
};

/**
 * 태아보험 KBF일때 2회차까지 표시, 나머지 1회차까지 표시
 * @param fetusPremium
 * @param companyCode
 * @returns
 */

const getFetusInstallmentByCompanyCode = (fetusPremium: number[], companyCode: string) => {
    const fetusInstallment = fetusPremium.filter((_: number, idx: number) => {
        if (companyCode === 'KBF') return idx <= 1;
        return idx < 1;
    });
    return fetusInstallment;
};

/**
 * 나이(userType)에 따라 달라지는 납입기간 리스트 반환
 * @param userType
 * @param guaranteePeriod
 * @param birth
 * @returns string[]
 */
const getPaymentPeriodList = (userType: string, guaranteePeriod: number, birth: string) => {
    const defaultList = ['10년', '20년', '30년'];
    const insuranceAge = PlanCommonUtil._calculateInsuranceAge(birth);
    if (userType === 'F') return defaultList;
    const periodList = defaultList.filter(v => {
        const paymentPeriod = Number(v.slice(0, -1));
        return guaranteePeriod - insuranceAge >= paymentPeriod;
    });
    return periodList;
};

/**
 * 나이(userType)에 따라 달라지는 보장기간 리스트 반환
 * @param userType
 * @param paymentPeriod
 * @param birth
 * @returns string[]
 */
const getGuaranteePeriodList = (userType: string, paymentPeriod: number, birth: string) => {
    const defaultList = ['80세', '90세', '100세'];
    if (userType === 'F') {
        defaultList.unshift('30세');
        return defaultList;
    }
    if (userType === 'N0') defaultList.unshift('30세');
    const guaranteePeriodList = defaultList.filter(v => {
        const insuranceAge = PlanCommonUtil._calculateInsuranceAge(birth);
        const guaranteePeriod = Number(v.slice(0, -1));
        return paymentPeriod + insuranceAge <= guaranteePeriod;
    });
    return guaranteePeriodList;
};

/**
 * 기존 상품 정보에 유저가 입력한 추가보장 정보 세팅
 * @param product 의료보장 상품 정보
 * @param additionalBeforeBirthUserInput
 * @param additionalAfterBirthUserInput
 * @returns IIM_Plan
 */
const getAdditionalGuaranteeProduct = (
    product: ITM_Plan,
    additionalBeforeBirthUserInput: { [key: string]: IAdditionalInput },
    additionalAfterBirthUserInput: { [key: string]: IAdditionalInput },
) => {
    const { beforeBirth, afterBirth } = product.coverage.additional.injuryDisease;

    const newAdditionalBeforeBirth = Object.entries(additionalBeforeBirthUserInput).reduce((newObj, [key, value]) => {
        const { subscriptionAmount } = value;
        if (beforeBirth && (key === 'injury' || key === 'disease')) {
            const isJoin = subscriptionAmount ? 1 : 0;
            return {
                ...newObj,
                [key]: beforeBirth[key].map(v => ({
                    ...v,
                    premium: (isJoin * v.maxSA * v.monthlyPayment) / v.unitMP,
                    subscriptionAmount,
                })),
            };
        } else if (key === 'injuryDaily' || key === 'diseaseDaily') {
            const _maxSA = Math.min(...(beforeBirth?.[key]?.map(v => v.maxSA) ?? []));
            // 담보들의 최대 가입 금액 중 최솟값 중 첫번째 요소를 기준으로 unitSA를 결정함
            // 추가 기획이 생긴다면 변경해줘야 함
            const { unitSA: _unitSA = 1 } = beforeBirth?.[key].find(v => v.maxSA === _maxSA) ?? {};
            const cnt = subscriptionAmount / _unitSA;
            return {
                ...newObj,
                [key]: beforeBirth?.[key]?.map(v => ({
                    ...v,
                    premium: (cnt * v.maxSA * v.monthlyPayment) / v.unitMP,
                    subscriptionAmount,
                })),
            };
        } else {
            return {};
        }
    }, {});

    const newAdditionalAfterBirth = Object.entries(additionalAfterBirthUserInput).reduce((newObj, [key, value]) => {
        const { subscriptionAmount } = value;
        if (afterBirth && (key === 'injury' || key === 'disease')) {
            const isJoin = subscriptionAmount ? 1 : 0;
            return {
                ...newObj,
                [key]: afterBirth[key].map(v => ({
                    ...v,
                    premium: (isJoin * v.maxSA * v.monthlyPayment) / v.unitMP,
                    subscriptionAmount: isJoin * v.maxSA,
                })),
            };
        } else if (key === 'injuryDaily' || key === 'diseaseDaily') {
            const _maxSA = Math.min(...afterBirth[key].map(v => v.maxSA));
            // 담보들의 최대 가입 금액 중 최솟값 중 첫번째 요소를 기준으로 unitSA를 결정함
            // 추가 기획이 생긴다면 변경해줘야 함
            const { unitSA: _unitSA = 1 } = afterBirth[key].find(v => v.maxSA === _maxSA) ?? {};
            const cnt = subscriptionAmount / _unitSA;
            return {
                ...newObj,
                [key]: afterBirth?.[key]?.map(v => ({
                    ...v,
                    premium: (cnt * v.maxSA * v.monthlyPayment) / v.unitMP,
                    subscriptionAmount,
                })),
            };
        } else {
            return {};
        }
    }, {});

    const totalAdditionalBeforeBirth = Object.values(additionalBeforeBirthUserInput).reduce(
        (acc, cur) => acc + cur.premium,
        0,
    );
    const totalAdditionalAfterBirth = Object.values(additionalAfterBirthUserInput).reduce(
        (acc, cur) => acc + cur.premium,
        0,
    );

    const fetus = {
        original: product?.premium?.fetus?.original,
        total: product?.premium?.fetus?.original?.map((item: number) => item + totalAdditionalBeforeBirth),
    };

    const normal = {
        original: product.premium.normal.original,
        total: product.premium.normal.original + totalAdditionalAfterBirth,
    };

    const newProduct = {
        ...product,
        premium: { normal, fetus },
        coverage: {
            ...product.coverage,
            additional: {
                injuryDisease: { beforeBirth: newAdditionalBeforeBirth, afterBirth: newAdditionalAfterBirth },
            },
        },
    };

    delete newProduct['additionalGuarantee'];

    return newProduct;
};

/**
 * userInput, oldAdditionalCoverage를 기반으로 유저가 가입한 추가보장 계산하고 newAdditionalCoverage를 기반으로 가입금액, 보험료 계산
 * @param newAdditionalCoverage
 * @param userInput
 * @param oldAdditionalCoverage
 * @returns
 */
const calculateAdditionalInput = (
    newAdditionalCoverage: {
        injury: IGuarantee[] | {}[];
        injuryDaily: IGuarantee[] | {}[];
        disease: IGuarantee[] | {}[];
        diseaseDaily: IGuarantee[] | {}[];
    },
    userInput: { [key: string]: IAdditionalInput },
    oldAdditionalCoverage: { [key: string]: IGuarantee[] },
) => {
    const newAdditionalInput = {
        injury: { label: '상해 수술비', premium: 0, subscriptionAmount: 0 },
        disease: { label: '질병 수술비', premium: 0, subscriptionAmount: 0 },
        injuryDaily: { label: '상해 입원일당', premium: 0, subscriptionAmount: 0 },
        diseaseDaily: { label: '질병 입원일당', premium: 0, subscriptionAmount: 0 },
    };

    Object.keys(userInput).forEach(key => {
        if (key === 'diseaseDaily' || key === 'injuryDaily') {
            const label = (newAdditionalCoverage[key] as IGuarantee[]).map(v => v.coverageName).join(', ');
            const _maxSA = Math.min(...oldAdditionalCoverage[key].map(v => v.maxSA));
            // 담보들의 최대 가입 금액 중 최솟값 중 첫번째 요소를 기준으로 unitSA를 결정함
            // 추가 기획이 생긴다면 변경해줘야 함
            const { unitSA: _unitSA = 1 } = oldAdditionalCoverage[key].find(v => v.maxSA === _maxSA) ?? {};
            const cnt = userInput[key].subscriptionAmount / _unitSA;
            const subscriptionAmounts = (newAdditionalCoverage[key] as IGuarantee[]).map(({ unitSA }) => cnt * unitSA);
            const premiums = (newAdditionalCoverage[key] as IGuarantee[]).map(({ unitSA, unitMP, monthlyPayment }) =>
                Math.floor((cnt * unitSA * monthlyPayment) / unitMP),
            );
            newAdditionalInput[key] = {
                ...newAdditionalInput[key],
                label,
                subscriptionAmount: subscriptionAmounts.reduce((acc, cur) => acc + cur),
                premium: premiums.reduce((acc, cur) => acc + cur),
            };
        } else if (key === 'disease' || key === 'injury') {
            const label = (newAdditionalCoverage[key] as IGuarantee[]).map(v => v.coverageName).join(', ');
            const isJoin = userInput[key].subscriptionAmount ? 1 : 0;
            const newSubscriptionAmount = Math.min(...(newAdditionalCoverage[key] as IGuarantee[]).map(v => v.maxSA));
            const premiums = (newAdditionalCoverage[key] as IGuarantee[]).map(({ maxSA, monthlyPayment, unitMP }) =>
                Math.floor((isJoin * maxSA * monthlyPayment) / unitMP),
            );
            newAdditionalInput[key] = {
                ...newAdditionalInput[key],
                label,
                subscriptionAmount: newSubscriptionAmount,
                premium: premiums.reduce((acc, cur) => acc + cur),
            };
        }
    });

    return newAdditionalInput;
};

const isSeniorInsInput = (insInput: ITMInsuranceInput | ISeniorInput | DefaultValue): insInput is ISeniorInput => {
    if ('selectedGP' in insInput) return false;
    return true;
};

const isTMInsuranceInput = (
    insInput: ITMInsuranceInput | ISeniorInput | DefaultValue,
): insInput is ITMInsuranceInput => {
    if ('selectedGP' in insInput) return true;
    return false;
};

const getTMInsuredPersonInfo = (insuredPerson: MedicalInsuredPerson | InsuredFetus) => {
    if ('expectedBirthDate' in insuredPerson) {
        const fetusInfo = {
            motherRisk: insuredPerson.risk,
            expectedBirthDate: insuredPerson.expectedBirthDate.toString(),
            motherBirth: insuredPerson.birth,
            insuredPersonType: 'F',
        };
        return fetusInfo;
    } else {
        const personInfo = {
            insuredPersonRisk: insuredPerson.risk,
            insuredPersonBirth: insuredPerson.birth,
            insuredPersonSex: insuredPerson.sex,
            insuredPersonType: getUserCode(insuredPerson),
        };
        return personInfo;
    }
};

const getTMSeniorRegisterParams = (
    userInfo: MedicalInsuredPerson,
    insInput: ISeniorInput,
    additionalInfo: { city: any; gungu: any; contents: string },
) => {
    const { city, gungu, contents } = additionalInfo;
    const userType = getUserCode(userInfo);
    const insuredPersonInfo = {
        insuredPersonBirth: userInfo.birth,
        insuredPersonRisk: (userInfo as MedicalInsuredPerson).risk,
        insuredPersonSex: (userInfo as MedicalInsuredPerson).sex,
        insuredPersonType: userType,
    };

    const insuranceInfo = {
        insuranceType: TMSENIOR,
        selectedGuarantee: getSeniorSelectedGuarantee(insInput),
        premium: insInput.claim,
    };

    const campaignCategory = window.sessionStorage.getItem('campaign-category');
    const campaignTerm = window.sessionStorage.getItem('campaign-term');
    const campaignSource = window.sessionStorage.getItem('campaign-source');
    const addSeo = campaignCategory !== undefined && campaignTerm !== undefined && campaignSource !== undefined;

    const additionalInfoWithSeo = {
        seo: { campaign: campaignCategory, term: campaignTerm, source: campaignSource },
    };

    const _additionalInfo = {
        locationCode: PlanCommonUtil._getLocationCode(city, gungu),
        userText: contents,
        ...(addSeo ? additionalInfoWithSeo : {}),
    };
    return { insuredPersonInfo, insuranceInfo, additionalInfo: _additionalInfo };
};

export default {
    dateRangeByType,
    _maternityMin,
    _maternityMax,
    _dueDateMax,
    _dueDateMin,
    _calculatePregnancyCycle,
    getUserCode,
    checkUserType,
    setErrorMsg,
    isFetus,
    calculatePremium,
    isFetusOrChild,
    getListParams,
    getSeniorSelectedGuarantee,
    getFetusInstallmentByCompanyCode,
    getPaymentPeriodList,
    getGuaranteePeriodList,
    getAdditionalGuaranteeProduct,
    calculateAdditionalInput,
    isSeniorInsInput,
    isTMInsuranceInput,
    getTMInsuredPersonInfo,
    getTMSeniorRegisterParams,
};
