import { atom } from 'recoil';
import { sessionAtom } from '../_persistAtom';
import { PromotionPlanner } from '@/models';

export const promotionPlannerState = atom<PromotionPlanner | null>({
    key: 'promotionPlannerState',
    default: null,
    effects_UNSTABLE: [sessionAtom('session-promotion-planner')],
});

export const referrerState = atom<string | undefined>({
    key: 'referrerState',
    default: undefined,
    effects_UNSTABLE: [sessionAtom('session-promotion-referrer')],
});
