import Head from 'next/head';

interface ISEO {
    title: string;
    description: string;
    url: string;
    image: string;
}

const SEO = (props: ISEO) => {
    const title = props.title ?? '픽앤플랜 ─ 보험에 대한 모든 고민의 솔루션';
    const description =
        props.description ??
        '전화번호 노출 없이 다양한 종합 보험 상품을 비교하고, 다수의 전문가가 보내드린 제안서 중 마음에 드는 제안서로 상담 받아보세요';
    const url = typeof window !== 'undefined' ? window.location.href : 'https://picknplan.com';
    const image =
        props.image ??
        'https://pickn-storage-development.s3.ap-northeast-2.amazonaws.com/public/picknplan_meta_logo.jpeg';

    return (
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
            <meta name="google-site-verification" content="pb-bKBlSPVcb7mvhdFsD_qooql9NMcn26aUMkKv9YK0" />
            <meta name="naver-site-verification" content="e44191fc5c01fb5ca35bfd47e6cc61208024d7dc" />
            <title>{title}</title>
            <meta name="description" key="main_meta" content={description} />

            {/* 구글 */}
            <meta itemProp="name" content={title} />
            <meta itemProp="description" content={description} />
            <meta itemProp="image" content={image} />

            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="픽앤플랜 ── 보험에 대한 모든 고민의 솔루션." />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />

            {/* 트위터 */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />

            {/* 카카오톡 */}
            <meta property="og:type" content="website" />
            <meta property="og:title" id="ogtitle" content={title} />
            <meta property="og:description" id="ogdesc" content={description} />
            <meta property="og:image" id="ogimg" content={image} />

            <link rel="icon" href="/img/logo/logo_favicon.png" />
            <link rel="canonical" href={url} />

            <script
                id="structured_data"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        '@context': 'http://schema.org',
                        '@type': 'Organization',
                        name: 'picknplan',
                        url: 'https://picknplan.com',
                        sameAs: [
                            'https://www.youtube.com/channel/UCGVaYa61mwQGR1MjF8FCljA',
                            'https://picknplan.com/blog/',
                            'https://blog.naver.com/picknplan_official',
                            'https://www.instagram.com/picknplan.official/',
                            'https://www.facebook.com/profile.php?id=100091483776511',
                            'https://apps.apple.com/kr/app/%ED%94%BD%EC%95%A4%ED%94%8C%EB%9E%9C/id1666115753',
                            'https://play.google.com/store/apps/details?id=com.picknplan_app',
                        ],
                    }),
                }}
            />
        </Head>
    );
};

export default SEO;
