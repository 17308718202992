import { atom, selector } from 'recoil';

import { IModal } from '@/models';

export const isLoadingState = atom<boolean>({
    key: 'isLoadingState',
    default: false,
});

export const modalState = atom<IModal[]>({
    key: 'modalState',
    default: [],
});

// true, loading, null
export const appState = atom<string | null>({
    key: 'appState',
    default: 'loading',
});

export const isAppState = selector<boolean>({
    key: 'isAppState',
    get: ({ get }) => {
        if (get(appState) === 'true') return true;
        else return false;
    },
});

export const proposalRouteState = atom<string>({
    key: 'proposalRouteState',
    default: '',
});

export const prevPathState = atom<string>({
    key: 'prevPathState',
    default: '',
});
