import React from 'react';
import axios from 'axios';
import styled from 'styled-components';

import useQueryHook from '@/hooks/useQueryHook';
import DividerDefault from '@/components/Divider/Default';
import QnAInfoByStatus from '@/components/ContextPrinter/QnAInfoByStatus';
import VerticalImageNContent from '@/components/ContextPrinter/VerticalImageNContent';
import { API, URLs } from '@/api';
import { FontStyle } from '@/utils/StyleUtil';
import ImageCarousel from '@/components/Viewer/ImageCarouselDefault';
import QnAInfoByStatusInquiry from '@/components/ContextPrinter/QnAInfoByStatusInquiry';

interface IProps {
    questionId: number;
}

interface IInquiry {
    id: number;
    userType: number;
    userId: number;
    inquiryTitle: string;
    inquiryContent: string;
    answerContent?: string;
    answerCreatedAt?: string;
    createdAt: string;
    updatedAt: string;
    files: string[];
}

const MyPageAnswer = ({ questionId }: IProps) => {
    const { data: QnAData } = useQueryHook(
        ['myPageQuestionDetail'],
        async () => {
            const QnAData: IInquiry = (await API.get(URLs.myPage.inquiryDetail(questionId))) as any;

            const saltArr: any = [];
            const imageArr: string[] | undefined = [];

            for (const imageName of QnAData.files) {
                saltArr.push(API.get(URLs.download.inquiryFile(questionId, imageName)));
            }
            await axios
                .all([...saltArr])
                .then(imageSalts => {
                    for (const saltStr of imageSalts) {
                        imageArr.push(URLs.temp.getFileUrl(encodeURIComponent(saltStr)));
                    }
                })
                .catch(err => {
                    console.log(err);
                });

            return { ...QnAData, imageURLs: imageArr };
        },
        {
            cacheTime: 0,
        },
    );

    const renderAnswer = () => {
        return (
            <AnswerContainer>
                <DividerDefault thickness={4} length="100%" color="#ebebeb" style={{ marginBottom: '24px' }} />
                <QnAInfoByStatusInquiry
                    // questionId={QnAData.id}
                    status={3}
                    content={QnAData.answerContent}
                    showStatus={false}
                    style={{ padding: '0 20px 50px 20px' }}
                    createdAt={QnAData.createdAt}
                />
            </AnswerContainer>
        );
    };

    const renderNoAnswer = () => {
        return (
            <NoAnswerContainer>
                <VerticalImageNContent
                    content={'아직 등록된 답변이 없어요\n조금만 기다려주세요!'}
                    fontStyle={{ color: '#acacac' }}
                    image="/img/myPage/noQuestion@3x.png"
                    imageWidth={92}
                    imageHeight={92}
                />
            </NoAnswerContainer>
        );
    };

    const renderPage = () => {
        if (QnAData) {
            return (
                <Container isAnswered={QnAData.answerContent === null ? false : true}>
                    <QuestionContainer>
                        <Title>{QnAData.inquiryTitle}</Title>
                        <QnAInfoByStatusInquiry
                            // questionId={QnAData.id}
                            status={QnAData.answerContent ? 1 : 0}
                            content={QnAData.inquiryContent}
                            showStatus={false}
                            style={{ padding: '0 20px' }}
                            createdAt={QnAData.createdAt}
                        />
                        {QnAData.imageURLs.length > 0 && <ImageCarousel imageArr={QnAData.imageURLs} />}
                    </QuestionContainer>
                    {QnAData.answerContent ? renderAnswer() : renderNoAnswer()}
                </Container>
            );
        }
        return <></>;
    };

    return renderPage();
};

const Container = styled.div<{ isAnswered: boolean }>`
    height: 100%;
    background-color: ${({ isAnswered }) => (isAnswered ? '#ffffff' : '#fbfbfb')};
`;

const QuestionContainer = styled.div`
    background-color: #ffffff;
    padding-bottom: 30px;
`;

const AnswerContainer = styled.div`
    background-color: #ffffff;
`;

const NoAnswerContainer = styled.div`
    padding: 60px 0;
    background-color: #fbfbfb;
`;

const Title = styled.div`
    padding: 0 20px 22px 20px;
    ${FontStyle(22, 30, '500', '#222222', -1.2)};
`;

export default MyPageAnswer;
