import React from 'react';
import styled, { keyframes } from 'styled-components';

import { ISlideProps } from '@/models';
import { FontStyle } from '@/utils/StyleUtil';
import SvgSelector from '../Svg/SvgSelector';
import { TABLET, RESIZED_SCREEN_TABLET } from '@/models/breakPoints';

function SlideModal({
    direction = 'Up',
    hasHeader = true,
    content,
    title,
    maxHeightRatio = 0.8,
    handleCloseModal = () => {},
    containerStyle,
    contentStyle,
    hasFixedButton = false,
    isBorderRound = true,
    modalHeight,
}: ISlideProps) {
    const renderHeader = () => {
        if (hasHeader) {
            if (typeof title === 'object') {
                return (
                    <>
                        <Header isAbsolute={true}>
                            <EmptyDivForJustify />
                            <EmptyDivForJustify />
                            <CloseIconContainer onClick={() => handleCloseModal()}>
                                <SvgSelector svg="close" width={18} height={18} strokeWidth={1.4} stroke="#222222" />
                            </CloseIconContainer>
                        </Header>
                        <TitleContainer>{title}</TitleContainer>
                    </>
                );
            }
            if (typeof title === 'string') {
                return (
                    <Header isAbsolute={false}>
                        <EmptyDivForJustify />
                        <div className="title">{title}</div>
                        <CloseIconContainer onClick={() => handleCloseModal()}>
                            <SvgSelector svg="close" width={18} height={18} strokeWidth={1.4} stroke="#222222" />
                        </CloseIconContainer>
                    </Header>
                );
            }
        }
        return undefined;
    };

    const renderContent = () => {
        return (
            <ContentContainer style={contentStyle} hasFixedButton={hasFixedButton}>
                {typeof content === 'function' ? content() : content}
            </ContentContainer>
        );
    };

    return (
        <Modal>
            <BackDropContainer onClick={() => handleCloseModal()} />
            <ModalContainer
                dir={direction}
                isBorderRound={isBorderRound}
                maxHeightRatio={maxHeightRatio}
                style={containerStyle}
                modalHeight={modalHeight}
            >
                {renderHeader()}
                {renderContent()}
            </ModalContainer>
        </Modal>
    );
}

const Modal = styled.div`
    ${({ theme }) => theme.flex.flexOnly}
    ${({ theme }) => theme.flex.justifyCenter}
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    @media (min-width: ${TABLET}px) {
        max-width: ${RESIZED_SCREEN_TABLET}px;
        overflow: hidden;
    }
`;

const BackDropContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
`;

const SlideAnimation = (dir: string) => {
    if (dir === 'Up') {
        return keyframes`
            0% { transform: translateY(100%) }
            100% { transform: translateY(0) }
        `;
    } else if (dir === 'Down') {
        return keyframes`
            0% { transform: translateY(-100%) }
            100% { transform: translateY(0) }
        `;
    } else if (dir === 'Right') {
        return keyframes`
            0% { transform: translateX(-100%) }
            100% { transform: translateX(0) }
        `;
    } else {
        return keyframes`
            0% { transform: translateX(100%) }
            100% { transform: translateX(0) }
        `;
    }
};

const setPosition = (dir: string) => {
    if (dir === 'Up') return { bottom: 0, left: 0, right: 0 };
    else if (dir === 'Down') return { top: 0, left: 0, right: 0 };
    else if (dir === 'Right') return { top: 0, bottom: 0, left: 0 };
    else return { top: 0, bottom: 0, right: 0 };
};

const setBorderRadius = (dir: string) => {
    if (dir === 'Up') return { borderTopLeftRadius: '30px', borderTopRightRadius: '30px' };
    else if (dir === 'Down') return { borderBottomLeftRadius: '30px', borderBottomRightRadius: '30px' };
    else if (dir === 'Right') return { borderTopRightRadius: '30px', borderBottomRightRadius: '30px' };
    else return { borderTopLeftRadius: '30px', borderBottomLeftRadius: '30px' };
};

const ModalContainer = styled.div<{
    dir: string;
    isBorderRound: boolean;
    maxHeightRatio: number;
    modalHeight?: number;
}>`
    ${({ theme }) => theme.flex.flexColumn};
    position: absolute;
    ${({ dir }) => setPosition(dir)};
    ${({ dir }) => (dir === 'Up' || dir === 'Down' ? 'min-height: 80px' : 'min-width: 80px')};
    ${({ dir, maxHeightRatio }) =>
        dir === 'Up' || dir === 'Down'
            ? `max-height: calc(100% * ${maxHeightRatio})`
            : `max-width: calc(100% * ${maxHeightRatio})`};
    animation: ${({ dir }) => SlideAnimation(dir)} 0.8s;
    background-color: #ffffff;
    ${({ dir, isBorderRound }) => isBorderRound && setBorderRadius(dir)};
    height: ${({ modalHeight }) => (modalHeight ? `${modalHeight}px` : 'auto')};
`;

const Header = styled.div<{ isAbsolute: boolean }>`
    ${({ theme }) => theme.flex.flexOnly}
    ${({ theme }) => theme.flex.justifyBetween};
    position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : undefined)};
    margin-top: 25px;
    padding: 0 30px;
    width: 100%;

    .title {
        ${FontStyle(16, 18, '500', '#222222', -1)};
    }
`;

const EmptyDivForJustify = styled.div`
    width: 28px;
`;

const CloseIconContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly}
    ${({ theme }) => theme.flex.justifyEnd}
    width: 28px;
    height: 28px;
`;

const TitleContainer = styled.div``;

const ContentContainer = styled.div<{ hasFixedButton: boolean }>`
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: ${({ hasFixedButton }) => (hasFixedButton ? '58px' : undefined)};
`;

export default React.memo(SlideModal);
