import React from 'react';
import styled from 'styled-components';

import { FontStyle } from '@/utils/StyleUtil';
import { IFullScreenProps } from '@/models';
import SvgSelector from '@/components/Svg/SvgSelector';
import { TABLET, RESIZED_SCREEN_TABLET } from '@/models/breakPoints';

const FullScreenModal = ({
    className,
    transparent = false,
    hasHeader = true,
    title,
    content,
    isCloseIconOnRight = true,
    handleCloseModal = () => {},
    containerStyle,
    contentStyle,
    hasFixedButton = false,
    headerStyle,
}: IFullScreenProps) => {
    const transparentBGColor = transparent ? 'rgba(0, 0, 0, 0.6)' : '#ffffff';
    const transparentColor = transparent ? '#ffffff' : '#222222';

    const renderHeader = () => {
        if (isCloseIconOnRight) {
            return (
                <Header style={headerStyle} isAbsolute={false} transparentColor={transparentColor}>
                    <EmptyDivForJustify />
                    {title ? <TitleContainer>{title}</TitleContainer> : <EmptyDivForJustify />}
                    <CloseIconContainer onClick={() => handleCloseModal()}>
                        <SvgSelector svg="close" width={18} height={18} strokeWidth={1.4} stroke={transparentColor} />
                    </CloseIconContainer>
                </Header>
            );
        }
        return (
            <Header isAbsolute={false} transparentColor={transparentColor}>
                <CloseIconContainer onClick={() => handleCloseModal()}>
                    <SvgSelector svg="arrow-back" width={28} height={28} strokeWidth={1.6} stroke={transparentColor} />
                </CloseIconContainer>
                {title ? <TitleContainer>{title}</TitleContainer> : <EmptyDivForJustify />}
                <EmptyDivForJustify />
            </Header>
        );
    };

    const renderContent = () => {
        return (
            <ContentContainer className={className} style={contentStyle} hasFixedButton={hasFixedButton}>
                {typeof content === 'function' ? content() : content}
            </ContentContainer>
        );
    };

    return (
        <Modal style={containerStyle} transparentBGColor={transparentBGColor}>
            {hasHeader && renderHeader()}
            {renderContent()}
        </Modal>
    );
};

const Modal = styled.div<{ transparentBGColor: string }>`
    ${({ theme }) => theme.flex.flexColumn};
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: ${({ transparentBGColor }) => transparentBGColor};
    @media (min-width: ${TABLET}px) {
        max-width: ${RESIZED_SCREEN_TABLET}px;
    }
`;

const Header = styled.div<{ isAbsolute: boolean; transparentColor: string }>`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyBetween};
    position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : undefined)};
    min-height: 56px;
    padding: 0 20px;
    width: 100%;
    .title {
        background-color: #ffffff;
        ${FontStyle(16, 18, '500', undefined, -1)};
        color: ${({ transparentColor }) => transparentColor};
    }
`;

const EmptyDivForJustify = styled.div`
    width: 28px;
    height: 28px;
`;

const CloseIconContainer = styled.button`
    ${({ theme }) => theme.flex.flexOnly}
    ${({ theme }) => theme.flex.justifyEnd}
    width: 28px;
    height: 28px;
`;

const TitleContainer = styled.div`
    ${FontStyle(16, 24, '400', '#222222', -1)}
`;

const ContentContainer = styled.div<{ hasFixedButton: boolean }>`
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: ${({ hasFixedButton }) => (hasFixedButton ? '58px' : undefined)};
    word-wrap: break-word;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export default FullScreenModal;
