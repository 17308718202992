import { atom, selector, selectorFamily } from 'recoil';

import {
    AC,
    DE,
    DM,
    IACInsuranceInput,
    IDEInsuranceInput,
    IDE_Plan,
    IDMInsuranceInput,
    IDM_Plan,
    IIM_Plan,
    IM,
    IMFETUS,
    INQUIRY,
    ISeniorInput,
    ITMInsuranceInput,
    ITM_Plan,
    TInsuranceType,
    TM,
    TMFETUS,
    TMSENIOR,
    TMSICK,
} from '@/models/plan';
import {
    TMUserInfoState,
    FetusUserInfoState,
    TMSickUerInfoState,
    TMPlanState,
    TMInsuranceInputState,
    TMInsuredPersonType,
} from './totalMedical';
import { sessionAtom } from '../_persistAtom';
import {
    InsuredFetus,
    InsuredPerson,
    MedicalInsuredPerson,
    defaultTMFetus,
    defaultTMNormal,
    defaultTMSick,
    defaultUser,
    inquiryUser,
} from '@/models/userInput';
import UserInfoUtil from '@/utils/plan/UserInfoUtil';
import PlanTypeGuard from '@/utils/plan/PlanTypeGuard';
import { THEME_COLORS, THEME_SUB_COLORS } from '@/styles/theme';
import { ACInsuranceInput, ACUserInfoState } from './accident';
import { DEInsuranceInput, DEUserInfoState, DEPlanState } from './death';
import { DMUserInfoState, DMPlanState, DMInsuranceInputState } from './dementia';
import { IMPlanState } from './indemnity';
import { IQUserInfoState } from './plannerInquiry';

export * from './accident';
export * from './death';
export * from './totalMedical';
export * from './dementia';
export * from './indemnity';

// TODO 네이밍 수정
export const SelectedIns = atom<TInsuranceType>({
    key: 'SelectedIns',
    default: 0,
    effects_UNSTABLE: [sessionAtom('session-insurance-type')],
});

export const insuranceTypeState = selector<TInsuranceType>({
    key: 'insuranceTypeState',
    get: ({ get }) => {
        const selIns = get(SelectedIns);
        const userType = get(TMInsuredPersonType);
        if (userType === 'N4' || userType === 'S2') return 110;
        return selIns;
    },
    set: ({ set }, newValue) => {
        set(SelectedIns, newValue);
    },
});

export const insuredPersonState = selector<InsuredPerson | MedicalInsuredPerson | InsuredFetus>({
    key: 'insuredPersonState',
    get: ({ get }) => {
        switch (get(SelectedIns)) {
            case TMFETUS:
            case IMFETUS:
                return get(FetusUserInfoState);
            case IM:
            case TM:
                return get(TMUserInfoState);
            case TMSICK:
                return get(TMSickUerInfoState);
            case AC:
                return get(ACUserInfoState);
            case DE:
                return get(DEUserInfoState);
            case DM:
                return get(DMUserInfoState);
            case INQUIRY:
                return get(IQUserInfoState);
            default:
                return defaultUser;
        }
    },
    set: ({ get, set }, newValue) => {
        switch (get(SelectedIns)) {
            case IMFETUS:
            case TMFETUS:
                if (UserInfoUtil.isFetus(newValue)) {
                    set(FetusUserInfoState, newValue);
                }
                break;
            case TMSICK:
                if (UserInfoUtil.isMedicalUser(newValue)) {
                    set(TMSickUerInfoState, newValue);
                }
                break;
            case IM:
            case TM:
                if (UserInfoUtil.isMedicalUser(newValue)) {
                    set(TMUserInfoState, newValue);
                }
                break;
            case AC:
                if (UserInfoUtil.isUser(newValue)) {
                    set(ACUserInfoState, newValue);
                }
                break;
            case DE:
                if (UserInfoUtil.isUser(newValue)) {
                    set(DEUserInfoState, newValue);
                }
                break;
            case DM:
                if (UserInfoUtil.isUser(newValue)) {
                    set(DMUserInfoState, newValue);
                }
                break;
            case INQUIRY:
                if (UserInfoUtil.isMedicalUser(newValue)) {
                    set(IQUserInfoState, newValue);
                }
                break;
            case 0:
                set(FetusUserInfoState, defaultTMFetus);
                set(TMSickUerInfoState, defaultTMSick);
                set(TMUserInfoState, defaultTMNormal);
                set(DEUserInfoState, defaultUser);
                set(DMUserInfoState, defaultUser);
                set(ACUserInfoState, defaultUser);
                set(IQUserInfoState, inquiryUser);
                break;
            default:
                break;
        }
    },
});

export const themeColorState = selectorFamily<string, TInsuranceType>({
    key: 'themeColorState',
    get: planType => () => {
        switch (planType) {
            case IM:
            case IMFETUS:
            case TMFETUS:
            case TM:
            case TMSICK:
            case TMSENIOR:
                return THEME_COLORS.medical;
            case AC:
                return THEME_COLORS.accident;
            case DE:
                return THEME_COLORS.death;
            case DM:
                return THEME_COLORS.dementia;
            default:
                return THEME_COLORS.default;
        }
    },
});

export const themeSubColorState = selectorFamily<string, TInsuranceType>({
    key: 'themeSubColorState',
    get: planType => () => {
        switch (planType) {
            case IM:
            case IMFETUS:
            case TMFETUS:
            case TM:
            case TMSICK:
            case TMSENIOR:
                return THEME_SUB_COLORS.medical;
            case AC:
                return THEME_SUB_COLORS.accident;
            case DE:
                return THEME_SUB_COLORS.death;
            case DM:
                return THEME_SUB_COLORS.dementia;
            default:
                return THEME_SUB_COLORS.default;
        }
    },
});

export const insuranceInputState = selectorFamily<
    IDEInsuranceInput | IACInsuranceInput | ITMInsuranceInput | ISeniorInput | IDMInsuranceInput,
    TInsuranceType
>({
    key: 'insuranceInput',
    get:
        planType =>
        ({ get }) => {
            const userType = get(TMInsuredPersonType);
            switch (planType) {
                case DE:
                    return get(DEInsuranceInput);
                case AC:
                    return get(ACInsuranceInput);
                case DM:
                    return get(DMInsuranceInputState);
                case TM:
                case TMFETUS:
                case TMSENIOR:
                case TMSICK:
                    return get(TMInsuranceInputState(userType));
                default:
                    return get(TMInsuranceInputState(userType));
            }
        },
    set:
        planType =>
        ({ get, set }, newValue) => {
            const userType = get(TMInsuredPersonType);
            switch (planType) {
                case AC:
                    set(ACInsuranceInput, newValue as IACInsuranceInput);
                    break;
                case DE:
                    set(DEInsuranceInput, newValue as IDEInsuranceInput);
                    break;
                case DM:
                    set(DMInsuranceInputState, newValue as IDMInsuranceInput);
                    break;
                case TM:
                case TMFETUS:
                case TMSENIOR:
                case TMSICK:
                    set(TMInsuranceInputState(userType), newValue as ITMInsuranceInput | ISeniorInput);
                    break;
                default:
                    break;
            }
        },
});

// TODO 직접 매칭하는 경우들(사고보장, 고령자 등등은 여기에 넣어야 하는가?)
export const planState = selectorFamily<ITM_Plan | IIM_Plan | IDE_Plan | IDM_Plan | undefined, TInsuranceType>({
    key: 'planState',
    get:
        insuranceType =>
        ({ get }) => {
            switch (insuranceType) {
                case DM:
                    return get(DMPlanState);
                case DE:
                    return get(DEPlanState);
                case TM:
                case TMFETUS:
                case TMSICK:
                    return get(TMPlanState);
                case IM:
                case IMFETUS:
                    return get(IMPlanState);
                default:
                    return undefined;
            }
        },
    set:
        insuranceType =>
        ({ set }, newValue) => {
            switch (insuranceType) {
                case DM:
                    if (PlanTypeGuard.isDMPlan(newValue)) {
                        set(DMPlanState, newValue);
                    }
                    break;
                case DE:
                    if (PlanTypeGuard.isDEPlan(newValue)) {
                        set(DEPlanState, newValue);
                    }
                    break;
                case TM:
                case TMFETUS:
                case TMSICK:
                    if (PlanTypeGuard.isTMPlan(newValue)) {
                        set(TMPlanState, newValue);
                    }
                    break;
                case IM:
                case IMFETUS:
                    if (PlanTypeGuard.isIMPlan(newValue)) {
                        set(IMPlanState, newValue);
                    }
                    break;
                default:
                    break;
            }
        },
});

export const insuranceList = atom<any>({
    key: 'insuranceList',
    default: [],
});

export const interestPlanParamsState = atom<string>({
    key: 'interestPlanParamsState',
    default: '',
});
