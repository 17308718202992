import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';

import { Page } from 'types/page';
import theme from '@/styles/theme';
import MetaLayout from '@/layouts/metaLayout';
import AppChecker from '@/layouts/provider/appChecker';
import PageChecker from '@/layouts/provider/pageChecker';
import TokenProvider from '@/layouts/provider/tokenProvider';
import DeepLinkInspector from '@/layouts/provider/DeepLinkInspector';
import ConnectSendbird from '@/layouts/provider/sendbird';
import MessageListener from '@/layouts/provider/messageListener';
import BackHandlerController from '@/layouts/provider/controlBackHandler';
import BackgroundScrollController from '@/layouts/provider/controlBackgroundScroll';
import ModalProvider from '@/components/Modal/RenderModal';
import DefaultToast from '@/components/Toast/Default';
import SEO from '@/containers/common/SEO/SEO';
import LoadingPage from '@/containers/common/loading/Page';
import '../styles/globals.scss';
import '../styles/fonts.scss';
import CampaignChecker from '@/layouts/provider/CampaignChecker';

type Props = AppProps & {
    Component: Page;
};

// react query init
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    },
});

// msw init
const mswEnabled = process.env.NEXT_PUBLIC_API_MOCKING === 'enabled';
if (mswEnabled) {
    import('@/utils/mocks');
}

function MyApp({ Component, pageProps }: Props) {
    // msw이 활성화 되어 있을 시 mock init에 필요한 시간을 주기 위한 코드
    const [loadForMsw, setLoadForMsw] = useState(!mswEnabled);

    useEffect(() => {
        if (mswEnabled)
            setTimeout(() => {
                setLoadForMsw(true);
            }, 1000);
    }, []);

    return (
        <>
            <SEO
                title={pageProps.title}
                description={pageProps.description}
                url={pageProps.url}
                image={pageProps.image}
            />
            <Script id="naver_ad_script_js" type="text/javascript" src="//wcs.naver.net/wcslog.js"></Script>
            <Script id="naver_ad_common_js" type="text/javascript" strategy="afterInteractive">
                {`
                    if(!wcs_add) var wcs_add = {};
                    var _nasa={};
                    window.wcs_add = {wa:'s_48e9ad60cf60'}
                    if (!_nasa) var _nasa={};
                    if(window.wcs){
                        wcs.inflow("picknplan.com");
                        wcs_do(_nasa);
                        var _nasa={};
                    }
                `}
            </Script>
            {!mswEnabled && (
                <Script
                    id="google-tag-manager"
                    type="text/javascript"
                    async
                    dangerouslySetInnerHTML={{
                        __html: `
                    (function(w, d, s, l, i) {
                    w[l] = w[l] || []
                    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
                    const f = d.getElementsByTagName(s)[0],
                      j = d.createElement(s),
                      dl = l != "dataLayer" ? "&l=" + l : ""
                    j.async = true
                    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
                    f.parentNode.insertBefore(j, f)
                  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLETAG}')`,
                    }}
                />
            )}
            <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
                strategy="afterInteractive"
            />
            <Script id="google-analytics" strategy="afterInteractive">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
                `}
            </Script>
            <Script id="dable_visit">
                {`
                    if (window.location.search.includes('from=news')) {
                        (function(d,a,b,l,e,_) {
                            d[b]=d[b]||function(){(d[b].q=d[b].q||[]).push(arguments)};e=a.createElement(l);
                            e.async=1;e.charset='utf-8';e.src='//static.dable.io/dist/dablena.min.js';
                            _=a.getElementsByTagName(l)[0];_.parentNode.insertBefore(e,_);
                            })(window,document,'dablena','script');
                            dablena('init', '001-009-179');
                            dablena('track', 'PageView');

                        window.sessionStorage.setItem('from', 'news');
                    }
                `}
            </Script>
            <RecoilRoot>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={theme}>
                        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
                        <LoadingPage />
                        <DefaultToast />
                        <ModalProvider />
                        <AppChecker />
                        <PageChecker />
                        <CampaignChecker />
                        <TokenProvider>
                            {loadForMsw ? <MetaLayout page={<Component {...pageProps} />} /> : undefined}
                        </TokenProvider>
                        <ConnectSendbird />
                        <DeepLinkInspector />
                        <MessageListener />
                        <BackHandlerController />
                        <BackgroundScrollController />
                    </ThemeProvider>
                </QueryClientProvider>
            </RecoilRoot>
        </>
    );
}

export default MyApp;

/*
 * _app은 서버로 요청이 들어왔을 때 가장 먼저 실행되는 컴포넌트로, 페이지에 적용할 공통 레이아웃의 역할을 함
 * _app은 로직, 전역 스타일 등 컴포넌트에 공통 데이터를 다룸
 */
