import { atom } from 'recoil';

import { IAllAlarm } from '@/models';

export const alarmArr = atom<IAllAlarm>({
    key: 'alarmArr',
    default: {
        data: [],
        metadata: {
            unreadCount: 0,
        },
    },
});
