import React from 'react';
import styled, { CSSProperties } from 'styled-components';

import { FontStyle } from '@/utils/StyleUtil';

interface IProps {
    className?: string;
    title: string;
    tags?: string[];
    subText?: string;
    titleStyle?: CSSProperties;
    subTextStyle?: CSSProperties;
    tagsStyle?: CSSProperties;
}

function TitleNAdditionalContents({ className, title, tags, subText, titleStyle, subTextStyle, tagsStyle }: IProps) {
    return (
        <Container className={className}>
            {tags && className !== 'renderQnATitle' && (
                <div className="tag_container">
                    {tags?.map((text, index) => (
                        <Tag style={tagsStyle} key={`${text}-${index}`}>
                            #{text}
                        </Tag>
                    ))}
                </div>
            )}
            <Title data-cy="title" style={titleStyle}>
                {title}
            </Title>
            {subText && (
                <SubText data-cy="subText" style={subTextStyle}>
                    {subText}
                </SubText>
            )}
            {tags && className === 'renderQnATitle' && (
                <div className="tag_container">
                    {tags?.map((text, index) => (
                        <Tag style={tagsStyle} key={`${text}-${index}`}>
                            #{text}
                        </Tag>
                    ))}
                </div>
            )}
        </Container>
    );
}

const Container = styled.div`
    .tag_container {
        ${({ theme }) => theme.flex.flexOnly};
        margin-top: 6px;
    }
`;

const Title = styled.h1`
    margin: 0;
    ${FontStyle(22, 30, '700', '#111111', -1)}
    white-space: pre-wrap;
`;

const SubText = styled.div`
    margin-top: 8px;
    ${FontStyle(14, 22, '400', '#acacac', -1)}
    white-space: pre-wrap;
`;

const Tag = styled.span`
    margin-bottom: 2px;
    margin-right: 8px;
    ${FontStyle(12, 20, '500', '#ACACAC', -1)}
`;

export default TitleNAdditionalContents;
