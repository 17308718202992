import React, { useRef, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import jwt_decode from 'jwt-decode';

import useMutationHook from '@/hooks/useMutationHook';
import useModal from '@/hooks/useModal';
import useToast from '@/hooks/useToast';
import AgreeTerms from '@/containers/modal/login/AgreeTerms';
import { useSocket } from '@/hooks/useSocket';
import useAlarmEvent from '@/hooks/useAlarmEvent';
import {
    uuidState,
    loginState,
    isLoginState,
    dummyUserState,
    isLoadingState,
    pushTokenState,
    groupProposalState,
} from '@/recoil';
import { cameraPermissionState } from '@/recoil/chat';
import { API, URLs } from '@/api';
import { sendbird } from '@/utils/Sendbird';
import { IJwt, IPlannerThreadPayload, PLANNER_THREAD, PageData } from '@/models';
import LogUtil from '@/utils/LogUtil';
import ErrorPage from '@/containers/common/error/Page';

interface IOAuth {
    platform: string;
    oAuthIdToken: string;
    platformId: string;
    email: string;
    name?: string;
    isApp: boolean;
}

const MessageListener = () => {
    const router = useRouter();
    const { addModal, removeCurrentModal, clearModalList } = useModal();
    const { openToast } = useToast();
    const { disconnectSocket, connectSocket } = useSocket();
    const alarmEvent = useAlarmEvent();

    const uuid = useRecoilValue(uuidState);
    const isLogin = useRecoilValue(isLoginState);
    const setIsGetGroupProposal = useSetRecoilState(groupProposalState);
    const setIsLoading = useSetRecoilState(isLoadingState);
    const setPushToken = useSetRecoilState(pushTokenState);
    const setCameraPermission = useSetRecoilState(cameraPermissionState);
    const [loginStatus, setLoginStatus] = useRecoilState(loginState);
    const [dummyUser, setDummyUser] = useRecoilState(dummyUserState);

    const [sendbirdError, setSendbirdError] = useState(false);

    const isLoginRef = useRef<boolean>(isLogin);

    const jsonChecker = (data: string) => {
        try {
            let o = JSON.parse(JSON.parse(data));

            // Handle non-exception-throwing cases:
            // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
            // but... JSON.parse(null) returns null, and typeof null === "object",
            // so we must check for that, too. Thankfully, null is falsey, so this suffices:
            if (o && typeof o === 'object') {
                return true;
            }
        } catch (e) {}

        return false;
    };

    const registeredUser = async (loginRes: any) => {
        if (window.ReactNativeWebView)
            window.ReactNativeWebView.postMessage(JSON.stringify({ clearAllPushNoti: true }));

        disconnectSocket();
        connectSocket(loginRes.tokenSet.accessToken, loginRes.tokenSet.refreshToken);

        setLoginStatus({
            userType: 'client',
            clientId: loginRes.clientId,
            name: loginRes.clientName,
            email: loginRes.email,
            designatedPlannerName: loginRes.recommendedPlannerName,
            designatedPlannerId: -1,
        });
        if (loginRes.groupProposalSubscribe) setIsGetGroupProposal(loginRes.groupProposalSubscribe);
        setIsLoading(false);

        const { isBanned } = jwt_decode(loginRes.tokenSet.accessToken) as IJwt;
        if (router.pathname !== '/ban' && isBanned) router.push('/ban');

        clearModalList();
    };

    const unRegisteredUser = (loginRes: any, name?: string) => {
        setDummyUser({
            ...dummyUser,
            clientId: loginStatus.clientId,
            platform: loginRes.platform,
            oAuthIdToken: loginRes.oAuthIdToken,
            platformId: loginRes?.platformId,
            email: loginRes?.email,
        });

        setIsLoading(false);

        addModal({
            key: 'fullScreen',
            props: {
                content: <AgreeTerms name={name} />,
                handleCloseModal: removeCurrentModal,
                isCloseIconOnRight: false,
            },
        });
    };

    const { mutate: tryLogin, errorPopUp } = useMutationHook(
        ['try-login'],
        async ({ platform, oAuthIdToken, platformId, email, name, isApp }: IOAuth) => {
            setIsLoading(true);

            let loginRes;

            if (platform === 'naver')
                loginRes = await API.post(URLs.auth.loginByOAuth, { platform, platformId, email, isApp });
            else loginRes = await API.post(URLs.auth.loginByOAuth, { platform, oAuthIdToken, isApp });

            if (platform === 'naver') {
                return {
                    loginRes,
                    platform,
                    oAuthIdToken,
                    platformId,
                    email,
                };
            } else if (platform === 'apple') {
                return {
                    loginRes,
                    platform,
                    oAuthIdToken,
                    name,
                };
            }
            return {
                loginRes,
                platform,
                oAuthIdToken,
            };
        },
        {
            onMutate: () => setIsLoading(true),
            onSuccess: async loginRes => {
                setIsLoading(true);

                if (loginRes.loginRes.isRegistered) {
                    registeredUser(loginRes.loginRes);

                    if (router.asPath === '/myPlan/interest-detail') router.push('/myPlan');
                } else {
                    if (loginRes.platform === 'apple') unRegisteredUser(loginRes, loginRes.name);
                    else unRegisteredUser(loginRes);
                }
            },
            onError: err => {
                setIsLoading(false);
                errorPopUp(err?.response?.status);
            },
        },
    );

    useEffect(() => {
        isLoginRef.current = isLogin;
    }, [isLogin]);

    const registFCMToken = async (data: { os: string; fcmToken: string; apnsToken: string }) => {
        const { os, fcmToken, apnsToken } = data;
        const capitalOS = os === 'android' ? 'Android' : os === 'ios' ? 'iOS' : 'Web';
        setPushToken({ platform: capitalOS, fcmToken, apnsToken });

        await API.post(URLs.noti.register, {
            deviceId: uuid,
            platform: capitalOS,
            fcmToken,
            apnsToken,
        });

        if (isLoginRef.current) sendbird.registTokenForFCM(capitalOS, fcmToken, apnsToken);
    };

    const moveToChatRoom = (data: { channelURL: string }) => {
        sendbird.getChannel(
            data.channelURL,
            (groupChannel: any) => {
                router.push('/chat').then(() => {
                    const channelData = JSON.parse(groupChannel.data);
                    const plannerInfo = channelData.plannerInfo;
                    const roomInfo = channelData.roomInfo;

                    router.push(
                        {
                            pathname: '/chat/room',
                            query: {
                                url: roomInfo.channelUrl,
                                plannerId: plannerInfo.id,
                                plannerName: plannerInfo.name,
                                plannerSex: plannerInfo.sex,
                                plannerAge: plannerInfo.age,
                                designRequestId: roomInfo.contentId,
                            },
                        },
                        '/chat/room',
                    );
                });
            },
            err => setSendbirdError(!!err),
            isLogin,
        );
    };

    const downloadStatus = (data: { downloadStatus: number }) => {
        if (data.downloadStatus !== 200) openToast('다운로드에 실패하였습니다');
        // if (data.downloadStatus === 200) openToast('저장되었습니다');
        // else openToast('다운로드에 실패하였습니다');
    };

    const pushAlarmClickEvent = async (data: any) => {
        alarmEvent(data.type, data.payload);
    };

    const { mutate: plannerThread } = useMutationHook(
        ['planner-qna-page'],
        async ({ payload }: { payload: IPlannerThreadPayload }) => {
            const res = await API.get(URLs.plannerInfo.fetchPlannerQnAPage(payload.threadId));
            return res;
        },
        {
            onSuccess: ({ metadata }: { metadata: PageData }, { payload }: { payload: IPlannerThreadPayload }) => {
                router.push(
                    {
                        pathname: `/planner/${payload.plannerId}`,
                        query: { isAlarm: true, page: metadata.page, threadId: payload.threadId },
                    },
                    `/planner/${payload.plannerId}`,
                );
            },
            onError: err => errorPopUp(err?.response?.status),
        },
    );

    const moveToProposalPage = (proposalId: number) => {
        router.push({ pathname: '/myPlan/proposal-explain', query: { proposalId } }, '/myPlan/proposal-explain');
    };

    const moveToLoginPage = (url: string) => {
        router.push(url);
    };

    const moveToSignPage = (os: string, platform: string) => {
        router.replace(`/login/signIn?platform=${platform}&os=${os}`);
    };

    const selectEvent = (message: any) => {
        const mData = message.data;
        const isJson = jsonChecker(mData);

        if (!isJson) {
            const randIdx = Math.floor(Math.random() * LogUtil.colors.length);
            // LogUtil.log(randIdx, `JSON catcher: `, mData);
        } else if (typeof message.data === 'string') {
            const data = JSON.parse(JSON.parse(message.data));

            if (process.env.NEXT_PUBLIC_LOGGING === 'logging') {
                const randIdx = Math.floor(Math.random() * LogUtil.colors.length);
                LogUtil.log(randIdx, `message from app: `, data);
            }

            if ('platform' in data) {
                if (data.platform === 'naver')
                    tryLogin({
                        platform: data.platform,
                        platformId: data.platformId,
                        email: data.email,
                        isApp: true,
                    });
                else if (data.platform === 'apple')
                    tryLogin({
                        platform: data.platform,
                        oAuthIdToken: data.oAuthIdToken,
                        name: data.name,
                        isApp: true,
                    });
                else tryLogin({ platform: data.platform, oAuthIdToken: data.oAuthIdToken, isApp: true });
            } else if ('os' in data) registFCMToken(data);
            else if ('channelURL' in data) moveToChatRoom(data);
            else if ('downloadStatus' in data) downloadStatus(data);
            else if ('cameraPermission' in data) setCameraPermission(data.cameraPermission);
            else if ('type' in data && data?.type === PLANNER_THREAD) plannerThread({ payload: data.payload });
            else if ('proposalId' in data) moveToProposalPage(Number(data.proposalId));
            else if ('loginTest' in data) moveToLoginPage(data.loginTest);
            else if ('loginOS' in data) {
                moveToSignPage(data.loginOS, data.platformOS);
            } else pushAlarmClickEvent(data);
        } else {
            // console.log('message from sendbird : ', message);
        }
    };

    useEffect(() => {
        window.addEventListener('message', selectEvent);
        document.addEventListener('message', selectEvent);

        return () => {
            window.removeEventListener('message', selectEvent);
            document.removeEventListener('message', selectEvent);
        };
    }, []);

    if (sendbirdError) return <ErrorPage />;

    return <></>;
};

export default MessageListener;
