import { useEffect } from 'react';

export default function DeepLinkInspector() {
    useEffect(() => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ getProposalId: true }));
        }
    }, []);

    return <></>;
}
