import { InsuredPerson } from '@/models/userInput';
import PlanCommonUtil from './PlanCommonUtil';
import { DateTime } from 'luxon';
import { IDE_Plan, TInsuranceType } from '@/models/plan';

const _birthMin = () => {
    const now = DateTime.local();
    const min = now.minus({ years: 70, months: 6 }).plus({ days: 1 }).toFormat('yyyyMMdd');
    return min;
};

const _birthMax = () => {
    const now = DateTime.local();
    const max = now.minus({ years: 15 }).toFormat('yyyyMMdd');
    return max;
};

const setErrorMsg = (value: string) => {
    // 1. 입력 형식이 맞는지
    if (!PlanCommonUtil._checkValidDate(value)) return '생년월일을 올바르게 입력해주세요';
    // 2. 기간이 맞는지
    const minRange = _birthMin();
    const maxRange = _birthMax();
    if (!PlanCommonUtil._checkDateRange(value, DateTime.fromISO(minRange), DateTime.fromISO(maxRange)))
        return '가입을 할 수 없는 나이입니다';
    return '';
};

const checkValid = (value: string) => {
    // 1. 입력 형식이 맞는지
    if (!PlanCommonUtil._checkValidDate(value)) return false;
    // 2. 기간이 맞는지
    const minRange = _birthMin();
    const maxRange = _birthMax();
    if (!PlanCommonUtil._checkDateRange(value, DateTime.fromISO(minRange), DateTime.fromISO(maxRange))) return false;
    return true;
};

const getListParams = (
    userInfo: any,
    subscriptionAmount: number,
    periods: { paymentPeriod: number; guaranteePeriod: number },
    iType: number,
) => {
    const { birth, sex, sick } = userInfo;
    const { paymentPeriod, guaranteePeriod } = periods;
    const insuranceAge = PlanCommonUtil._calculateInsuranceAge(birth);
    const westernAge = PlanCommonUtil._calculateWesternAge(birth);
    const params = {
        insuranceAge,
        westernAge,
        sex,
        sick: sick === 1,
        subscriptionAmount,
    };

    switch (iType) {
        case 0:
            return {
                ...params,
                productType: ['interestRate', 'interestRateRelated', 'interestRateFixed'],
                paymentPeriod: `Y${paymentPeriod}`,
                guaranteePeriod: `A${guaranteePeriod}`,
            };
        case 1:
            return {
                ...params,
                productType: ['unpaidableCancellationRefund', 'partialCancellationRefund'],
                paymentPeriod: `Y${paymentPeriod}`,
                guaranteePeriod: `A${guaranteePeriod}`,
            };
        case 2:
            return {
                ...params,
                productType: ['variable'],
                paymentPeriod: `Y${paymentPeriod}`,
                guaranteePeriod: `A${guaranteePeriod}`,
            };
    }
};

const getDEProposalParams = (userInfo: InsuredPerson, selectedProduct: IDE_Plan, selIns: TInsuranceType) => {
    const params = {
        insuranceInfo: { ...selectedProduct, insuranceType: selIns },
        insuredPersonInfo: { insuredPersonBirth: userInfo.birth, insuredPersonSex: userInfo.sex },
    };
    return params;
};

const getDEInsuredPersonInfo = (insuredPerson: InsuredPerson) => {
    const { birth: insuredPersonBirth, sex: insuredPersonSex } = insuredPerson;
    return { insuredPersonBirth, insuredPersonSex };
};

const getGuaranteePeriodList = (userInfo: InsuredPerson, currentTab: number, key: string) => {
    const insuranceAge = PlanCommonUtil._calculateInsuranceAge(userInfo.birth);
    if (key === 'string') {
        if (insuranceAge >= 61) {
            return ['평생'];
        } else {
            if (currentTab === 0) {
                return ['20년', '평생'];
            } else {
                return ['평생'];
            }
        }
    } else {
        if (insuranceAge >= 61) {
            return [999];
        } else {
            if (currentTab === 0) {
                return [20, 999];
            } else {
                return [999];
            }
        }
    }
};

const getPaymentPeriodList = <T>(userInfo: InsuredPerson, paymentList: T[], guaranteePeriod: number) => {
    const insuranceAge = PlanCommonUtil._calculateInsuranceAge(userInfo.birth);
    const westernAge = PlanCommonUtil._calculateWesternAge(userInfo.birth);

    const defaultList = [...paymentList];
    if (guaranteePeriod === 20) {
        return defaultList.slice(-1);
    }

    if (westernAge >= 15 && insuranceAge <= 60) return defaultList;
    if (insuranceAge >= 61 && insuranceAge <= 65) {
        return defaultList.slice(0, 2);
    }
    if (insuranceAge >= 66 && insuranceAge <= 70) {
        return defaultList.slice(0, 1);
    }
    return [];
};

export default {
    getListParams,
    setErrorMsg,
    checkValid,
    getDEProposalParams,
    getPaymentPeriodList,
    getGuaranteePeriodList,
    getDEInsuredPersonInfo,
};
