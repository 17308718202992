import React from 'react';
import styled from 'styled-components';

import { FontStyle } from '@/utils/StyleUtil';
import { IPopUpProps } from '@/models';
import { TABLET, RESIZED_SCREEN_TABLET } from '@/models/breakPoints';

function PopUpModal({
    type = 'default',
    content,
    onClickNo,
    onClickYes,
    onClickConfirm,
    onClickSubmit,
    handleCloseModal,
    containerStyle,
    contentStyle,
    noText = '취소',
    yesText = '확인',
}: IPopUpProps) {
    const onClickButton = (onClickFuntion?: Function) => {
        if (onClickFuntion) onClickFuntion();
    };

    const _renderButton = (type: string) => {
        switch (type) {
            case 'noYes':
                return (
                    <FlexContainer>
                        <NoButton onClick={() => onClickButton(onClickNo)}>{noText}</NoButton>
                        <Divider />
                        <YesButton onClick={() => onClickButton(onClickYes)}>{yesText}</YesButton>
                    </FlexContainer>
                );
            case 'confirm':
                return (
                    <FlexContainer>
                        <OneButton onClick={() => onClickButton(onClickConfirm)}>확인</OneButton>
                    </FlexContainer>
                );

            case 'submit':
                return (
                    <FlexContainer>
                        <OneButton onClick={() => onClickButton(onClickSubmit)}>제출하기</OneButton>
                    </FlexContainer>
                );
            default:
                return undefined;
        }
    };

    return (
        <Modal>
            <BackDropContainer onClick={() => onClickButton(handleCloseModal)} />
            <ModalContainer style={containerStyle}>
                <ContentContainer style={contentStyle}>
                    {typeof content === 'function' ? content() : content}
                </ContentContainer>
                {_renderButton(type)}
            </ModalContainer>
        </Modal>
    );
}

const Modal = styled.div`
    ${({ theme }) => theme.flex.flexOnly}
    ${({ theme }) => theme.flex.justifyCenter}
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    @media (min-width: ${TABLET}px) {
        max-width: ${RESIZED_SCREEN_TABLET}px;
    }
`;

const BackDropContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
`;

const ModalContainer = styled.div`
    position: absolute;
    background-color: #ffffff;
    border-radius: 8px;
    white-space: pre-wrap;
    text-align: center;
    min-width: 240px;
    max-height: 80%;
    overflow-y: auto;
    ${FontStyle(14, 22, '400', '#444444', -1)};
`;

const ContentContainer = styled.div`
    padding: 28px 23px;
    min-height: 122px;
    ${({ theme }) => theme.flex.flexOnly}
    ${({ theme }) => theme.flex.justifyCenter}
`;

const FlexContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly}
    border-top: 1px solid #eeeeee;
`;

const Button = styled.div`
    ${FontStyle(14, 22, '500', '#111111', -1)}
    flex: 1;
    padding: 12px 0;
    background-color: #ffffff;
    border: none;
`;

const OneButton = styled(Button)`
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

const NoButton = styled(Button)`
    border-bottom-left-radius: 8px;
    color: #888888;
`;

const YesButton = styled(Button)`
    border-bottom-right-radius: 8px;
`;

const Divider = styled.div`
    width: 1px;
    background-color: #eeeeee;
`;

export default PopUpModal;
