import { io, Socket } from 'socket.io-client';

import { IAllAlarm, IAlarmItem } from '@/models';
import { API, URLs } from '@/api';

const socketURL = `${process.env.NEXT_PUBLIC_SOCKET_URL}/notification/client`;

interface ServerToClientEvents {
    // previously notification:all\
    'notification:list': (_notification: IAllAlarm) => void;
    'notification:single': (_payload: IAlarmItem) => void;
    'proposal:summary': (callback: (message: any) => void) => void;
    'proposal:detail': (callback: (message: any) => void) => void;
}

interface ClientToServerEvents {
    // previously status
    'notification:list': ({ page }: { page: number }) => void;
    // previously bookmark
    'notification:check': () => void;
}

class SocketWrapper {
    private instance!: Socket<ServerToClientEvents, ClientToServerEvents>;

    checkConnected = () => {
        if (this.instance) {
            const isConnect = this.instance.on('connect', () => {});

            if (isConnect.connected) return true;
            else return false;
        } else return false;
    };

    connect = () => {
        this.instance = io(socketURL, {
            path: '/socket.io/notification/client',
            auth: { accessToken: localStorage.getItem('accessToken') },
            transports: ['websocket', 'polling'],
        });
    };

    disconnect = () => {
        this.instance.disconnect();
    };

    connectError = (refreshToken: string) => {
        this.instance.on('connect_error', async () => {
            this.disconnect();

            await API.post(URLs.auth.refresh, { refreshToken }).then((res: any) => {
                localStorage.setItem('accessToken', res.accessToken);
                localStorage.setItem('refreshToken', res.refreshToken);

                this.instance.auth = { accessToken: res.accessToken };
                this.instance.connect();
            });
        });
    };

    // status = (page: number) => {
    //     this.instance.emit('notification:list', { page });
    // };

    // bookmark = () => {
    //     this.instance.emit('notification:check');
    // };

    initialNotification = (notificationCallback: (_notification: IAllAlarm) => void) => {
        this.instance.on('notification:list', (notification: IAllAlarm) => {
            notificationCallback(notification);
        });
    };

    notification = (notificationCallback: (_payload: IAlarmItem) => void) => {
        this.instance.on('notification:single', (payload: IAlarmItem) => {
            notificationCallback(payload);
        });
    };

    proposalSummary = (setData: Function) => {
        if (this.checkConnected()) {
            this.instance.on('proposal:summary', (message: any) => {
                setData((prev: any) => {
                    const newArr = prev?.map((item: any) =>
                        item.designRequestId === message.designRequestId
                            ? { ...item, proposalInfo: message.proposalData }
                            : item,
                    );
                    return newArr;
                });
            });
        }
    };

    proposalSummaryDisconnect = () => {
        if (this.instance) {
            this.instance.off('proposal:summary');
        }
    };

    proposalDetailDisconnect = () => {
        if (this.instance) {
            this.instance.off('proposal:detail');
        }
    };

    proposalDetail = (setData: Function, designRequestId: number) => {
        if (this.checkConnected()) {
            this.instance.on('proposal:detail', (message: any) => {
                const idx = message.data.findIndex(
                    (v: { designRequestId: number }) => v.designRequestId === designRequestId,
                );
                if (idx !== -1) setData(message.data);
            });
        }
    };
}

export const socket = new SocketWrapper();
