import { TM, IM, TMFETUS, TMSICK, DE, DM, TInsuranceType } from './plan';

export interface PromotionInsurance {
    image: string;
    prefixLabel: string;
    suffixLabel: string;
    code: TInsuranceType;
    href: string;
}

export const INSURANCE_LIST: {
    image: string;
    prefixLabel: string;
    suffixLabel: string;
    code: TInsuranceType;
    href: string;
}[] = [
    {
        image: '/img/plan/total_medical_icon@3x.png',
        prefixLabel: '#암보험 #뇌심혈관질환보험',
        suffixLabel: '종합 건강보험',
        code: TM,
        href: '/plan/user-info',
    },
    {
        image: '/img/plan/indemnity_medical_icon@3x.png',
        prefixLabel: '#실손의료비 #실비 ',
        suffixLabel: '실손 의료비보험',
        code: IM,
        href: '/plan/indemnity-type',
    },
    {
        image: '/img/plan/fetus_medical_icon@3x.png',
        prefixLabel: '#태아보험 #산모의료비',
        suffixLabel: '태아보험',
        code: TMFETUS,
        href: '/plan/user-info',
    },
    {
        image: '/img/plan/sick_medical_icon@3x.png',
        prefixLabel: '#병이있어도 #간편가입',
        suffixLabel: '유병자보험',
        code: TMSICK,
        href: '/plan/user-info',
    },
    {
        image: '/img/plan/death_medical_icon@3x.png',
        prefixLabel: '#사망보험 #종신보험 #정기보험 #변액',
        suffixLabel: '사망보험',
        code: DE,
        href: '/plan/user-info',
    },
    {
        image: '/img/plan/demetia_medical_icon@3x.png',
        prefixLabel: '#간병보험 #치매보험',
        suffixLabel: '치매보험',
        code: DM,
        href: '/plan/user-info',
    },
];
