import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';

import useQueryHook from '@/hooks/useQueryHook';
import SvgSelector from '@/components/Svg/SvgSelector';
import { isLoadingState } from '@/recoil';
import { API, URLs } from '@/api';
import { FontStyle } from '@/utils/StyleUtil';
import { TABLET, RESIZED_SCREEN_TABLET } from '@/models/breakPoints';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ pdfId }: { pdfId?: number }) => {
    const setIsLoading = useSetRecoilState(isLoadingState);
    const [pdfUrl, setPdfUrl] = useState('');
    const [numPages, setNumPages] = useState(0);
    const [isLoadingContent, setIsLoadingContent] = useState(true);

    const { refetch } = useQueryHook(
        [`pdf-url-${pdfId}`],
        async () => {
            setIsLoading(true);

            if (pdfId) {
                return API.get(URLs.download.contractFile(pdfId)).then(data => {
                    const url = data as unknown as string;
                    return API.fileDownload(URLs.temp.downloadFile(encodeURIComponent(url)), {}, 'blob');
                });
            }
        },
        {
            onSuccess: (salt: string) => {
                setIsLoading(true);
                setIsLoadingContent(false);
                setPdfUrl(salt);
            },
            onError: err => {
                console.log('error occurs when calling pdf : ', err);
                setIsLoadingContent(false);
            },
            enabled: false,
            cacheTime: 0,
            staleTime: 0,
        },
    );

    useEffect(() => {
        refetch();
    }, [pdfId]);

    if (isLoadingContent) {
        return <></>;
    } else {
        if (pdfUrl) {
            return (
                <TransformWrapper limitToBounds={false}>
                    <TransformComponent>
                        <Document
                            file={pdfUrl}
                            loading={() => {
                                return <div />;
                            }}
                            onLoadSuccess={({ numPages }: { numPages: number }) => setNumPages(numPages)}
                        >
                            {Array.from(new Array(numPages), (_, index) => (
                                <Page
                                    width={window.innerWidth >= TABLET ? RESIZED_SCREEN_TABLET : window.innerWidth}
                                    height={window.innerHeight}
                                    key={index}
                                    pageNumber={index + 1}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                    loading={() => {
                                        return <div />;
                                    }}
                                    onLoadSuccess={() => {
                                        if (index === numPages - 1) setIsLoading(false);
                                    }}
                                />
                            ))}
                        </Document>
                    </TransformComponent>
                </TransformWrapper>
            );
        }
        return (
            <LoadingErrorContainer>
                <SvgSelector
                    svg="round-exclamation-mark-circle"
                    width={24}
                    height={24}
                    stroke="#c8c8c8"
                    strokeWidth={1.6}
                />
                <div>내용을 불러오지 못했습니다</div>
            </LoadingErrorContainer>
        );
    }
};

const LoadingErrorContainer = styled.div`
    ${({ theme }) => theme.flex.flexColumn};
    ${({ theme }) => theme.flex.justifyCenter};
    gap: 4px;
    margin-top: 100px;
    ${FontStyle(14, 22, '400', '#acacac', -1)};
`;

export default PdfViewer;
