import React, { useState } from 'react';
import Image from 'next/image';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';

import SvgSelector from '@/components/Svg/SvgSelector';
import { TABLET, RESIZED_SCREEN_TABLET } from '@/models/breakPoints';

interface IProps {
    imageArr: string[];
    currentTab: number;
    handleTab: Function;
    onClickEnlarger: Function;
}

interface IImgLayout {
    width?: number;
    height?: number;
    layout: 'fill' | 'responsive';
}

const CarouselWithEnlarger = ({ imageArr, currentTab, handleTab, onClickEnlarger }: IProps) => {
    const setOriginSizeArr = () => {
        const arr = [];

        for (let i = 0; i < imageArr.length; i += 1) arr.push({ width: 1, height: 1 });

        return arr;
    };

    const [originImageSize, setOriginImageSize] = useState(setOriginSizeArr());

    const setLayout = (index: number): IImgLayout => {
        const width = originImageSize[index].width;
        const height = originImageSize[index].height;

        if (width > height)
            return {
                layout: 'fill',
            };
        else
            return {
                width,
                height,
                layout: 'responsive',
            };
    };

    return (
        <Carousel
            swipeable
            emulateTouch
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            showThumbs={false}
            onChange={e => {
                handleTab(e);
            }}
            selectedItem={currentTab}
        >
            {imageArr.map((item: string, index: number) => {
                return (
                    <EachImageContainer key={item}>
                        <img
                            src={item}
                            alt={`image_${index}`}
                            // {...setLayout(index)}
                            // layout="fill"
                            // onLoad={e => {
                            //     const tmp = [...originImageSize];
                            //     const imageWidth = (e.target as HTMLImageElement).naturalWidth;

                            //     tmp[index].width = imageWidth > 1280 ? RESIZED_SCREEN_TABLET : imageWidth;
                            //     tmp[index].height = (e.target as HTMLImageElement).naturalHeight;
                            //     setOriginImageSize([...tmp]);
                            // }}
                            // objectFit="contain"
                        />
                        <EnlargerContainer onClick={() => onClickEnlarger()}>
                            <SvgSelector
                                svg="enlarge"
                                width="32px"
                                height="32px"
                                fill="#222222"
                                stroke="#ffffff"
                                strokeWidth={1.6}
                            />
                        </EnlargerContainer>
                    </EachImageContainer>
                );
            })}
        </Carousel>
    );
};

const EachImageContainer = styled.div`
    height: calc(100vw - 40px);
    @media (min-width: ${TABLET}px) {
        height: calc(${RESIZED_SCREEN_TABLET}px - 40px);
    }
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const EnlargerContainer = styled.div`
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
`;

export default CarouselWithEnlarger;
