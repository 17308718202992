import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import useQueryHook from '@/hooks/useQueryHook';
import useModal from '@/hooks/useModal';
import MovingFocusInput from '@/components/InputForm/MovingFocusInput';
import { typedUserInfoState, checkErrorState } from '@/recoil';
import { API, URLs } from '@/api';
import { FontStyle } from '@/utils/StyleUtil';

const DesignatedPlanner = () => {
    const { addModal, removeCurrentModal } = useModal();
    const [userInfo, setUserInfo] = useRecoilState(typedUserInfoState);
    const isCheckError = useSetRecoilState(checkErrorState);
    const [inputBorder, setInputBorder] = useState('#dadada');

    const onClickClose = () => {
        removeCurrentModal();
        isCheckError(true);
    };

    const { refetch: checkPlanner, RenderError } = useQueryHook(
        ['planner-info-click-enroll'],
        async () => {
            return await API.get(URLs.plannerInfo.plannerInfo(Number(userInfo.code)));
        },
        {
            onSuccess: plannerInfo => {
                if (plannerInfo.name)
                    addModal({
                        key: 'popUp',
                        props: {
                            type: 'noYes',
                            content: (
                                <>
                                    <PlannerName>{plannerInfo.name}</PlannerName> 플래너가 맞나요?
                                </>
                            ),
                            handleCloseModal: onClickClose,
                            noText: '아니오',
                            onClickNo: () => {
                                setUserInfo({ ...userInfo, code: '' });
                                onClickClose();
                            },
                            yesText: '예',
                            onClickYes: () => {
                                setUserInfo({ ...userInfo, designatedCode: userInfo.code });
                                onClickClose();
                            },
                        },
                    });
                else
                    addModal({
                        key: 'popUp',
                        props: {
                            type: 'confirm',
                            content: '입력하신 코드를 다시 확인해 주세요',
                            handleCloseModal: onClickClose,
                            onClickConfirm: onClickClose,
                        },
                    });
            },
            onError: () => {
                addModal({
                    key: 'popUp',
                    props: {
                        type: 'confirm',
                        content: <>{RenderError}</>,
                        handleCloseModal: onClickClose,
                        onClickConfirm: onClickClose,
                    },
                });
            },
            enabled: false,
        },
    );

    return (
        <DesignatedPlannerContainer id="planner">
            <Title>지정 플래너 등록 (선택)</Title>
            <TypingContainer>
                <InfoTyping
                    placeholder="추천인 코드 (7자리 숫자)"
                    inputMode="numeric"
                    value={userInfo.code}
                    maxLength={7}
                    onChange={(e: any) => setUserInfo({ ...userInfo, code: e.target.value })}
                    onFocus={() => setInputBorder('#444444')}
                    onBlur={() => setInputBorder('#dadada')}
                    style={{ borderBottomColor: inputBorder }}
                    disabled={userInfo.designatedCode.length > 0}
                    isMoving={true}
                />
                <EnrollButton
                    isEnrolled={userInfo.designatedCode.length > 0}
                    onClick={() => {
                        if (!userInfo.designatedCode) checkPlanner();
                    }}
                >
                    확인
                </EnrollButton>
            </TypingContainer>
        </DesignatedPlannerContainer>
    );
};

const DesignatedPlannerContainer = styled.div`
    margin-top: 30px;
`;

const Title = styled.div`
    ${FontStyle(14, 22, '500', '#686868', -1)};
`;

const TypingContainer = styled.div`
    position: relative;
`;

const InfoTyping = styled(MovingFocusInput)`
    width: 100%;
    height: 44px;
    border: none;
    border-bottom: 1px solid #dadada;
    outline: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    margin-top: 10px;
    padding: 9px 0;
    ${FontStyle(18, 26, '400', '#444444', -1)};

    ::placeholder {
        color: #c8c8c8;
    }
    :disabled {
        background-color: #ffffff;
    }
`;

const EnrollButton = styled.div<{ isEnrolled: boolean }>`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyCenter};
    position: absolute;
    top: 17px;
    right: 0;
    width: 62px;
    height: 28px;
    border: 1px solid #0ec4cf;
    border-radius: 22px;
    ${FontStyle(12, 18, '500', undefined, -1)};
    color: ${({ isEnrolled }) => (isEnrolled ? '#c8c8c8' : '#0ec4cf')};
    background-color: ${({ isEnrolled }) => (isEnrolled ? '#f3f3f3' : '#ffffff')};
    border-color: ${({ isEnrolled }) => (isEnrolled ? '#f3f3f3' : '#0ec4cf')};
`;

const PlannerName = styled.div`
    ${FontStyle(14, 22, '500', '#444444', -1)};
`;

export default DesignatedPlanner;
