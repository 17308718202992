import useMutationHook from '@/hooks/useMutationHook';

import { uuidState } from '@/recoil';
import { useRecoilValue } from 'recoil';

import { API, URLs } from '@/api';
import RegistGuest from '@/queries/common/registGuest';

const DeleteGuest = () => {
    const uuid = useRecoilValue(uuidState);
    const { registGuest } = RegistGuest();

    const { mutate: deleteGuest, errorPopUp } = useMutationHook(
        ['delete-guest'],
        async () => {
            return await API.delete(URLs.auth.deleteFaker, { deviceId: uuid });
        },
        {
            onSuccess: () => {
                // DOUBLECHECK THIS CHANGE BEFORE YOU REGRET!!!
                // console.log('After deleting, register again');
                // registGuest({});
            },
            onError: err => errorPopUp(err?.response?.status),
        },
    );

    return { deleteGuest };
};

export default DeleteGuest;
