import React, { CSSProperties } from 'react';

export type TIcons =
    | 'arrow-back'
    | 'check-circle'
    | 'check'
    | 'chevron-down'
    | 'chevron-right'
    | 'chevron-up'
    | 'close-circle'
    | 'close'
    | 'minus-circle'
    | 'more-vert'
    | 'plus-circle'
    | 'plus'
    | 'search'
    | 'send'
    | 'heart'
    | 'accuse'
    | 'writing'
    | 'exclamation-mark-circle'
    | 'filled-explanation-circle'
    | 'speech-bubble'
    | 'note'
    | 'enlarge'
    | 'msg-send'
    | 'download'
    | 'alarm-on'
    | 'alarm-off'
    | 'trash'
    | 'block'
    | 'exit'
    | 'star-left-side'
    | 'star-right-side'
    | 'complete'
    | 'circle-question-mark'
    | 'eye-on'
    | 'eye-off'
    | 'filled-triangle'
    | 'round-exclamation-mark-circle'
    | 'pagination-left'
    | 'pagination-right'
    | 'down-arrow'
    | 'trapezoid_middle'
    | 'trapezoid_last'
    | 'logo_gray'
    | 'pension-cat'
    | 'pet-coverage'
    | 'sick-pet'
    | 'ina-cat'
    | 'ina-answer-complete'
    | 'ina-answer-expired'
    | 'ina-answer-waiting'
    | 'event/clover'
    | 'event/candy_yellow'
    | 'event/candy_orange'
    | 'event/candy_skyblue'
    | 'event/candy_rainbow'
    | 'event/speech_balloon'
    | 'intro-ponder'
    | 'intro-question-mark-left'
    | 'intro-question-mark-right';

interface IProps {
    className?: string;
    svg: TIcons;
    width?: string | number;
    height?: string | number;
    stroke?: string;
    strokeWidth?: number;
    fill?: string;
    style?: CSSProperties;
}

function SvgSelector({
    className,
    svg,
    width,
    height,
    stroke = 'black',
    strokeWidth = 1,
    fill = stroke,
    style,
}: IProps) {
    const Selector = require(`/public/svg/${svg}.svg`).default;

    return (
        <Selector
            className={className}
            data-cy={svg}
            width={width}
            height={height}
            stroke={stroke}
            fill={fill}
            strokeWidth={strokeWidth}
            style={style}
        />
    );
}

export default SvgSelector;
