import { useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { modalState } from '@/recoil';

const BackgroundControl = () => {
    const modalList = useRecoilValue(modalState);
    const scrollPosition = useRef<number>(0);
    const isPositionFixed = useRef<boolean>(false);

    useEffect(() => {
        if (modalList.length === 1) {
            document.body.style.overflow = 'hidden';
        }

        if (modalList.length === 2) {
            scrollPosition.current = window.scrollY;
            isPositionFixed.current = true;

            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPosition.current}`;
            document.body.style.left = '0';
            document.body.style.right = '0';
        }

        if (modalList.length === 0) {
            document.body.style.removeProperty('overflow');

            if (isPositionFixed.current) {
                document.body.style.removeProperty('position');
                document.body.style.removeProperty('top');
                document.body.style.removeProperty('left');
                document.body.style.removeProperty('right');

                window.scrollTo(0, scrollPosition.current);
                isPositionFixed.current = false;
            }
        }
    }, [modalList]);

    return <></>;
};

export default BackgroundControl;
