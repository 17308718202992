const pushURL = process.env.NEXT_PUBLIC_PUSH_URL;
const picknURL = process.env.NEXT_PUBLIC_PICKN_URL;

const comprehensiveURL =
    process.env.NEXT_PUBLIC_API_MOCKING === 'enabled'
        ? process.env.NEXT_PUBLIC_URL
        : process.env.NEXT_PUBLIC_COMPREHENSIVE_URL;
const deathURL =
    process.env.NEXT_PUBLIC_API_MOCKING === 'enabled' ? process.env.NEXT_PUBLIC_URL : process.env.NEXT_PUBLIC_DEATH_URL;
const dementiaURL =
    process.env.NEXT_PUBLIC_API_MOCKING === 'enabled'
        ? process.env.NEXT_PUBLIC_URL
        : process.env.NEXT_PUBLIC_DEMENTIA_URL;

// const mswUrl = process.env.NEXT_PUBLIC_URL;

export const URLs = {
    mswTest: {
        todos: `/todos`,
    },
    ina: {
        questionList: `${picknURL}/subject_client/ina/question/list`,
        question: (questionId?: number) =>
            questionId !== undefined
                ? `${picknURL}/subject_client/ina/question/detail/${questionId}`
                : `${picknURL}/subject_client/ina/question`,

        postQuestion: `${picknURL}/subject_client/ina/question`,
        editQuestion: (questionId?: number) => `${picknURL}/subject_client/ina/question/detail/${questionId}`,
        myQuestion: (questionId: number) => `${picknURL}/subject_client/ina/question/detail/my/${questionId}`,
        myQuestionList: `${picknURL}/subject_client/ina/question/list/my`,
        today: `${picknURL}/subject_client/ina/question/list/my/today`,
        search: `${picknURL}/subject_client/ina/question/list/search`,
        like: (answerId: number) => `${picknURL}/subject_client/ina/answer/detail/${answerId}/like`,
        unlike: (answerId: number) => `${picknURL}/subject_client/ina/answer/detail/${answerId}/unlike`,
        plannerInfo: (answerId: number) => `${picknURL}/subject_client/ina/answer/detail/${answerId}/planner_Info`,

        inaFile: (questionId: number, fileName: string) =>
            `${picknURL}/subject_client/ina/question/detail/${questionId}/file/${fileName}`,
        taggedQuestionList: `${picknURL}/subject_client/ina/spread`,
        thumbnail: (questionId?: number) => `${picknURL}/subject_client/ina/question/detail/${questionId}/thumbnail`,
    },
    plan: {
        comprehensive: `${comprehensiveURL}/comprehensive`,
        indemnity: `${comprehensiveURL}/indemnity`,
        death: `${deathURL}/subject_client/insurance/death`,
        // deathStatistics: (expenseType: string) => `${deathURL}/subject_client/statistics/expense/${expenseType}`,
        dementiaList: `${dementiaURL}/product/list`,
        interest: `${picknURL}/subject_client/interest`,
        designRequest: `${picknURL}/subject_client/design_request`,
        fetusSex: `${comprehensiveURL}/comprehensive/additional_coverage`,
    },
    myPlan: {
        proposal: `${picknURL}/subject_client/design_request/list`,
        interest: `${picknURL}/subject_client/interest/list`,
        insuranceDetail: (requestId: number) => `${picknURL}/subject_client/design_request/detail/${requestId}`,
        proposalStatus: (requestId: number) => `${picknURL}/subject_client/design_request/detail/${requestId}/proposal`,
        proposalDetail: (proposalId: number) => `${picknURL}/subject_client/proposal/${proposalId}`,
        plannerInfo: (proposalId: number) => `${picknURL}/subject_client/proposal/${proposalId}/planner_info`,
        readProposal: (proposalId: number) => `${picknURL}/subject_client/proposal/${proposalId}/read`,
        interestDetail: (interestId: number) => `${picknURL}/subject_client/interest/detail/${interestId}`,
        interestBulk: `${picknURL}/subject_client/interest/bulk`,
        rematch: `${picknURL}/subject_client/design_request/rematch`,
        cancelProposal: `${picknURL}/subject_client/design_request/matching`,
    },
    myPage: {
        inquiryList: `${picknURL}/subject_client/inquiry/list`,
        inquiryDetail: (inquiryId: number) => `${picknURL}/subject_client/inquiry/detail/${inquiryId}`,
        inquiry: `${picknURL}/subject_client/inquiry`,
        faqCategoryList: `${picknURL}/subject_client/faq/category`,
        freqQuestion: (categoryId: number) => `${picknURL}/subject_client/faq/list/${categoryId}`,
        notice: `${picknURL}/subject_client/notice/list`,
        service: `${picknURL}/subject_client/my_page/service`,
        privacy: `${picknURL}/subject_client/my_page/privacy`,
    },
    auth: {
        checkFaker: `${picknURL}/subject_faker/auth/check`,
        registerGuest: `${picknURL}/subject_faker/auth/register`,
        deleteFaker: `${picknURL}/subject_faker/auth/clear`,
        refresh: `${picknURL}/subject_client/auth/refresh`,
        loginByOAuth: `${picknURL}/subject_client/auth/login_by_oauth`,
        logout: `${picknURL}/subject_client/auth/logout`,
        dropout: `${picknURL}/subject_client/auth/dropout`,
        loginUrl: (oAuthPlatform: string) => `${picknURL}/subject_client/auth/authorization_url/${oAuthPlatform}`,
        loginByNewOauth: (oAuthPlatform: string) => `${picknURL}/subject_client/auth/login/${oAuthPlatform}`,
    },

    clientInfo: {
        clientInfo: `${picknURL}/subject_client/client_info`,
        clientInfoUsingOAuth: `${picknURL}/subject_client/auth/oauth`,
        profileImage: `${picknURL}/subject_client/client_info/profile_image/`,
        designatedPlannerCode: `${picknURL}/subject_client/client_info/recommended_planner_code`,
        recommendedPlanerHist: `${picknURL}/subject_client/client_info/recommended_planner_hist`,
        signUp: (oAuthPlatform: string) => `${picknURL}/subject_client/auth/sign_up/${oAuthPlatform}`,
    },
    plannerInfo: {
        plannerInfoPublic: (plannerId: number) => `${picknURL}/subject_client/planner_info/${plannerId}/public`,
        recommendedPlannerInfo: `${picknURL}/subject_client/planner_info/recommended_planner`,
        plannerInfo: (plannerCode: number) =>
            `${picknURL}/subject_client/planner_info/recommended_planner/${plannerCode}`,
        plannerDetailInfo: (plannerId: number | string) => `${picknURL}/subject_client/planner_info/${plannerId}`,
        plannerInA: (plannerId: number) => `${picknURL}/subject_client/ina/answer/list/${plannerId}`,
        fetchPlannerQnA: (plannerId: number) => `${picknURL}/subject_client/planner_thread/list/${plannerId}`,
        createPlannerQnA: `${picknURL}/subject_client/planner_thread`,
        updatePlannerQnA: `${picknURL}/subject_client/planner_thread`,
        deletePlannerQna: `${picknURL}/subject_client/planner_thread/hide`,
        createPlannerThreadComment: `${picknURL}/subject_client/planner_thread/comment`,
        fetchPlannerThreadComment: (threadId: number) =>
            `${picknURL}/subject_client/planner_thread/list/comment/${threadId}`,
        deletePlannerThreadComment: `${picknURL}/subject_client/planner_thread/comment/hide`,
        fetchPlannerQnAPage: (threadId: number) => `${picknURL}/subject_client/planner_thread/meta/${threadId}`,
    },
    contract: {
        consentPersonalInfoUse: `${picknURL}/subject_client/contract/list/consent_personal_info_use`,
        termsOfService: `${picknURL}/subject_client/contract/list/terms_of_service`,
        privacyPolicy: `${picknURL}/subject_client/contract/list/privacy_policy`,
    },
    temp: {
        preview: (salt: string) => `${picknURL}/subject_temporary/file/${salt}`,
        checkTemp: (salt: string) => `${picknURL}/subject_temporary/file/${salt}`,
        getFileUrl: (hashKey: string) => `${picknURL}/subject_temporary/file/${hashKey}`,
        downloadFile: (hashKey: string) => `${picknURL}/subject_temporary/file/download/${hashKey}`,
        uploadFile: `${picknURL}/subject_temporary/file`,
        uploadImage: `${picknURL}/subject_temporary/file/generate_upload_url`,
    },
    chat: {
        chatroomInfo: (channelUrl: number | string) => `${picknURL}/subject_client/chatting/detail/${channelUrl}`,
        initUsingProposalId: (proposalId: number) => `${picknURL}/subject_client/chatting/${proposalId}/proposal`,
        initUsingPlannerId: (plannerId: number) => `${picknURL}/subject_client/chatting/${plannerId}/planner`,
        score: (channelUrl: string) => `${picknURL}/subject_client/chatting/score/${channelUrl}`,
        notiKakao: (channelUrl: string) => `${picknURL}/subject_client/chatting/notiKakaoInitChat/${channelUrl}`,
    },
    noti: {
        register: `${pushURL}/register`,
        alarmList: `${pushURL}/subscription_list`,
        adAlarm: `${pushURL}/subscribe/ad`,
        proposalAlarm: `${pushURL}/subscribe/proposal`,
        qnaAlarm: `${pushURL}/subscribe/answer`,
        threadAlarm: `${pushURL}/subscribe/thread`,
        emailAlarm: `${pushURL}/subscribe/email`,
        kakaoAlarm: `${pushURL}/subscribe/kakao`,
        notificationList: `${picknURL}/subject_client/notification/list`,
        notificationCheck: `${picknURL}/subject_client/notification/check`,
    },
    report: {
        report: `${picknURL}/subject_client/report`,
    },
    clickEvent: {
        log: `${picknURL}/subject_client/click_event_log`,
    },
    download: {
        profileImage: `${picknURL}/subject_client/client_info/profile_image/`,
        contractFile: (contractId: number) => `${picknURL}/subject_client/contract/file/${contractId}`,
        inaFile: (questionId: number, fileName: string) =>
            `${picknURL}/subject_client/ina/question/detail/${questionId}/file/${fileName}`,
        inquiryFile: (inquiryId: number, fileName: string) =>
            `${picknURL}/subject_client/inquiry/detail/${inquiryId}/file/${fileName}`,
        informFile: (insuranceType: string, fileName: string) =>
            `${picknURL}/subject_client/insurance/inform/${insuranceType}/detail/${fileName}`,
        proposalFile: (proposalId: number, fileName: string) =>
            `${picknURL}/subject_client/proposal/${proposalId}/file/${fileName}`,
    },
    event: {
        state: `${picknURL}/event`,
        draw: `${picknURL}/event/draw`,
        ticket: `${picknURL}/event/ticket`,
        verification: `${picknURL}/event/verification`,
        verificationUrl: `${picknURL}/event/verification/url`,
    },
};
