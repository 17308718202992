import { ReactNode } from 'react';

export interface IProfileHeader {
    highlightText: string;
    subText: string;
    icon: string | ReactNode;
}

export const defaultProfileHeader = { highlightText: '로그인', subText: '해 주세요', icon: ':)' };

export interface IMenu {
    key: string;
    label: string;
}

export const firstArea: IMenu[] = [
    {
        key: 'oneOnOne',
        label: '앱 이용 관련 문의',
    },
    {
        key: 'myQuestion',
        label: '나의 문의내역',
    },
];

export const secondArea: IMenu[] = [
    {
        key: 'event',
        label: '이벤트',
    },
    {
        key: 'notice',
        label: '공지사항',
    },
    {
        key: 'freqQuestion',
        label: '자주 하는 질문',
    },
    {
        key: 'setting',
        label: '앱 설정',
    },
];

export const thirdArea_Logout: IMenu[] = [
    {
        key: 'terms',
        label: '서비스 이용약관',
    },
    {
        key: 'policy',
        label: '개인정보 처리방침',
    },
];

export const thirdArea_LogIn: IMenu[] = [
    {
        key: 'terms',
        label: '서비스 이용약관',
    },
    {
        key: 'policy',
        label: '개인정보 처리방침',
    },
    {
        key: 'logout',
        label: '로그아웃',
    },
];

export const DEFAULT_PROFILE = '/img/profile_image/nonMember@3x.png';

interface IQuestion {
    id: number;
    category: string;
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
}

export interface IFreqQuestion {
    category: string;
    list: IQuestion[];
}
