import React from 'react';
import Image from 'next/image';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { profileState } from '@/recoil';
import { noProfileState } from '@/models';
import { FontStyle } from '@/utils/StyleUtil';

const SelectProfile = () => {
    const [profile, setProfile] = useRecoilState(profileState);

    const onClickDeleteProfile = () => {
        setProfile(noProfileState);
    };

    const uploadToClient = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            setProfile({ needUpdate: true, profileURL: URL.createObjectURL(file), profileImage: file });
        } else setProfile(noProfileState);
    };

    return (
        <>
            <TitleWrapper>
                <Title>프로필 이미지 선택</Title>
                <DeleteProfile onClick={onClickDeleteProfile}>프로필 사진 삭제</DeleteProfile>
            </TitleWrapper>
            <ImageInputContainer>
                <ImageContainer htmlFor="file_upload">
                    <Image
                        src={profile.profileURL}
                        alt="profile image"
                        width="60px"
                        height="60px"
                        style={{ borderRadius: '60px' }}
                    />
                    <div className="modify_position">
                        <Image src="/img/common/modify@3x.png" alt="modify icon" width="22px" height="22px" />
                    </div>
                </ImageContainer>
                <input type="file" id="file_upload" accept="image/*" onChange={e => uploadToClient(e)} />
            </ImageInputContainer>
        </>
    );
};

const TitleWrapper = styled.span`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyBetween};
    align-items: center;
    margin-top: 30px;
`;

const Title = styled.span`
    ${FontStyle(14, 22, '500', '#686868', -1)};
`;

const DeleteProfile = styled.span`
    ${FontStyle(12, 20, '500', '#ACACAC', -1.2)};
    text-decoration: underline;
`;

const ImageInputContainer = styled.div`
    margin-top: 16px;

    input[type='file'] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }
`;

const ImageContainer = styled.label`
    .modify_position {
        margin: -20px 0 0 40px;
    }
`;

export default SelectProfile;
