const colors = {
    primary: '#0ec4cf',
    second: '#0089b5',
    medical: '#4F88F6',
    death: '#11be80',
    silver: '#ff7426',
    accident: '#D9397D',
    dementia: '#4f88f6',
    price: '#ff1e1e',
    error: '#ff1e1e',
};

export const THEME_COLORS = {
    medical: '#4F88F6',
    death: '#11BE80',
    dementia: '#4F88F6',
    accident: '#CD0D5F',
    default: '#0EC4CF',
};

export const THEME_SUB_COLORS = {
    medical: '#F0F8FF',
    death: '#EDF9F5',
    dementia: '#F0F8FF',
    accident: '#FFDFED',
    default: '#F0FBFC',
};

const flex = {
    flexOnly: `
    display: flex;
    `,
    flexColumn: `
    display: flex;
    flex-direction: column;
    `,
    justifyCenter: `
    justify-content: center;
    align-items: center;
    `,
    justifyBetween: `
    justify-content: space-between;
    align-items: center;
    `,
    justifyAround: `
    justify-content: space-around;
    align-items: center;
    `,
    justifyStart: `
    justify-content: flex-start;
    align-items: center;
    `,
    justifyEnd: `
    justify-content: flex-end;
    align-items: center;
    `,
};

const padding = {
    contentsPadding: 20,
    modalPadding: 30,
};

const settingByBrowser = {
    hideScrollBarX: `
    overflow-x: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
    `,
    lineClamp: (line: number | undefined) => `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${line};
    line-clamp: ${line};
    -webkit-box-orient: vertical;
    word-break: break-word;
    `,
};

const pageTheme = () => {
    if (typeof window !== 'undefined') {
        const pageCategory = window.location.pathname.split('/')[1];

        if (pageCategory === 'plan') {
            return colors.medical;
        } else if (pageCategory === 'death') {
            return colors.death;
        } else if (pageCategory === 'dementia') {
            return colors.dementia;
        }
    }
};

const theme = {
    colors,
    flex,
    settingByBrowser,
    padding,
    pageTheme,
};

export type Colors = typeof colors;
export type Flex = typeof flex;
export type SettingByBrowser = typeof settingByBrowser;
export type Padding = typeof padding;
export type PageTheme = typeof pageTheme;

export default theme;
