export interface IInAList {
    allList: QuestionList[];
    insuranceClaimList: QuestionList[];
    productInquiryList: QuestionList[];
    guaranteeAnalysisList: QuestionList[];
    diseaseInquiryList: QuestionList[];
    etcInquiryList: QuestionList[];
}

export interface QuestionList {
    id: number;
    clientId: number;
    title: string;
    content: string;
    category: number[];
    answerCount: number;
    status: number;
    isBlind: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface QuestionDetail extends QuestionList {
    files: string[];
}

export interface IAnswer {
    id: number;
    questionId: number;
    likes: number[];
    content: string;
    createdAt: string;
    updatedAt: string;
    isBlind: boolean;
    plannerSummary: PlannerSummary | null;
    advConfirmationCode: string;
    advConfirmationEffectiveDateStartedAt: Date;
    advConfirmationEffectiveDateExpiredAt: Date;
}

export interface PlannerSummary {
    id: number;
    companyNumber: string;
    associationNumber: string;
    name: string;
    age: number;
    sex: number;
    introduce: string;
    tier: number;
    headPosition: number;
    headName: string;
    organizationName: string;
    teamName: string;
}

export const QNA_STATUS = {
    WAITING: 0,
    COMPLETE: 1,
    UNANSWERED: 2,
    ANSWER: 3,
};

export const ORDER = ['최신순', '답변많은순'];

export const CATEGORY_LIST = ['보험금청구', '상품문의', '보장분석', '병력문의', '기타문의'];

export interface IPlannerInA {
    question: QuestionList;
    answer: IAnswer;
}

export const CONFIRMATION_EFFECTIVEDATE_GUIDE_TEXT = [
    '본 광고는 광고 심의기준을 준수하였으며, 유효기간은 심의일로부터\n1년입니다.',
    '답변은 설계사의 의견이며, 이익 및 손실은 계약자 등에게 귀속됩니다.',
    '상품별로 상이하니 해당 약관을 참조 바랍니다.',
    '상품별로 성별, 연령, 직업에 따라 보험료, 담보, 가입 금액은 상이할 수\n있습니다.',
];
