import { TInsuranceType } from './plan';

interface IProps {
    createdAt: string;
    insuredPersonSex?: number;
    insuredPersonBirth?: string;
    // userType: string;
    insuranceType: number;
    proposalInfo: {
        acceptCnt: number;
        writingCnt: number;
        completeCnt: number;
    };
}

export interface ITMProps extends IProps {
    companyName: string;
    productName: string;
    premium: { normal: { total: number } };
}

export interface ISeniorInsurance extends IProps {
    premium: number;
}

export interface IInsuredFetusInfo {
    expectedBirthDate: string;
    motherBirth: string;
    motherRisk: number;
}

export interface IInsuredPersonInfo {
    insuredPersonSex: number;
    insuredPersonBirth: string;
    insuredPersonRisk?: number;
    insuredPersonType?: string;
}

export interface IProposalStatus {
    proposalId: number;
    plannerId: number;
    proposalStatus: number;
    clientRead: boolean;
    plannerInfo: IPlannerInfo;
}

export const ProposalStatus = {
    PENDING: 100, // 수락도 거절도 안 된 상태
    DECLINE: 110, // 수락 거절
    ACCEPT: 200, // 수락 상태
    WRITING: 300, // 작성 중
    COMPLETE: 400, // 제출 완료
    PENDING_TIMEOUT: 101, // Pending 상태 시간 초과 (PENDING -> PENDING_TIMEOUT)
    ACCEPT_TIMEOUT: 201, // Accept 받고 미제출 상태로 시간 초과 (ACCEPT -> ACCEPT_TIMEOUT)
    WRITING_TIMEOUT: 301, // Writing 상태로 들어가서 미제출 상태로 시간 초과 (WRTING -> WRITING_TIMEOUT)
};

export interface IPlannerInfo {
    id: number;
    introduce: string;
    name: string;
    age: number;
    sex: number;
    tier: number;
    indemnityCert: boolean;
    lifeCert: boolean;
    variableCert: boolean;
    likes: number;
}

export interface IPlannerDetailInfo {
    age: number;
    award: {
        isCertifiedPlanner: boolean;
        certifiedPeriod: number;
        customerService: boolean;
        inaProfessional: boolean;
        highlyReview: boolean;
    };
    companyNumber: string;
    consumerLawAgreement: boolean;
    id: number;
    indemnityCert: boolean;
    headPosition: number;
    lifeCert: boolean;
    variableCert: boolean;
    introduce: string;
    introduceDetail?: string;
    likes: number;
    locationCode: number;
    masterPermission: number;
    minAcceptablePremium: number;
    name: string;
    registrationAgreement: boolean;
    sex: number;
    subscribe: boolean;
    tier: number;
    phoneNumber: string | null;
    sns: { kakao: string | null; blog: string | null; youtube: string | null };
}

export interface IProposalData {
    id: number;
    plannerId: number;
    content: string;
    files: string[];
}

export interface ITM_Direct {
    insuranceType: TInsuranceType;
    cancer: number;
    brain: number;
    heart: number;
    liver?: number;
    lung?: number;
    kidney?: number;
    priority: number[];
}

export interface IDE_Direct {
    insuranceType: TInsuranceType;
    subscriptionAmount: number;
}

export interface IDM_Direct {
    insuranceType: TInsuranceType;
    livingExpense: number;
}

export const MatchingType = {
    NORMAL: 0,
    ONETOONE: 1,
    NOPRODUCT: 2,
    PLANNERINQUIRY: 3,
};

export interface IProposalCard {
    designRequestId: number;
    createdAt: string;
    insuranceInfo: any;
    insuredPersonInfo: any;
    isCancelled: boolean;
    isPlannerDropped: boolean;
    matchingType: number;
    proposalInfo: {
        acceptCnt: number;
        writingCnt: number;
        completeCnt: number;
    };
}
