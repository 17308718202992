import { atom, selector, selectorFamily } from 'recoil';

import { insuredPersonState } from '.';
import TotalMedicalUtil from '@/utils/plan/TotalMedicalUtil';
import {
    defaultTMChildInput,
    defaultTMFetusInput,
    defaultTMInput,
    defaultTMSeniorInput,
    IDISEASE_ITEM,
    IGuarantee,
    ISeniorInput,
    ITMInsuranceInput,
} from '@/models/plan';
import { ITM_Plan, TM_MAJOR_DISEASE } from '@/models/plan';
import { defaultTMFetus, defaultTMNormal, defaultTMSick, InsuredFetus, MedicalInsuredPerson } from '@/models/userInput';

// ** TM_Normal ** //
export const TMUserInfoState = atom<MedicalInsuredPerson>({
    key: 'TMUserInfoState',
    default: defaultTMNormal,
});

// ** TM_Fetus, IM_Fetus ** //
export const FetusUserInfoState = atom<InsuredFetus>({
    key: 'FetusUserInfoState',
    default: defaultTMFetus,
});

// ** TM_Sick ** //
export const TMSickUerInfoState = atom<MedicalInsuredPerson>({
    key: 'TMSickUerInfoState',
    default: defaultTMSick,
});

// ** TM Insurance ** //
export const TMInsuredPersonType = selector({
    key: 'TMInsuredPersonType',
    get: ({ get }) => {
        const userInfo = get(insuredPersonState);
        return TotalMedicalUtil.getUserCode(userInfo as MedicalInsuredPerson | InsuredFetus);
    },
});

const TMInsInputState = atom<ITMInsuranceInput>({
    key: 'TMInsuranceInput',
    default: defaultTMInput,
});

const TMFetusInsInputState = atom<ITMInsuranceInput>({
    key: 'TMFetusInsInput',
    default: defaultTMFetusInput,
});

const TMChildInsInputState = atom<ITMInsuranceInput>({
    key: 'TMChildInsInputState',
    default: defaultTMChildInput,
});

const TMSeniorInsInputState = atom<ISeniorInput>({
    key: 'TMSeniorInsInput',
    default: defaultTMSeniorInput,
});

export const TMInsuranceInputState = selectorFamily<ITMInsuranceInput | ISeniorInput, string>({
    key: 'TMInsuranceInputState',
    get:
        userType =>
        ({ get }) => {
            switch (userType) {
                case 'F':
                    return get(TMFetusInsInputState);
                case 'N0':
                    return get(TMChildInsInputState);
                case 'N4':
                case 'S2':
                    return get(TMSeniorInsInputState);
                default:
                    return get(TMInsInputState);
            }
        },
    set:
        userType =>
        ({ set }, newValue) => {
            switch (userType) {
                case 'F':
                    if (TotalMedicalUtil.isTMInsuranceInput(newValue)) {
                        set(TMFetusInsInputState, newValue);
                    }
                    break;
                case 'N0':
                    if (TotalMedicalUtil.isTMInsuranceInput(newValue)) {
                        set(TMChildInsInputState, newValue);
                    }
                    break;
                case 'N4':
                case 'S2':
                    if (TotalMedicalUtil.isSeniorInsInput(newValue)) {
                        set(TMSeniorInsInputState, newValue);
                    }
                    break;
                case 'Q':
                    set(TMFetusInsInputState, defaultTMFetusInput);
                    set(TMChildInsInputState, defaultTMChildInput);
                    set(TMInsInputState, defaultTMInput);
                    set(TMSeniorInsInputState, defaultTMSeniorInput);
                    break;
                default:
                    if (TotalMedicalUtil.isTMInsuranceInput(newValue)) {
                        set(TMInsInputState, newValue);
                    }
            }
        },
});

export const majorGuaranteeLimitState = selectorFamily<number, string>({
    key: 'majorGuaranteeLimit',
    get: userType => () => {
        switch (userType) {
            case 'F':
            case 'N0':
            case 'S0':
            case 'S1':
                return 1;
            case 'N4':
            case 'S2':
                return 6;
            default:
                return 2;
        }
    },
});

export const majorDiseasesState = selectorFamily<IDISEASE_ITEM[], string>({
    key: 'majorDiseasesState',
    get: userType => () => {
        switch (userType) {
            case 'F':
            case 'N0':
            case 'S1':
            case 'S0':
                return TM_MAJOR_DISEASE.filter((_, index) => index < 3);
            case 'N1':
            case 'N2':
            case 'N3':
            case 'N4':
            case 'S2':
                return TM_MAJOR_DISEASE;
            default:
                return [];
        }
    },
});

export const TMListTabState = atom<number>({
    key: 'TMListTabState',
    default: 1,
});

// ** SERVER DATA **
export const TMPlanState = atom<ITM_Plan | undefined>({
    key: 'TMPlanState',
    default: undefined,
});

// additional server data
export const TMAdditionalCoverageBeforeBirthState = selector<
    | {
          injury: IGuarantee[];
          disease: IGuarantee[];
          injuryDaily: IGuarantee[];
          diseaseDaily: IGuarantee[];
      }
    | undefined
>({
    key: 'TMAdditionalCoverageBeforeBirthState',
    get: ({ get }) => {
        return get(TMPlanState)?.coverage?.additional?.injuryDisease?.beforeBirth;
    },
});

export const TMAdditionalCoverageAfterBirthState = selector<
    | {
          injury: IGuarantee[];
          disease: IGuarantee[];
          injuryDaily: IGuarantee[];
          diseaseDaily: IGuarantee[];
      }
    | undefined
>({
    key: 'TMAdditionalCoverageAfterBirthState',
    get: ({ get }) => {
        return get(TMPlanState)?.coverage?.additional?.injuryDisease?.afterBirth;
    },
});
