import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import { isLoadingState } from '@/recoil';
import { TABLET, RESIZED_SCREEN_TABLET } from '@/models/breakPoints';

function LoadingPage() {
    const isLoading = useRecoilValue(isLoadingState);

    if (isLoading)
        return (
            <LoadingContainer>
                <Image src={'/img/common/loading_white.gif'} alt="loading" width="50px" height="50px" />
            </LoadingContainer>
        );
    return <></>;
}

const LoadingContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyCenter};
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.6);
    @media (min-width: ${TABLET}px) {
        max-width: ${RESIZED_SCREEN_TABLET}px;
    }
`;

export default React.memo(LoadingPage);
