import { useRecoilValue, useSetRecoilState } from 'recoil';

import { API, URLs } from '@/api';
import { loginState, promotionPlannerState } from '@/recoil';
import useMutationHook from '../useMutationHook';

const useSettingDesignatedPlanner = (submitProposal?: Function) => {
    const promotionPlanner = useRecoilValue(promotionPlannerState);
    const setLoginInfo = useSetRecoilState(loginState);

    const { mutate: onClickButton, errorPopUp } = useMutationHook(
        ['change-designatedPlanner'],
        async () => {
            const res = await API.post(URLs.clientInfo.designatedPlannerCode, {
                recommendedPlannerCode: promotionPlanner?.companyNumber,
            });
            return res;
        },
        {
            onSuccess: () => {
                setLoginInfo(prev => ({
                    ...prev,
                    designatedPlannerName: promotionPlanner?.name ?? '',
                    designatedPlannerId: promotionPlanner?.id ?? 0,
                }));
                if (submitProposal) submitProposal();
            },
            onError: err => errorPopUp(err?.response?.status),
        },
    );

    return { onClickButton };
};

export default useSettingDesignatedPlanner;
