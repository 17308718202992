import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';

import PopUpModal from './PopUp';
import SlideModal from './Slide';
import FullScreenModal from './FullScreen';
import { modalState } from '@/recoil';
import { isPopUp, isSlide, isFullScreen } from '@/models';

const Modal: FC = () => {
    const modalList = useRecoilValue(modalState);

    return (
        <div>
            {modalList.map(({ key, props }, index: number) => {
                if (isPopUp(key, props)) return <PopUpModal {...props} key={key + String(index)} />;
                if (isSlide(key, props)) return <SlideModal {...props} key={key + String(index)} />;
                if (isFullScreen(key, props)) return <FullScreenModal {...props} key={key + String(index)} />;
                return undefined;
            })}
        </div>
    );
};

export default React.memo(Modal);
