import { TInsuranceType } from '.';

type TACITEMS = 'car' | 'driver' | 'accident' | 'travel' | 'pet' | 'etc';
type TTraffic = 'personal' | 'official' | 'noneDriving';

export const ACCIDENT_ITEMS: { label: string; key: TACITEMS; activeImg: string; inactiveImg: string }[] = [
    {
        label: '자동차보험',
        key: 'car',
        activeImg: '/img/plan/accident/car_active@3x.png',
        inactiveImg: '/img/plan/accident/car_inactive@3x.png',
    },
    {
        label: '운전자보험',
        key: 'driver',
        activeImg: '/img/plan/accident/driver_active@3x.png',
        inactiveImg: '/img/plan/accident/driver_inactive@3x.png',
    },
    {
        label: '화재/사고보험',
        key: 'accident',
        activeImg: '/img/plan/accident/accident_active@3x.png',
        inactiveImg: '/img/plan/accident/accident_inactive@3x.png',
    },
    {
        label: '여행자보험',
        key: 'travel',
        activeImg: '/img/plan/accident/travel_active@3x.png',
        inactiveImg: '/img/plan/accident/travel_inactive@3x.png',
    },
    {
        label: '펫보험',
        key: 'pet',
        activeImg: '/img/plan/accident/pet_active@3x.png',
        inactiveImg: '/img/plan/accident/pet_inactive@3x.png',
    },
    {
        label: '연금(저축)보험',
        key: 'etc',
        activeImg: '/img/plan/accident/pension_active.png',
        inactiveImg: '/img/plan/accident/pension_inactive.png',
    },
];

export const ACCIDENT_GUIDE = [
    [
        {
            title: '의무보험',
            img: {
                src: '/img/plan/accident/insurance@3x.png',
                alt: '의무보험',
            },
            subText: [
                {
                    parent: '자동차 사고로 다른 사람을 죽거나\n다치게 한 경우, 다른 사람의 차량이나 재물을 파손한 경우 보장',
                    child: ['대인배상 I', '대물배상 2,000만원'],
                },
            ],
        },
        {
            title: '임의보험',
            img: {
                src: '/img/plan/accident/insurance@3x.png',
                alt: '임의보험',
            },
            subText: [
                {
                    parent: '의무 보험에서 보장하는 것 외에\n추가로 보장이 더 필요한 경우 보장',
                    child: ['대인배상 II', '대물배상 2,000만원 초과 분', '자기신체사고, 자기차량 손해 등'],
                },
            ],
        },
    ],
    [
        {
            title: '교통사고 치료비',
            img: {
                src: '/img/plan/accident/accident@3x.png',
                alt: '교통사고 치료비',
            },
            subText: [
                {
                    parent: '운전 중 사고로 인한 신체적 상해에\n대한 보장',
                    child: ['자동차사고 부상 치료비', '골절진단비/화상진단비', '교통상해입원일당 등'],
                },
            ],
        },
        {
            title: '교통사고 벌금/법률 비용',
            img: {
                src: '/img/plan/accident/car_accident@3x.png',
                alt: '교통사고 벌금/법률 비용',
            },
            subText: [
                {
                    parent: '운전 중 사고로 발생하는 형사적\n책임 비용 보장',
                    child: ['자동차사고 처리 지원금', '자동차사고 벌금', '벌금/변호사 선임 비용 등'],
                },
            ],
        },
    ],
    [
        {
            title: '일상생활사고',
            img: {
                src: '/img/plan/accident/accident@3x.png',
                alt: '일상생활사고',
            },
            subText: [
                {
                    parent: '일상생활 중 발생하는 상해사고에\n대한 보장',
                    child: ['일상생활배상책임', '상해수술비, 상해입원일당', '골절진단비/화상진단비 등'],
                },
            ],
        },
        {
            title: '주택/건물 화재',
            img: {
                src: '/img/plan/accident/building@3x.png',
                alt: '주택/건물 화재',
            },
            subText: [
                {
                    parent: '화재로 인한 손해 및 배상책임이\n발생할 경우에 대한 보장',
                    child: ['주택화재의 직·간접손해', '벌금 및 배상책임', '도난 및 누수에 의한 손해배상 등 '],
                },
            ],
        },
    ],
    [
        {
            title: '여행 중 의료비·배상책임',
            img: {
                src: '/img/plan/accident/travel@3x.png',
                alt: '여행 중 의료비·배상책임',
            },
            subText: [
                {
                    parent: '여행 중 발생할 수 있는 상해 또는 질병\n손해에 대한 보장',
                    child: [
                        '여행 중 의료비(상해/질병)',
                        '여행 중 배상책임',
                        '휴대폰 손해 (분실 제외)',
                        '(해외)항공기 및 수화물 지연 비용 등',
                    ],
                },
            ],
        },
    ],
    [
        {
            title: '반려동물 의료비·배상책임',
            img: {
                src: '/img/plan/accident/pet@3x.png',
                alt: '반려동물 의료비·배상책임',
            },
            subText: [
                {
                    parent: '반려동물의 질병 또는 상해로 인한\n의료비 및 배상책임 보장',
                    child: ['반려 동물의 입/통원 의료비', '반려동물 배상책임(반려견)', '반려동물 사망위로금 등'],
                },
            ],
        },
    ],
];

export interface IAC_Plan {
    insuranceType: TInsuranceType;
    premium: number;
    selectedGuarantee: {
        car: boolean; // 자동차보험
        driver: TTraffic | false; // 운전자보험
        accident: boolean; // 화재/사고보험
        travel: boolean; // 여행자보험
        pet: boolean; // 펫보험
        etc: boolean; // 기타보험
    };
}

export const defaultAccidentPlan = {
    priority: [],
    driver: undefined,
    selectedItem: '',
};

export interface IACInsuranceInput {
    priority: number[];
    driver?: number;
    selectedItem: string;
}
