import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { hasAlertSelector, alarmArr, isLoginState } from '@/recoil';
import { socket } from '@/utils/notification/Socket';
import { IAlarmItem, IAllAlarm, NOTICE } from '@/models';
import { GetInfiniteNotificationList } from '@/queries/myPage/notification/infiniteNotification';
import { GetNotificationList } from '@/queries/myPage/notification/notification';
import { API, URLs } from '@/api';
import { useRouter } from 'next/router';

export const useSocket = () => {
    const [notificationArr, setNotificationArr] = useRecoilState(alarmArr);
    const setHasAlert = useSetRecoilState(hasAlertSelector);
    const isLogin = useRecoilValue(isLoginState);
    const queryClient = useQueryClient();
    const router = useRouter();

    const disconnectSocket = () => {
        if (socket.checkConnected()) {
            setNotificationArr({
                data: [],
                metadata: {
                    unreadCount: 0,
                },
            });
            socket.disconnect();
        }
    };

    const connectSocket = (accessToken: string, refreshToken: string) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        socket.connect();
        socket.connectError(refreshToken);

        // Change this to api call for notification list
        // socket.initialNotification((notification: IAllAlarm) => {
        //     console.log('useSocket checking initialNotfication', notification);
        //     const messages = notification.data;
        //     const bookmarkIndex = notification.metadata.unreadCount as number;
        //     const unreadCount = notification.metadata.unreadCount;
        //     const latestNoti = messages[0] ?? undefined;

        //     setNotificationArr((existedNotiList: IAllAlarm) => {
        //         return {
        //             data: [...existedNotiList.data, ...messages],
        //             metadata: {
        //                 bookmarkIndex,
        //                 unreadCount: unreadCount,
        //             },
        //         };
        //     });

        //     if (latestNoti) {
        //         if (!latestNoti.isRead) setHasAlert(true);
        //         else setHasAlert(false);
        //     }

        //     if (window.ReactNativeWebView)
        //         window.ReactNativeWebView.postMessage(JSON.stringify({ unReadNotiCnt: unreadCount }));
        // });
        // Start here for initial fetch
        // const { data } = GetInfiniteNotificationList();
        GetNotificationList().then(notification => {
            const messages = notification.data;
            const bookmarkIndex = notification.metadata.unreadCount as number;
            const unreadCount = notification.metadata.unreadCount;
            const latestNoti = messages[0] ?? undefined;

            setNotificationArr((existedNotiList: IAllAlarm) => {
                return {
                    data: [...existedNotiList.data, ...messages],
                    metadata: {
                        bookmarkIndex,
                        unreadCount: unreadCount,
                    },
                };
            });

            if (latestNoti) {
                if (!latestNoti.isRead) setHasAlert(true);
                else setHasAlert(false);
            }

            if (window.ReactNativeWebView)
                window.ReactNativeWebView.postMessage(JSON.stringify({ unReadNotiCnt: unreadCount }));
        });
        //const notification = data as unknown as IAllAlarm;

        socket.notification(async (notification: IAlarmItem) => {
            if (router.pathname === '/notification') {
                await API.patch(URLs.noti.notificationCheck);
                setHasAlert(false);
            } else {
                setHasAlert(true);
            }
            setNotificationArr((existedNotiList: IAllAlarm) => {
                return {
                    data: [notification, ...existedNotiList.data],
                    metadata: {
                        unreadCount: existedNotiList.metadata.unreadCount,
                    },
                };
            });

            if (window.ReactNativeWebView)
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({ unReadNotiCnt: notificationArr.metadata.unreadCount + 1 }),
                );

            // Could call a refetch here to save me the trouble, but that might end up with duplicates
            if (notification.type === NOTICE) {
                queryClient.refetchQueries({ queryKey: ['notification-list', isLogin ? 'login' : 'not-login'] });
            }
        });

        // call first pagenated result of notification/list
        //socket.status(1);
    };

    const proposalSummary = (setData: Function) => {
        socket.proposalSummary(setData);
    };

    const proposalDetail = (setData: Function, designRequestId: number) => {
        socket.proposalDetail(setData, designRequestId);
    };

    return { connectSocket, disconnectSocket, proposalSummary, proposalDetail };
};
