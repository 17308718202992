import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import useModal from '@/hooks/useModal';
import BottomFixedButton from '@/components/Button/BottomFixedButton';
import { FontStyle } from '@/utils/StyleUtil';
import { NAdTracking } from '@/utils/script/NaverAd';
import { promotionPlannerState, referrerState } from '@/recoil';
import { API, URLs } from '@/api';
import useCounselMatching from '@/hooks/plan/useCounselMatching';
import useSettingDesignatedPlanner from '@/hooks/planner/useSettingDesignatedPlanner';
import { SelectedIns, insuranceTypeState } from '@/recoil/plan';
import { DEFAULT_REFERRER } from '@/models';
import { AC, INQUIRY, TMSENIOR } from '@/models/plan';
import { MatchingType } from '@/models/myPlan';

const CompleteRegist = () => {
    const { clearModalList } = useModal();
    const router = useRouter();

    const selIns = useRecoilValue(SelectedIns);
    const insuranceType = useRecoilValue(insuranceTypeState);
    const referrer = useRecoilValue(referrerState);
    const promotionPlanner = useRecoilValue(promotionPlannerState);

    const onClickButton = () => {
        const curPath = router.asPath;
        if (curPath.includes('/insurance-detail')) router.push('/plan/get-proposal');
        else if (curPath.includes('/insurance-list')) router.push('/plan/get-no-product-proposal');
        else if (curPath.includes('/user-info') && selIns === AC) router.push('/plan/accident');
        else if (curPath.includes('/user-info') && selIns === INQUIRY) router.push('/plan/get-no-product-proposal');
        else if (curPath.includes('/user-info') && insuranceType === TMSENIOR) router.push('/plan/senior');
        clearModalList();

        if (curPath.includes('/plan') || curPath.includes('/myPlan')) NAdTracking('3', '1');
        else if (curPath.includes('/ina')) NAdTracking('3', '2');
        else if (curPath.includes('/myPage')) NAdTracking('3', '3');

        if (curPath === '/myPlan/interest-detail') router.push('/myPlan');
    };

    // 플래너와 바로 상담하기
    const onSubmitCounsel = async () => {
        const campaignCategory = window.sessionStorage.getItem('campaign-category');
        const campaignTerm = window.sessionStorage.getItem('campaign-term');
        const campaignSource = window.sessionStorage.getItem('campaign-source');
        const addSeo = campaignCategory !== undefined && campaignTerm !== undefined && campaignSource !== undefined;

        const additionalInfoWithSeo = {
            referrer: referrer !== '' ? referrer : DEFAULT_REFERRER,
            seo: { campaign: campaignCategory, term: campaignTerm, source: campaignSource },
        };

        const additionalInfoWithoutSeo = {
            referrer: referrer !== '' ? referrer : DEFAULT_REFERRER,
        };

        const params = {
            additionalInfo: { ...(addSeo ? additionalInfoWithSeo : additionalInfoWithoutSeo) },
            insuredPersonInfo: {},
            matchingType: MatchingType.PLANNERINQUIRY,
        };
        const res = await API.post(URLs.plan.designRequest, params);
        return res;
    };

    const { submitProposal } = useCounselMatching({ onSubmitCounsel });
    const { onClickButton: setDesignatedPlanner } = useSettingDesignatedPlanner(submitProposal);

    const startConsulting = () => {
        setDesignatedPlanner({});
        clearModalList();
    };

    return (
        <Container>
            <Image src="/img/error/registration_complete.gif" alt="complete" width={72} height={72} />
            <div className="title">가입이 완료되었습니다</div>
            <div className="sub_title">픽앤플랜의 더 많은 기능들을 이용해보세요!</div>
            <BottomFixedButton
                text={promotionPlanner && selIns === 0 ? '상담 시작하기' : '픽앤플랜 시작하기'}
                buttonColor="#0ec4cf"
                onClickButton={promotionPlanner && selIns === 0 ? startConsulting : onClickButton}
            />
        </Container>
    );
};

const Container = styled.div`
    ${({ theme }) => theme.flex.flexColumn};
    ${({ theme }) => theme.flex.justifyCenter};
    height: 100vh;

    .title {
        ${FontStyle(20, 28, '700', '#333', -1)};
        margin-top: 10px;
    }
    .sub_title {
        ${FontStyle(14, 22, '400', '#acacac', -1)};
        margin-top: 4px;
    }
`;

export default CompleteRegist;
