import React, { ReactElement, MouseEvent, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import Link from 'next/link';
import styled from 'styled-components';

import { isAppState } from '@/recoil';
import { BOTTOM_FIXED_BUTTON, BOTTOM_NAVIGATOR } from '@/models';
import { FontStyle } from '@/utils/StyleUtil';
import { TABLET, RESIZED_SCREEN_TABLET } from '@/models/breakPoints';

interface IProps {
    text: string | ReactElement;
    className?: string;
    textColor?: string;
    buttonColor?: string;
    hasBottomNavi?: boolean;
    active?: boolean;
    onClickNonAcive?: Function;
}

interface IButton extends IProps {
    onClickButton: Function;
}

interface ILink extends IProps {
    link: string;
    onClickButton?: Function;
}

function BottomFixedButton(props: ILink | IButton) {
    const isApp = useRecoilValue(isAppState);
    const [height, setHeight] = useState<number | undefined>(undefined);

    const isLink = (props: IButton | ILink): props is ILink => {
        if ('link' in props) return true;
        return false;
    };

    const onClickLink = (e: MouseEvent) => {
        if (isLink(props) && !props?.active) {
            e.preventDefault();
        } else {
            if (props.onClickButton) props.onClickButton();
        }
    };

    const onClickButton = () => {
        if (!isLink(props)) {
            if (props.active === false) {
                if (props.onClickNonAcive) props.onClickNonAcive();
                return;
            }
            props.onClickButton();
        }
    };

    const resizeEvent = () => {
        if (props.hasBottomNavi) setHeight(window.outerHeight - BOTTOM_FIXED_BUTTON - BOTTOM_NAVIGATOR);
        else setHeight(window.outerHeight - BOTTOM_FIXED_BUTTON);
    };

    useEffect(() => {
        if (window && isApp) {
            window.addEventListener('resize', resizeEvent, true);

            return () => {
                window.removeEventListener('resize', resizeEvent, true);
            };
        }
    }, []);

    return (
        <>
            {isLink(props) ? (
                <Link href={props.link}>
                    <StyledLink
                        data-cy="button__fixedBottom"
                        color={props.textColor}
                        backgroundColor={props.buttonColor}
                        active={props.active}
                        className={props.className}
                        onClick={e => onClickLink(e)}
                        style={{ top: `${height}px` }}
                    >
                        {props.text}
                    </StyledLink>
                </Link>
            ) : (
                <Container
                    data-cy="button__fixedBottom"
                    onClick={onClickButton}
                    color={props.textColor}
                    backgroundColor={props.buttonColor}
                    className={props.className}
                    hasBottomNavi={props.hasBottomNavi}
                    active={props.active}
                    style={{ top: `${height}px` }}
                >
                    {props.text}
                </Container>
            )}
        </>
    );
}

interface IOptions {
    color?: string;
    active?: boolean;
    backgroundColor?: string;
    hasBottomNavi?: boolean;
}

const Container = styled.button<IOptions>`
    position: fixed;
    bottom: 0;
    height: 58px;
    width: 100%;
    @media (min-width: ${TABLET}px) {
        max-width: ${RESIZED_SCREEN_TABLET}px;
    }
    margin-bottom: ${({ hasBottomNavi }) => (hasBottomNavi ? '57px' : 0)};
    background-color: ${({ theme, backgroundColor, active }) =>
        active !== false ? backgroundColor || theme.pageTheme() : '#acacac'};
    ${({ color }) => FontStyle(16, 24, '500', color || '#ffffff', 0)};
    z-index: 10;
`;

const StyledLink = styled.a<IOptions>`
    position: fixed;
    bottom: 0;
    height: 58px;
    width: 100%;
    @media (min-width: ${TABLET}px) {
        max-width: ${RESIZED_SCREEN_TABLET}px;
    }
    background-color: ${({ theme, backgroundColor, active }) =>
        active ? backgroundColor || theme.pageTheme() : '#acacac'};
    ${({ color }) => FontStyle(16, 24, '500', color || '#ffffff', 0)};
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyCenter};
    z-index: 10;
`;

export default BottomFixedButton;
