export class LocalStorage {
    static getItem(key: string) {
        if (typeof window === 'undefined') return;
        return localStorage.getItem(key);
    }

    static setItem(key: string, value: string) {
        if (typeof window === 'undefined') return;
        localStorage.setItem(key, value);
    }

    /*
     * items = [['key1', 'value1'], ['key2', 'value2'], ['key3', 'value3'], ['key4', 'value4']]
     */
    static setMultiItem(items: [string, string][]) {
        if (!localStorage) return;
        for (let i = 0; i < items.length; i++) {
            localStorage.setItem(items[i][0], items[i][1]);
        }
    }

    static removeItem(key: string) {
        if (!localStorage) return;
        localStorage.removeItem(key);
    }
}
