import { useRouter } from 'next/router';
import { useSetRecoilState } from 'recoil';

import useMutationHook from '@/hooks/useMutationHook';
import useModal from '@/hooks/useModal';
import MyPageAnswer from '@/containers/modal/myPage/MyPageAnswer';
import { insuranceTypeState } from '@/recoil/plan';
import { designRequestIdState } from '@/recoil/myPlan';
import { API, URLs } from '@/api';
import {
    WELCOME,
    NOTICE,
    EVENT,
    COMMUNITY_ANSWER,
    COMMUNITY_ANSWER_EXPIRED,
    MYPLAN_PROPOSAL,
    MYPLAN_PROPOSAL_CHECK,
    INQUIRY_ANSWER,
    ONE_TO_ONE_MATCHING,
    IInAPayload,
    IProposalPayload,
    IMyPageAnswerPayload,
    IOneToOneMatchingPayload,
    PLANNER_THREAD,
    IPlannerThreadPayload,
    PageData,
} from '@/models';
import { INQUIRY } from '@/models/plan';

interface IPayload {
    payload?: IInAPayload | IProposalPayload | IMyPageAnswerPayload | IPlannerThreadPayload;
}

export default function useAlarmEvent() {
    const router = useRouter();
    const { addModal, removeCurrentModal } = useModal();

    const setSelectedIns = useSetRecoilState(insuranceTypeState);
    const setDesignRequestId = useSetRecoilState(designRequestIdState);

    const { mutate: readProposal } = useMutationHook(
        ['read-proposal'],
        async ({ proposalId }: { proposalId: number }) => {
            return await API.put(URLs.myPlan.readProposal(proposalId));
        },
    );

    const welcome = () => {};

    const notice = () => {
        router.push('/myPage').then(() => router.push('/myPage/notice'));
    };

    const event = () => {};

    const inaAnswer = (payload: IInAPayload) => {
        router.push({ pathname: '/ina', query: { category: 0 } }).then(() => {
            router.push({
                pathname: `/ina/my/${payload.questionId}`,
                // query: { isSearching: false },
            });
        });
    };

    const inaAnswerExpired = () => {
        router.push({ pathname: '/ina', query: { category: 0 } });
    };

    const myPlanProposal = async (payload: IProposalPayload) => {
        await API.get(URLs.myPlan.insuranceDetail(payload.designRequestId)).then(data => {
            setSelectedIns((data as any)?.insuranceInfo?.insuranceType ?? INQUIRY);

            router.push('/myPlan').then(() => {
                setDesignRequestId(payload.designRequestId);
                router.push(`/myPlan/proposal-status/${payload.designRequestId}`).then(() => {
                    router
                        .push(
                            { pathname: '/myPlan/proposal-explain', query: { proposalId: payload.proposalId } },
                            '/myPlan/proposal-explain',
                        )
                        .then(() => {
                            readProposal({ proposalId: Number(payload.proposalId) });
                        });
                });
            });
        });
    };

    const oneOnOneAnswer = (payload: IMyPageAnswerPayload) => {
        router.push('/myPage').then(() =>
            router.push('/myPage/myQuestion').then(() => {
                addModal({
                    key: 'fullScreen',
                    props: {
                        title: '',
                        content: <MyPageAnswer questionId={payload.inquiryId} />,
                        handleCloseModal: removeCurrentModal,
                    },
                });
            }),
        );
    };

    const oneToOneMatching = async (payload: IOneToOneMatchingPayload) => {
        await API.get(URLs.myPlan.insuranceDetail(payload.designRequestId)).then(data => {
            setSelectedIns((data as any)?.insuranceInfo?.insuranceType ?? INQUIRY);

            router.push('/myPlan').then(() => {
                setDesignRequestId(payload.designRequestId);

                router.push(`/myPlan/proposal-status/${payload.designRequestId}`);
            });
        });
    };

    const { mutate: plannerThread, errorPopUp } = useMutationHook(
        ['planner-qna-page'],
        async ({ payload }: { payload: IPlannerThreadPayload }) => {
            const res = await API.get(URLs.plannerInfo.fetchPlannerQnAPage(payload.threadId));
            return res;
        },
        {
            onSuccess: ({ metadata }: { metadata: PageData }, { payload }: { payload: IPlannerThreadPayload }) => {
                router.push(
                    {
                        pathname: `/planner/${payload.plannerId}`,
                        query: {
                            isAlarm: true,
                            page: metadata.page,
                            threadId: payload.threadId,
                        },
                    },
                    `/planner/${payload.plannerId}`,
                );
            },
            onError: err => errorPopUp(err?.response?.status),
        },
    );

    const alarmEvent = (type: number, payload: IPayload) => {
        if (type === WELCOME) welcome();
        else if (type === NOTICE) notice();
        else if (type === EVENT) event();
        else if (type === COMMUNITY_ANSWER) inaAnswer(payload as IInAPayload);
        else if (type === COMMUNITY_ANSWER_EXPIRED) inaAnswerExpired();
        else if (type === MYPLAN_PROPOSAL || type === MYPLAN_PROPOSAL_CHECK)
            myPlanProposal(payload as IProposalPayload);
        else if (type === INQUIRY_ANSWER) oneOnOneAnswer(payload as IMyPageAnswerPayload);
        else if (type === ONE_TO_ONE_MATCHING) oneToOneMatching(payload as IOneToOneMatchingPayload);
        else if (type === PLANNER_THREAD) plannerThread({ payload });
    };

    return alarmEvent;
}
