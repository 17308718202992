import { atom } from 'recoil';

import { IACInsuranceInput, defaultAccidentPlan } from '@/models/plan';
import { InsuredPerson, defaultUser } from '@/models/userInput';

export const ACUserInfoState = atom<InsuredPerson>({
    key: 'ACUserInfoState',
    default: defaultUser,
});

export const ACInsuranceInput = atom<IACInsuranceInput>({
    key: 'ACInsuranceInput',
    default: defaultAccidentPlan,
});

export const PensionPage = atom<boolean>({
    key: 'PensionPage',
    default: false,
});

export const PetPage = atom<boolean>({
    key: 'PetPage',
    default: false,
});
