import React, { ReactElement, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';

import { isLoginState } from '@/recoil';
import { Page } from 'types/page';

interface IPage extends Omit<ReactElement, 'type'> {
    type: Page;
}

interface IProps {
    page: IPage;
}

function MetaLayout({ page }: IProps) {
    const router = useRouter();

    const isLogin = useRecoilValue(isLoginState);

    const { loginRequired, getBottomLayout, getTopLayout } = page.type;

    // Layout이 있을 시 Layout을 적용하고, 없을 시 그냥 리턴
    const renderBottomLayout = getBottomLayout ?? (page => page);
    const renderTopLayout = getTopLayout ?? (page => page);

    // login 필요한 페이지 설정
    const checkLogin = useCallback(
        (page: React.ReactElement) => {
            const pathname = router.pathname.split('/');

            if (loginRequired && !isLogin) router.replace(`/${pathname[1]}`);

            return page;
        },
        [isLogin, loginRequired],
    );

    return checkLogin(renderBottomLayout(renderTopLayout(page)));
}

export default React.memo(MetaLayout);
