import { ReactElement, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { MutationKey, MutateFunction, UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { isLoadingState } from '@/recoil';
import useModal from './useModal';
import ErrorPartial from '@/containers/common/error/Partial';
import { FontStyle } from '@/utils/StyleUtil';

export default function useMutationHook(
    mutationKey: MutationKey,
    mutationFt: MutateFunction<any, any, any, any>,
    options?: UseMutationOptions<any, AxiosError, any, any>, // TODO 타입 다시 확인
) {
    const { addModal, removeCurrentModal } = useModal();

    const {
        data,
        isError,
        isLoading,
        isSuccess: _isSuccess,
        mutate,
        mutateAsync,
        ...result
    } = useMutation(mutationKey, mutationFt, {
        onSettled: () => {
            setLoading(false);
        },
        ...options,
    });

    const setLoading = useSetRecoilState(isLoadingState);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading, setLoading]);

    useEffect(() => {
        if (_isSuccess) {
            // data가 null이 온 경우 (서버에서 null은 무조건 에러인 것을 보장함)
            if (data === null) {
                setIsSuccess(false);
                return;
            }
            setIsSuccess(true);
        } else {
            // api 통신 실패한 경우
            if (isError) {
                setIsSuccess(false);
                return;
            }
        }
        // renderError 초기화
    }, [isError, data, _isSuccess, setLoading]);

    const errorPopUp = (errorStatus?: number) => {
        let errorContent: ReactElement;

        if (errorStatus === 400 || errorStatus === 404) {
            errorContent = <ErrorPartial type="badRequest" />;
        } else {
            errorContent = <ErrorPartial type="communication" />;
        }

        addModal({
            key: 'popUp',
            props: {
                type: 'confirm',
                content: <div>{errorContent}</div>,
                contentStyle: FontStyle(14, 22, '400', '#444444', -1),
                onClickConfirm: () => {
                    removeCurrentModal();
                },
            },
        });
    };

    return {
        isError,
        isLoading,
        isSuccess,
        mutate,
        mutateAsync,
        result,
        data,
        errorPopUp,
    };
}
