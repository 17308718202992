import Axios, { AxiosRequestConfig, AxiosRequestHeaders, ResponseType } from 'axios';
import qs from 'qs';

import axiosInstance from './interceptor';

interface IProps {
    method: any;
    url: string;
    withCredentials: boolean;
    responseType: ResponseType;
    data?: any;
    headers?: AxiosRequestHeaders;
}

export class API {
    // get method의 params를 queryString으로 변환하는 함수
    static addQueryString(url: string, params: any) {
        const paramsString = qs.stringify(params, { encodeValuesOnly: true });
        if (paramsString.length > 0) {
            return `${url}?${paramsString}`;
        }
        return url;
    }

    // axios request 보내는 함수
    static async _request(
        method: any,
        _url: string,
        params: any,
        responseType: ResponseType = 'json',
        headers?: AxiosRequestHeaders,
    ) {
        const url = _url;

        const options: IProps = {
            method,
            url,
            withCredentials: true,
            responseType,
            headers,
        };
        // GET을 제외한 POST, PUT, DELETE에는 data에 params를 담을 수 있음
        if (method !== 'GET') {
            options.data = params;
            if (params.files || params.uploads) {
                options.headers = { 'Content-Type': 'multipart/form-data; charset: UTF-8;' };

                const paramsObj = Object.keys(params);
                const formData = new FormData();

                for (let i = 0; i < paramsObj.length; i += 1) {
                    const key = paramsObj[i];
                    const value = params[paramsObj[i]];

                    if (key === 'files') {
                        params.files.forEach((file: any) => {
                            formData.append(key, file, file.name);
                        });
                    } else if (key === 'uploads') {
                        params.uploads.forEach((file: any) => {
                            formData.append(key, file, file.name);
                        });
                    } else {
                        formData.append(key, value);
                    }
                }
                options.data = formData;
            }
        } else {
            options.url = API.addQueryString(url, params);
        }

        return axiosInstance(options);
    }

    static async _all(...api: any[]) {
        const response = await Axios.all([...api]);
        return response;
    }

    static get(url: string, params = {}) {
        return API._request('GET', url, params);
    }

    static post(url: string, params = {}) {
        return API._request('POST', url, params);
    }

    static put(url: string, params = {}) {
        return API._request('PUT', url, params);
    }

    static delete(url: string, params = {}) {
        return API._request('DELETE', url, params);
    }

    static patch(url: string, params = {}) {
        return API._request('PATCH', url, params);
    }

    static fileDownload(url: any, params: {}, responseType: ResponseType) {
        return API._request('GET', url, params, responseType);
    }

    static request(config: any) {
        return axiosInstance(config);
    }

    static fileUpload<T>(url: string, data: T, config?: Omit<AxiosRequestConfig, 'url' | 'data'>) {
        return axiosInstance({
            url,
            data,
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            ...config,
        });
    }
}
