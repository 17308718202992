import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import useQueryHook from '@/hooks/useQueryHook';
import useModal from '@/hooks/useModal';
import SvgSelector from '@/components/Svg/SvgSelector';
import PdfViewer from '@/components/Viewer/PdfViewer';
import BottomFixedButton from '@/components/Button/BottomFixedButton';
import { API, URLs } from '@/api';
import { FontStyle } from '@/utils/StyleUtil';

const CheckTerms = ({ setActive }: { setActive: Function }) => {
    const { addModal, removeCurrentModal } = useModal();
    const [isChecked, setIsChecked] = useState([false, false]);
    const checkedTermRef = useRef<number>(-1);

    const termsArr = ['(필수) 픽앤플랜 이용약관', '(필수) 개인정보 수집 및 이용동의'];
    const termsTitle = ['픽앤플랜 이용약관', '개인정보 수집 및 이용동의'];

    const isSelectAll = (arr: boolean[]) => {
        const checkedCnt = arr.filter(value => value).length;

        if (checkedCnt === arr.length) return true;
        return false;
    };

    const checkTerm = (index: number) => {
        const tmp = [...isChecked];
        tmp[index] = !tmp[index];
        setIsChecked([...tmp]);

        setActive(isSelectAll(tmp));
    };

    const { refetch: renderPDF } = useQueryHook(
        ['regist-term'],
        async () => {
            if (checkedTermRef.current === 0) return await API.get(URLs.contract.termsOfService);
            else if (checkedTermRef.current === 1) return await API.get(URLs.contract.consentPersonalInfoUse);
        },
        {
            onSettled: termRes => {
                addModal({
                    key: 'fullScreen',
                    props: {
                        content: (
                            <>
                                <PdfViewer pdfId={termRes ? termRes[0].id : undefined} />
                                <BottomFixedButton
                                    text="동의"
                                    onClickButton={() => {
                                        checkTerm(checkedTermRef.current);
                                        removeCurrentModal();
                                    }}
                                    buttonColor="#0ec4cf"
                                />
                            </>
                        ),
                        handleCloseModal: removeCurrentModal,
                        title: termsTitle[checkedTermRef.current],
                        isCloseIconOnRight: false,
                    },
                });
            },
            enabled: false,
        },
    );

    return (
        <Wrapper>
            {termsArr.map((item, index) => (
                <CheckInfo key={item}>
                    <CheckIconWrapper
                        onClick={() => {
                            if (!isChecked[index]) {
                                checkedTermRef.current = index;
                                renderPDF();
                            } else checkTerm(index);
                        }}
                    >
                        <SvgSelector
                            svg="check-circle"
                            width={22}
                            height={22}
                            stroke="#ffffff"
                            fill={isChecked[index] ? '#0ec4cf' : '#dadada'}
                        />
                        {item}
                    </CheckIconWrapper>
                    <div
                        className="hyper_text"
                        onClick={() => {
                            checkedTermRef.current = index;
                            renderPDF();
                        }}
                    >
                        내용 보기
                    </div>
                </CheckInfo>
            ))}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-top: 30px;
`;

const CheckInfo = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyBetween};
    margin-top: 22px;
    ${FontStyle(14, 22, '400', '#888888', -1)};

    .hyper_text {
        ${FontStyle(12, 20, '500', '#acacac', -1.2)};
        text-decoration: underline;
    }
`;

const CheckIconWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export default CheckTerms;
