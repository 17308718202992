import axios, { AxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';
import * as Sentry from '@sentry/nextjs';

import { noLoginState } from '@/models';
import { URLs } from '@/api';
import LogUtil from '@/utils/LogUtil';
import { IJwt } from '@/models';

const jsonHeader = {
    'Content-Type': 'application/json',
};

const axiosInstance = axios.create({
    headers: jsonHeader,
});

const mswEnabled = process.env.NEXT_PUBLIC_API_MOCKING === 'enabled';

axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        const randIdx = Math.floor(Math.random() * LogUtil.colors.length);
        if (process.env.NEXT_PUBLIC_LOGGING === 'logging') {
            LogUtil.log(randIdx, `Started [${config.method}] : `, config.url);
            LogUtil.log(randIdx, `Parameters :`, config.data);
        }
        const tmp = { ...config, logColorIdx: randIdx };
        tmp.withCredentials = false;
        const token = localStorage.getItem('accessToken');
        if (token) tmp.headers!['x-access-token'] = token;
        return tmp;
    },
    err => {
        return Promise.reject(err);
    },
);

let isTokenRefreshing = false;
let refreshSubscribers: ((_accessToken: string) => void)[] = [];

const onTokenRefreshed = (accessToken: string) => {
    refreshSubscribers.map(callback => callback(accessToken));
};

const addRefreshSubscriber = (callback: (_accessToken: string) => void) => {
    refreshSubscribers.push(callback);
};

const logout = async () => {
    const uuidString = localStorage.getItem('persist-uuid');
    const userInfoStr = localStorage.getItem('persist-login-state');
    const userInfo = JSON.parse(userInfoStr!);

    if (uuidString) {
        const uuid = JSON.parse(uuidString);

        await axios.get(URLs.auth.checkFaker, { params: { deviceId: uuid } }).then(res => {
            // return res.data.data;
            if (res.data.data) {
                axios.delete(URLs.auth.deleteFaker, { data: { deviceId: uuid } }).then(() => {
                    axios
                        .post(URLs.auth.registerGuest, { deviceId: uuid })
                        .then(registRes => {
                            const data = registRes.data.data;

                            localStorage.setItem(
                                'persist-login-state',
                                JSON.stringify({ ...noLoginState, clientId: data.clientId }),
                            );
                            localStorage.setItem('accessToken', data.tokenSet.accessToken);
                            localStorage.setItem('refreshToken', data.tokenSet.refreshToken);

                            if (window.ReactNativeWebView)
                                window.ReactNativeWebView.postMessage(JSON.stringify({ clearAllPushNoti: true }));

                            if (userInfo.userType === 'client') window.location.href = '/logout';
                            else window.location.reload();
                        })
                        .catch(err => {});
                });
            } else {
                axios
                    .post(URLs.auth.registerGuest, { deviceId: uuid })
                    .then(registRes => {
                        const data = registRes.data.data;

                        localStorage.setItem(
                            'persist-login-state',
                            JSON.stringify({ ...noLoginState, clientId: data.clientId }),
                        );
                        localStorage.setItem('accessToken', data.tokenSet.accessToken);
                        localStorage.setItem('refreshToken', data.tokenSet.refreshToken);

                        if (window.ReactNativeWebView)
                            window.ReactNativeWebView.postMessage(JSON.stringify({ clearAllPushNoti: true }));

                        if (userInfo.userType === 'client') window.location.href = '/logout';
                        else window.location.reload();
                    })
                    .catch(err => {});
            }
        });
    }
};

interface IConfig extends AxiosRequestConfig {
    logColorIdx: number;
}

axiosInstance.interceptors.response.use(
    response => {
        if (process.env.NEXT_PUBLIC_LOGGING === 'logging') {
            const logColorIdx = (response.config as IConfig).logColorIdx;
            LogUtil.log(logColorIdx, `Finished [${response.config.method}] : `, response.config.url);
            LogUtil.log(logColorIdx, `Response Data : `, response.data);
        }

        if (Object.prototype.hasOwnProperty.call(response.data, 'metadata')) return response.data;
        if (Object.prototype.hasOwnProperty.call(response.data, 'metaData')) return response.data;
        if (Object.prototype.hasOwnProperty.call(response.data, 'isCancelled')) return response.data;
        if (Object.prototype.hasOwnProperty.call(response.data, 'data')) return response.data.data;
        return response.data;
    },
    async error => {
        if (!mswEnabled) {
            const {
                config,
                response: { status },
            } = error;

            const originalRequest = config;
            if (process.env.NEXT_PUBLIC_LOGGING === 'logging') {
                LogUtil.error(`Error! : ${error.response.status}`);
                LogUtil.error(`Message! : `, error.response.data);
            }

            if (status === 400) {
                if (error.response.data === 'jwt malformed') {
                    await logout();
                }
            }

            if (status === 401) {
                if (!isTokenRefreshing) {
                    let isHacker = false;
                    // isTokenRefreshing이 false인 경우에만 token refresh 요청
                    isTokenRefreshing = true;
                    const accessToken = localStorage.getItem('accessToken');
                    const refreshToken = localStorage.getItem('refreshToken');
                    if (accessToken === null) {
                        await logout();
                    } else {
                        const decoded = jwt_decode(accessToken!) as IJwt;

                        if (!decoded.isBanned) {
                            await axios
                                .post(URLs.auth.refresh, { refreshToken })
                                .then(tokenRes => {
                                    const { accessToken: _accessToken, refreshToken: _refreshToken } = tokenRes.data;
                                    localStorage.setItem('accessToken', _accessToken);
                                    localStorage.setItem('refreshToken', _refreshToken);

                                    onTokenRefreshed(_accessToken);
                                })
                                .catch(async () => {
                                    await logout();

                                    if (decoded.userType === 'client') isHacker = true;
                                });

                            // 새로운 토큰으로 지연되었던 요청 진행
                            if (!isHacker) {
                                isTokenRefreshing = false;
                                const response = await axiosInstance(originalRequest);
                                refreshSubscribers = [];
                                return response;
                            }
                        } else {
                            if (window.location.pathname !== '/ban') window.location.href = '/ban';
                        }
                    }
                }

                // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
                const retryOriginalRequest = new Promise(resolve => {
                    addRefreshSubscriber(accessToken => {
                        originalRequest.headers['x-access-token'] = accessToken;
                        resolve(axiosInstance(originalRequest));
                    });
                });

                return retryOriginalRequest;
            }
            if (status === 418) {
                logout();
            }
            if (status === 403) {
                if (error.response.data === 'TokenExpiredError') {
                    return Promise.resolve(undefined);
                } else if (error.response.data === 'TypeError') {
                    // TODO: Ask about TypeError and how to take care of it
                }
            }
            Sentry.captureException(error);
            Sentry.captureMessage(
                `AxiosError: status code ${error.response.status}, ${error.response.data?.message ?? error.message}`,
            );
            return Promise.reject(error);
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
