import { DateTime } from 'luxon';

class StringUtil {
    /*
     * Number 값 테스트
     */
    static isNumber(n: any): boolean {
        if (n === null || n === undefined) {
            return false;
        }
        return !Number.isNaN(n);
    }

    /*
     * 3자리 수 마다 쉼표(,) 적용 (1,234,567 or 가,나다라,마바사)
     */
    static addCommaToNumber(x: string | number): string {
        if (StringUtil.isNumber(x)) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return '';
    }

    /*
     * %s를 args로 포매팅 -> %s + %s, 1 , 2 => 1 + 2
     */
    static format(str: string, ...args: any[]): string {
        return args.reduce((pattern, value) => pattern.replace(/%s/, value), str);
    }

    /*
     * 1~9 앞에 0을 붙여 일자로 표현
     */
    static addZeroToDate(num: number): string {
        if (num >= 0 && num < 10) {
            return `0${num.toString()}`;
        }
        return num.toString();
    }

    /*
     * number 값을 한화 약 O00(억,만)원으로 표현 후 내림 적용 -> 1234567899 = 12억원
     * noUnit가 true일 경우 1,234,567원 으로 표현
     */
    static divNumberByUnit(value: number, noUnit: boolean = false): string {
        if (noUnit) return `${StringUtil.addCommaToNumber(value)}원`;
        if (value >= 1e12) {
            return `${Math.floor(value / 1e12)}조원`;
        }
        if (value >= 1e8) {
            return `${Math.floor(value / 1e8)}억원`;
        }
        if (value >= 1e4) {
            return `${Math.floor(value / 1e4)}만원`;
        }
        return `${value}원`;
    }

    /*
     * number 값을 한화 약 O00(억,만)원으로 표현 후 내림 적용 -> 1234567899 = 12억원
     * noUnit가 true일 경우 1,234,567원 으로 표현
     * split이 true일 경우 1억 2천만원 으로 표현
     * split이 false일 경우 1억2,000만원으로 표현
     */
    static convertKorUnit(num: number, split: boolean = false) {
        if (StringUtil.isNumber(num)) {
            const units = ['만', '억', '조'];
            let n = num;
            let i = 0;
            const arr = [];
            if (num === 0) return '0원';
            while (Math.floor(n) >= 1) {
                if (split) {
                    const splitNum = this.convert4Digit(n % 1e4, false);
                    if (splitNum !== '0') {
                        if (i === 0) arr.push(`${splitNum}`);
                        else arr.push(`${splitNum}${units[i - 1]}`);
                    }
                } else {
                    if (this.addCommaToNumber(n % 1e4) !== '0') {
                        if (i === 0) arr.push(`${this.addCommaToNumber(n % 1e4)}`);
                        else arr.push(`${this.addCommaToNumber(n % 1e4)}${units[i - 1]}`);
                    }
                }
                n = Math.floor(n / 1e4);
                i += 1;
            }
            const joinArr = arr.reverse().join(' ');
            return `${joinArr}원`;
        }
        return '';
    }

    /*
     * 최대 4자리 숫자까지 가능
     * unit이 false일 경우 1천2백으로 표현
     * unit이 true일 경우 1천2백원으로 표현
     */
    static convert4Digit = (number: number, unit: boolean) => {
        const arr: string[] = [];
        const units = ['', '십', '백', '천'];
        number
            .toString()
            .split('')
            .reverse()
            .map((item, index) => {
                if (item !== '0') {
                    arr.push(units[index]);
                    arr.push(item);
                }
            });

        if (unit) {
            return `${arr.reverse().join('')}원`;
        }
        return `${arr.reverse().join('')}`;
    };

    static convertDecimal = (value: number) => {
        if (value >= 1e12) {
            return `${value / 1e12}조원`;
        }
        if (value >= 1e8) {
            return `${value / 1e8}억원`;
        }
        if (value >= 1e4) {
            return `${value / 1e4}만원`;
        }
        return `${value}원`;
    };

    /*
     * Date 형식의 string 값을 yy. MM. dd 혹은 N시간 전, 방금 전으로 표현
     */
    static displayedAt(createdAt: string | number): string {
        const today = DateTime.now();
        const createAtDT = DateTime.fromJSDate(new Date(createdAt));
        const diff = today.diff(createAtDT, ['hours']).toObject();
        if (!diff.hours) return createAtDT.toLocaleString();
        if (diff.hours < 1) return '방금 전';
        if (diff.hours < 24) return `${Math.floor(diff.hours)}시간 전`;
        return createAtDT.toFormat('yy. MM. dd');
    }

    static displayedDateAt(date: Date): string {
        const dateAtDT = DateTime.fromJSDate(new Date(date));
        return dateAtDT.toFormat('yyyy.MM.dd');
    }

    /*
     * date를 HH:mm 으로 표현
     */
    static displayedChatAt(createdAt: string | number | Date): string {
        if (typeof createdAt === 'string' || typeof createdAt === 'number')
            return DateTime.fromJSDate(new Date(createdAt)).toFormat('HH:mm');
        return DateTime.fromJSDate(createdAt).toFormat('HH:mm');
    }

    /*
     * date를 yyyy.M.d (요일)으로 표현
     * 2022.02.03 (목)
     */
    static getDateWithDayOfTheWeek(d: Date | number | string): string {
        const str = DateTime.fromJSDate(new Date(d)).setLocale('kr').toFormat('yyyy.MM.dd (EEEEE)');
        return str;
    }

    /*
     * text ellipsis 변환
     */
    static ellipsis = (s: string, len: number) => {
        return s.length > len ? `${s.substring(0, len)}..` : s;
    };

    /*
     * 연도를 통해 한국나이 계산
     */
    static calcKorAgeFromBirthYear = (year: number) => {
        const criteriaYear = new Date().getFullYear();
        const compYear = year;

        if (!/\b(19|20)\d\d\b/g.test(year.toString())) return 0;
        if (criteriaYear - compYear === -1) return 1;
        else return criteriaYear - compYear + 1;
    };

    /*
     * 연도를 통해 만나이 계산
     */
    static calcWesternAgeFromBirthYear = (year: number) => {
        const criteriaYear = new Date().getFullYear();
        const compYear = year;

        if (!/\b(19|20)\d\d\b/g.test(year.toString())) return 0;
        if (criteriaYear - compYear === -1) return 0;
        else return criteriaYear - compYear;
    };

    /*
     * p days만큼의 시간을 더함
     */
    static getDownloadPeriod(d: Date | number | string, p: number): string {
        const str = DateTime.fromJSDate(new Date(d)).plus({ days: p }).setLocale('kr').toFormat('yyyy.MM.dd (EEEEE)');
        return str;
    }

    /*
     * byte를 단위 변환
     */
    static byteToOtherUnit(byte: number): string {
        if (byte / 1e6 > 1) return `${Math.round((byte / 1e6) * 10) / 10}MB`;
        else if (byte / 1e3 > 1) return `${Math.round((byte / 1e3) * 10) / 10}KB`;
        return `${byte}Byte`;
    }
}

export default StringUtil;
